import "./Burger.css";

type TypeBurgerProps = {
  value: boolean;
  action: any;
};

const Burger = ({ value, action }: TypeBurgerProps) => {
  return (
    <div id="burger-container">
      <input
        data-testid="burger-button"
        type="checkbox"
        id="menu-toggle"
        checked={value}
        onChange={() => action(!value)}
      />
      <label id="trigger" htmlFor="menu-toggle"></label>
      <label id="burger" htmlFor="menu-toggle"></label>
    </div>
  );
};

export default Burger;
