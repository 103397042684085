import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../assets/HomePage/close.svg";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { PaymentFor, PaymentForType } from "../../types/Balance";
import { SpecialistTag } from "../../types/TypeSpecialist";
import { SuccesNotify } from "../../utils/toaster";
import ModalWindow from "../ModalWindow/ModalWindow";
import PaymentSession from "../PaymentSession/PaymentSession";
import StandartButton from "../StandartButton/StandartButton";
import s from "./RealTimeSessionModalContent.module.css";

type DropDownMenuData = { lable: string; value: string };

type RealTimeSessionModalContentProps = {
  fullName: string;
  sendRequestHandler: (
    sessionSubject: string,
    sessionTags: string[],
    setSubjectValue: Dispatch<SetStateAction<string>>,
    selectSpecialistId: string
  ) => Promise<{ status: boolean; notEnoughBalance?: boolean }>;
  realTimeError: string;
  specialistUserId: string;
  allSpecializations: {
    specialistId: string;
    lable: string;
    price: number;
    specialization: string;
    tags: SpecialistTag[];
  }[];
  initialSpecialization?: string;
  isShowingSpecializationsTab?: boolean;
};

const RealTimeSessionModalContent = ({
  fullName,
  sendRequestHandler,
  realTimeError,
  specialistUserId,
  allSpecializations,
  initialSpecialization,
  isShowingSpecializationsTab = true,
}: RealTimeSessionModalContentProps) => {
  const navigate = useNavigate();
  const { setUserData, userData } = useContext(ContextProvider);
  const [subjectValue, setSubjectValue] = useState("");
  const [error, _] = useState("");
  const [noBalanceModal, setNoBalanceModal] = useState(false);
  const [paymentFor, setPaymentFor] = useState<PaymentFor | null>(null);
  const [selectSpecialistId, setSelectSpecialistId] = useState(
    initialSpecialization ?? allSpecializations[0].specialistId
  );
  const [sessionTags, setSessionTags] = useState<
    { _id: string; lable: string }[]
  >([]);
  const [tagsData, setTagsData] = useState<DropDownMenuData[]>([]);

  /* const gatTags = async (level: number, parentTagsId?: string) => {
    if (!token || !selectSpecialistId || !userData) return;
    const direction =
      directions[
        allSpecializations.find((el) => el.specialistId === selectSpecialistId)
          ?.specialization!
      ];
    const payload = {
      level: level,
      direction: direction,
      parentTagsIds: parentTagsId ? [parentTagsId] : undefined,
    };

    const response = await SpecialistApi.getSpecialistTags(token, payload);

    if (response.status && response.tags) {
      const tagsData = response.tags.map((item) => ({
        lable: item.labels.find(
          (el) => el.language === userData.selectedLanguage
        )!.text,
        value: item._id,
      }));
      setTagsData(tagsData);
    }
  }; */
  useEffect(() => {
    if (!selectSpecialistId) return;
    /*     const parentTagsId =
      sessionTags.length > 0 &&
      sessionTags.length < 3 &&
      sessionTags[sessionTags.length - 1]._id !== "other"
        ? sessionTags[sessionTags.length - 1]._id
        : undefined;
    gatTags(sessionTags.length + 1, parentTagsId); */
    const currentSpecialistTags = allSpecializations.find(
      (el) => el.specialistId === selectSpecialistId
    )?.tags!;
    const correctLevelTags = currentSpecialistTags.filter(
      (el) => el.level === sessionTags.length + 1
    );
    const parentTagId = sessionTags.length
      ? sessionTags[sessionTags.length - 1]._id
      : undefined;
    const tagsToSetData = parentTagId
      ? correctLevelTags
          .filter((el) => el.parentTag === parentTagId)
          .map((item) => ({
            value: item._id,
            lable: item.labels.find(
              (el) => el.language === userData?.selectedLanguage
            )?.text!,
          }))
      : correctLevelTags.map((item) => ({
          value: item._id,
          lable: item.labels.find(
            (el) => el.language === userData?.selectedLanguage
          )?.text!,
        }));

    setTagsData(tagsToSetData);
  }, [selectSpecialistId, sessionTags]);

  useEffect(() => {
    const currentPaymentFor = {
      type: PaymentForType.REAL_TIME_SESSION,
      bookingFor: specialistUserId,
      subject: subjectValue,
      realTimeSessionDuration: 60,
      bookingForSpecialist: selectSpecialistId,
    };
    setPaymentFor(currentPaymentFor);
  }, [subjectValue]);

  const sendRequest = async () => {
    const response = await sendRequestHandler(
      subjectValue,
      sessionTags.filter((el) => el._id !== "other").map((item) => item._id),
      setSubjectValue,
      selectSpecialistId
    );
    if (!response.status && response.notEnoughBalance) {
      setNoBalanceModal(true);
    }
    if (response.status) {
      navigate("/global-chat", {
        state: {
          userId: specialistUserId,
        },
      });
      SuccesNotify(strings.bookingSucces);
      setUserData((prev) => ({
        ...prev!,
        balance:
          prev!.balance -
          allSpecializations.find(
            (el) => el.specialistId === selectSpecialistId
          )?.price! *
            100,
      }));
    }
  };

  return (
    <div className={s.container}>
      <h3 className={s.headTitle}>{strings.realTimeSessionTitle}</h3>
      <div className={s.sessionDetailsBlock}>
        <h4>{strings.sessionDetails}</h4>
        {allSpecializations.length > 1 && isShowingSpecializationsTab ? (
          <div className={s.specializationTabsBlock}>
            {allSpecializations.map((item, index) => (
              <div
                key={index}
                className={
                  selectSpecialistId === item.specialistId
                    ? s.activeSpecializationTab
                    : s.notActiveSpecializationTab
                }
                onClick={() => {
                  setTagsData([]);
                  setSessionTags([]);
                  setSelectSpecialistId(item.specialistId);
                }}
              >
                {item.lable}
              </div>
            ))}
          </div>
        ) : null}
        <div className={s.sessionDetailsElement}>
          <span>{strings.specialistsrealTime} </span>
          <span>{fullName}</span>
        </div>
        <div className={s.sessionDetailsElement}>
          <span>
            {strings.price}:{" "}
            {
              allSpecializations.find(
                (el) => el.specialistId === selectSpecialistId
              )?.price
            }{" "}
            $
          </span>
        </div>
        <div className={s.sessionDetailsElement}>
          <span>{strings.time} </span>
          <span>{strings.now}</span>
        </div>
      </div>
      {sessionTags.map((item) => item._id).includes("other") ? (
        <div className={s.subjectBlock}>
          <div className={s.selectedSessionTagsBlock}>
            {sessionTags && sessionTags.length
              ? sessionTags.map((item, index) =>
                  item._id === "other" ? (
                    <div key={index}></div>
                  ) : (
                    <div key={index} className={s.selectedTagElement}>
                      <span>{item.lable}</span>{" "}
                      <CloseIcon
                        className={s.closeIcon}
                        onClick={() =>
                          setSessionTags((prev) => prev.slice(0, index))
                        }
                      />
                    </div>
                  )
                )
              : null}
          </div>
          <div className={s.subjectTitleBlock}>
            <h4>
              {strings.subjectSession}
              <span className={s.maxLength}>
                ({subjectValue.length + "/" + "500"})
              </span>
            </h4>
            {error.length ? <span>{error}</span> : ""}
          </div>
          <textarea
            name=""
            id=""
            cols={30}
            rows={3}
            value={subjectValue}
            className={s.textAreaNewNote}
            onChange={(e) => setSubjectValue(e.target.value)}
            maxLength={500}
          ></textarea>
        </div>
      ) : (
        <div className={s.tagsBlock}>
          <div className={s.selectedSessionTagsBlock}>
            {sessionTags && sessionTags.length
              ? sessionTags.map((item, index) =>
                  item._id === "other" ? (
                    <div></div>
                  ) : (
                    <div key={index} className={s.selectedTagElement}>
                      <span>{item.lable}</span>{" "}
                      <CloseIcon
                        className={s.closeIcon}
                        onClick={() =>
                          setSessionTags((prev) => prev.slice(0, index))
                        }
                      />
                    </div>
                  )
                )
              : null}
          </div>
          {sessionTags.length < 3 && (
            <div className={s.tagsDataBlock}>
              {[
                ...tagsData,
                { value: "other", lable: strings.otherCertificate },
              ].map((item, index) => (
                <div
                  key={index}
                  className={s.tagElement}
                  onClick={() => {
                    setSessionTags((prev) => [
                      ...prev,
                      { _id: item.value, lable: item.lable },
                    ]);
                  }}
                >
                  {item.lable}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className={s.errorTitle}>{realTimeError}</div>
      <div className={s.actionBlock}>
        <StandartButton
          action={sendRequest}
          buttonTitle={strings.sendRequestRealTimeBtn}
          disabled={
            subjectValue.length || sessionTags.length === 3 ? false : true
          }
          tooltipTitle={
            subjectValue.length || sessionTags.length === 3
              ? ""
              : strings.subjectError
          }
        />
      </div>
      {paymentFor && (
        <ModalWindow
          isOpen={noBalanceModal}
          setIsOpen={setNoBalanceModal}
          width="fit-content"
        >
          <PaymentSession hidePromocode paymentFor={paymentFor} />
        </ModalWindow>
      )}
    </div>
  );
};

export default RealTimeSessionModalContent;
