import { LayoutGroup, motion } from "framer-motion";
import { ReactNode } from "react";

type MotionDivProps = {
  flexDirection: "row" | "column";
  children: ReactNode;
};

const MotionDiv = ({ flexDirection, children }: MotionDivProps) => {
  return (
    <LayoutGroup>
      <motion.div
        style={{
          display: "flex",
          flexDirection: flexDirection,
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {children}
      </motion.div>
    </LayoutGroup>
  );
};

export default MotionDiv;
