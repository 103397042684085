export const network = {
  users: {
    getUserById: "getUserById",

    addAbilitiesToUser: "addAbilitiesToUser",

    register: "registerUser",

    connectPhoneNumber: "connectPhoneNumber",

    resendConfCode: "resendConfCode",

    confirmRegister: "confirmUserRegister",

    auth: "authUser",
    logout: "logout",

    refreshAccessToken: "refreshAccessToken",

    getUserByToken: "getUserByToken",

    getUserSelectedFields: "getUserSelectedFields",

    update: "updateUser",

    updatePassword: "updateUserPassword",

    confirmPassUpdate: "confirmPasswordUpdate",

    getUserPublicAvatarAndName: "getUserPublicAvatarAndName",

    getUserAvatarAndName: "getUserAvatarAndName",

    getAvatarsOfSessionMembers: "getAvatarsOfSessionMembers",

    addSessionAbilityToUsers: "addSessionAbilityToUsers",

    getUserProfileInfoById: "getUserProfileInfoById",

    checkIfUserJoinedAnyCompany: "checkIfUserJoinedAnyCompany",

    getAllNonSpecialistUsers: "getAllNonSpecialistUsers",

    createNewGender: "createNewGender",
    getAllGenders: "getAllGenders",

    getUserPublicInfo: "getUserPublicInfo",

    getUserPublicInfoById: "getUserPublicInfoById",

    getUserPayments: "getUserPayments",

    updateAvatar: "updateAvatar",

    getAvatarStream: "getAvatarStream",

    fixUserAvatars: "fixUserAvatars",

    fixScheduleType: "fixScheduleType",

    findUserBySearchString: "findUserBySearchString",

    getUserLastActivityLogs: "getUserLastActivityLogs",

    addFavouriteUser: "addFavouriteUser",

    removeFavouriteUser: "removeFavouriteUser",

    getUsersFavouritesList: "getUsersFavouritesList",

    checkIfUserWorkedWithSpecialist: "checkIfUserWorkedWithSpecialist",

    checkIfUserIsFavourite: "checkIfUserIsFavourite",

    sendEmailConfirmation: "sendEmailConfirmation",
    confirmEmailWithCode: "confirmEmailWithCode",

    getUserPublicInfoLatinName: "getUserPublicInfoLatinName",

    getUserGeoInfo: "getUserGeoInfo",
  },

  sessions: {
    incomingCall: "incomingCall",
    acceptCall: "acceptCall",

    getMyId: "getMyId",

    allUsers: "allUsers",

    callUser: "callUser",
    callAccepted: "callAccepted",

    cameraDisconnected: "cameraDisconnected",

    joinRoom: "joinRoom",
    onJoinRoom: "onJoinRoom",

    disconnectFromSession: "disconnectFromSession",

    createSession: "createSession",

    getSessionById: "getSessionById",

    bookSession: "bookSession",
    bookingNotify: "bookingNotify",

    acceptBooking: "acceptBooking",
    bookingAcceptNotify: "bookingAcceptNotify",

    getBookingById: "getBookingById",

    getUsersBooking: "getUsersBooking",

    rescheduleBooking: "rescheduleBooking",
    rescheduleBookingNotify: "rescheduleBookingNotify",

    rescheduleSession: "rescheduleSession",
    rescheduleSessionNotify: "rescheduleSessionNotify",

    getAllUserSessions: "getAllUserSessions",

    userActionInSessionChat: "userActionInSessionChat",

    commentSession: "commentSession",
    getSpecialistsComments: "getSpecialistsComments",

    getSpecialistsSessionsAmount: "getSpecialistsSessionsAmount",

    getSessionVisitDuration: "getSessionVisitDuration",

    checkIfCanLeaveComment: "checkIfCanLeaveComment",

    cancelBooking: "cancelBooking",

    createNewDefaultComment: "createNewDefaultComment",
    getAllDefaultComments: "getAllDefaultComments",

    createSessionPackage: "createSessionPackage",

    getSpecialistSessionPackages: "getSpecialistSessionPackages",

    getSpecialistSessionPackageById: "getSpecialistSessionPackageById",

    updatePackage: "updatePackage",

    setSessionPackagePreviewFile: "setSessionPackagePreviewFile",

    setFileToPackageSession: "setFileToPackageSession",

    deletePackageSessionFile: "deletePackageSessionFile",

    getPackageFiles: "getPackageFiles",

    deletePackage: "deletePackage",

    getPackageById: "getPackageById",

    getBookedPackageById: "getBookedPackageById",

    bookSessionPackage: "bookSessionPackage",

    getUsersBookedPackages: "getUsersBookedPackages",

    acceptPackageBooking: "acceptPackageBooking",

    cancelPackageBooking: "cancelPackageBooking",

    getUsersSessionPayments: "getUsersSessionPayments",

    getAllSessionPayments: "getAllSessionPayments",

    createPackagePromocode: "createPackagePromocode",

    getAllPackagePromocodes: "getAllPackagePromocodes",

    getUsersSessionHistory: "getUsersSessionHistory",

    refundNonCompletedSession: "refundNonCompletedSession",

    addSpecialistUsersFields: "addSpecialistUsersFields",

    getPackageImageStream: "getPackageImageStream",

    getPackageVideoStream: "getPackageVideoStream",

    getPackageSessionImageStream: "getPackageSessionImageStream",

    checkIfCanBookPackage: "checkIfCanBookPackage",

    getGeneralPackagesInfo: "getGeneralPackagesInfo",

    getUserSessionsPagination: "getUserSessionsPagination",

    initCronForSession: "initCronForSession",

    refundSession: "refundSession",
    payOutSession: "payOutSession",

    getSessionsWaitingForRefund: "getSessionsWaitingForRefund",

    getSessionInfoById: "getSessionInfoById",
  },

  chat: {
    joinChat: "joinChat",
    onJoinChat: "onJoinChat",

    sendMessage: "sendMessageInChat",
    onMessage: "onMessageInChat",

    getChat: "getChatInfo",

    newMessage: "receiveNewMessage",

    joinDialogChat: "joinDialogChat",

    sendMessageToDialogChat: "sendMessageToDialogChat",
    onDialogMessage: "onDialogMessage",

    newDialogMessage: "newDialogMessage",

    createDialogChat: "createDialogChat",

    getMessageFiles: "getMessageFiles",

    getUsersDialogChats: "getUsersDialogChats",

    getDialogMessagesById: "getDialogMessagesById",

    getDialogChatById: "getDialogChatById",

    getMessageById: "getMessageById",

    markMessagesAsRead: "markMessagesAsRead",

    addSystemMessageToDialogChat: "addSystemMessageToDialogChat",

    getSessionChatMessages: "getSessionChatMessages",

    getDialogMessageById: "getDialogMessageById",

    getUnreadDialogMessagesLength: "getUnreadDialogMessagesLength",

    getMessageFileStream: "getMessageFileStream",

    getMessageImages: "getMessageImages",

    downloadMessageFile: "downloadMessageFile",
  },

  quizes: {
    createQuestion: "createQuizQuestion",

    createQuiz: "createQuiz",

    saveQuizResult: "saveQuizResult",

    createQuizWithExistQuest: "createQuizWithExistQuest",

    getQuizByTitle: "getQuizByTitle",

    getQuizResultByTitle: "getQuizResultByTitle",
  },

  specialists: {
    update: "updateSpecialist",

    uploadIntroVideo: "uploadIntroVideo",
    uploadIntroVideoToBucket: "uploadIntroVideoToBucket",

    uploadEducationCertificates: "uploadEducationCertificates",
    removeEducationCertificate: "removeEducationCertificate",

    uploadQualificationCertificates: "uploadQualificationCertificates",
    removeQualificationCertificate: "removeQualificationCertificate",

    uploadFederationCertificates: "uploadFederationCertificates",
    removeFederationCertificate: "removeFederationCertificate",

    getSpecialistById: "getSpecialistById",

    getSpecialistIntroVideo: "getSpecialistIntroVideo",

    getSpecialistPublicIntroVideo: "getSpecialistPublicIntroVideo",

    getEducationCertificates: "getEducationCertificates",
    getEducationPublicCertificates: "getEducationPublicCertificates",

    getQualificationCertificates: "getQualificationCertificates",
    getQualificationPublicCertificates: "getQualificationPublicCertificates",

    getFederationCertificates: "getFederationCertificates",
    getFederationPublicCertificates: "getFederationPublicCertificates",

    getRecommendedSpecialists: "getRecommendedSpecialists",

    getFilteredSpecialists: "getFilteredSpecialists",

    getFilterAvailableParams: "getFilterAvailableParams",

    createMainSpecialization: "createMainSpecialization",

    createSubSpecialization: "createSubSpecialization",

    getMainSpecializations: "getMainSpecializations",

    getSubSpecializations: "getSubSpecializations",

    getAvailableParams: "getAvailableParams",

    getSpecialistFreeTime: "getSpecialistFreeTime",

    connectSpecialist: "connectSpecialist",

    addConnectedSpecialist: "addConnectedSpecialist",

    disconnectSpecialist: "disconnectSpecialist",

    sendRealTimeSessionRequest: "sendRealTimeSessionRequest",
    checkIfCanBookRealTimeSession: "checkIfCanBookRealTimeSession",
    acceptRealTimeSessionRequest: "acceptRealTimeSessionRequest",
    cancelRealTimeSessionRequest: "cancelRealTimeSessionRequest",

    getRealTimeBookingById: "getRealTimeBookingById",

    getAllSpecialists: "getAllSpecialists",

    getNonVerifiedSpecialists: "getNonVerifiedSpecialists",

    inviteSpecialistToApp: "inviteSpecialistToApp",

    bulkInviteSpecialistToApp: "bulkInviteSpecialistToApp",

    getProfileChangeDiff: "getProfileChangeDiff",

    getAllSpecialzations: "getAllSpecialzations",

    createSecondarySpecializations: "createSecondarySpecializations",

    deleteSecondarySpecializations: "deleteSecondarySpecializations",

    createSpecialistTag: "createSpecialistTag",

    changeSpecialistTag: "changeSpecialistTag",

    deleteSpecialistTag: "deleteSpecialistTag",

    addTagsToSpecialist: "addTagsToSpecialist",

    removeTagsFromSpecialist: "removeTagsFromSpecialist",

    getSpecialistTags: "getSpecialistTags",

    getEducationCertificateStream: "getEducationCertificateStream",
    getQualificationCertificateStream: "getQualificationCertificateStream",
    getFederationCertificateStream: "getFederationCertificateStream",
    getFederationBadgeStream: "getFederationBadgeStream",

    addNewSpecialistProfileToUser: "addNewSpecialistProfileToUser",

    createFederationBadge: "createFederationBadge",
    createEducation: "createEducation",
    createFederation: "createFederation",
    createQualification: "createQualification",

    updateFederationBadge: "updateFederationBadge",
    updateEducation: "updateEducation",
    updateFederation: "updateFederation",
    updateQualification: "updateQualification",

    getFederationBadges: "getFederationBadges",
    getEducations: "getEducations",
    getFederations: "getFederations",
    getQualifications: "getQualifications",

    getAllSpecialistBadges: "getAllSpecialistBadges",
    getAllSpecialistUserBadges: "getAllSpecialistUserBadges",

    getAllSpecialistLevels: "getAllSpecialistLevels",

    createEducationCourse: "createEducationCourse",
    createEducationAccreditation: "createEducationAccreditation",

    getEducationCourses: "getEducationCourses",
    getEducationAccreditations: "getEducationAccreditations",

    updateEducationCourse: "updateEducationCourse",
    updateEducationAccreditation: "updateEducationAccreditation",

    createTaxCategory: "createTaxCategory",
    deleteTaxCategory: "deleteTaxCategory",
    getTaxCategories: "getTaxCategories",
    setSpecialistTaxCategory: "setSpecialistTaxCategory",
  },

  stripe: {
    createPayment: "createPayment",

    createPaymentForSession: "createPaymentForSession",

    createPaymentForSubscription: "createPaymentForSubscription",

    confirmPaymentSucceeded: "confirmPaymentSucceeded",

    confirmSubscriptionSucceeded: "confirmSubscriptionSucceeded",

    cancelSubscription: "cancelSubscription",

    subscriptionRenewalEvent: "subscriptionRenewalEvent",

    getAllPayments: "getAllPayments",

    createDepositPromocode: "createDepositPromocode",

    getAllDepositPromocodes: "getAllDepositPromocodes",

    handleStripeWebhook: "handleStripeWebhook",

    payDraftInvoice: "payDraftInvoice",

    savePayoutInfo: "savePayoutInfo",
    getPayoutInfo: "getPayoutInfo",
  },

  notes: {
    createNote: "createNote",

    update: "updateNote",

    getUserNotes: "getUserNotes",

    deleteNote: "deleteNote",

    getNoteById: "getNoteById",
  },

  todos: {
    createTodo: "createTodo",

    updateTodoList: "updateTodoList",
    update: "updateTodo",

    deleteTodoList: "deleteTodoList",
    deleteTodo: "deleteTodo",

    getUserTodos: "getUserTodos",

    createTodoList: "createTodoList",

    getTodoById: "getTodoById",

    getTodoListById: "getTodoListById",

    createTodoStatus: "createTodoStatus",
  },

  notifies: {
    createNotify: "createNotify",

    joinNotifyChannel: "joinNotifyChannel",

    sendNotify: "sendNotify",

    sendPassChangeConfirmation: "sendPassChangeConfirmation",
    sendRegisterConfirmation: "sendRegisterConfirmation",

    getUnreadNotifies: "getUnreadNotifies",

    markNotifyAsRead: "markNotifyAsRead",

    getNotifyById: "getNotifyById",

    sendInviteToCompany: "sendInviteToCompany",

    sendInviteToProject: "sendInviteToProject",

    sessionCancelNotify: "sessionCancelNotify",

    emailAboutCompanyEvent: "emailAboutCompanyEvent",

    emailAboutProjectKick: "emailAboutProjectKick",

    sendInviteToApp: "sendInviteToApp",

    emailAboutIncommingBooking: "emailAboutIncommingBooking",

    emailHourBeforeSession: "emailHourBeforeSession",

    emailAboutPackageBooking: "emailAboutPackageBooking",

    sendMobileNotify: "sendMobileNotify",

    emailAboutSubscriptionDeactivated: "emailAboutSubscriptionDeactivated",

    sendEmailConfirmationCode: "sendEmailConfirmationCode",

    sendNotifyAboutProfileAbandon: "sendNotifyAboutProfileAbandon",
  },

  companies: {
    createCompany: "createCompany",

    update: "updateCompany",

    deleteCompany: "deleteCompany",

    getCompanyById: "getCompanyById",

    getCompanyParticipants: "getCompanyParticipants",

    getCompanyStatistic: "getCompanyStatistic",

    inviteUserToCompany: "inviteUserToCompany",

    inviteBulkUsersToCompany: "inviteBulkUsersToCompany",

    joinCompany: "joinCompany",

    getCompanySessions: "getCompanySessions",

    getCompanyProjects: "getCompanyProjects",

    removeUserFromCompany: "removeUserFromCompany",

    getCompaniesOfUser: "getCompaniesOfUser",

    changeCompanyUserRole: "changeCompanyUserRole",

    getAllCompanies: "getAllCompanies",

    generateEntryCodeWithoutEmail: "generateEntryCodeWithoutEmail",
  },

  projects: {
    createProject: "createProject",

    update: "updateProject",

    deleteProject: "deleteProject",

    inviteUserToProject: "inviteUserToProject",

    addSpecialistsToProject: "addSpecialistsToProject",

    joinProject: "joinProject",

    joinNonPrivateProject: "joinNonPrivateProject",

    getProjectById: "getProjectById",

    removeUserFromProject: "removeUserFromProject",

    getProjectParticipants: "getProjectParticipants",

    changeProjectUserRole: "changeProjectUserRole",

    getUserProjects: "getUserProjects",

    getProjectVideoStream: "getProjectVideoStream",

    getFilteredProjectSpecialists: "getFilteredProjectSpecialists",

    getAvailableSessionsInProject: "getAvailableSessionsInProject",

    uploadVideoToProject: "uploadVideoToProject",

    deleteVideoFromProject: "deleteVideoFromProject",

    getMyProjectsWithoutPackage: "getMyProjectsWithoutPackage",

    getProjectPackageOfSpecialist: "getProjectPackageOfSpecialist",
  },

  google: {
    generateCode: "generateCode",
    redirect: "redirect",

    insertNewEvent: "insertNewEvent",

    checkGoogleRefreshToken: "checkGoogleRefreshToken",
  },

  stats: {
    getAdminDashboardStats: "getAdminDashboardStats",
  },

  posts: {
    createPost: "createPost",

    createAdminPost: "createAdminPost",

    deletePost: "deletePost",

    getPostById: "getPostById",

    getPostCommentById: "getPostCommentById",

    updatePost: "updatePost",

    updatePostFiles: "updatePostFiles",

    removePostFile: "removePostFile",

    getUserPosts: "getUserPosts",

    getFollowingPosts: "getFollowingPosts",

    likePost: "likePost",

    unlikePost: "unlikePost",

    commentPost: "commentPost",

    updateComment: "updateComment",

    removeComment: "removeComment",

    likeComment: "likeComment",

    unlikeComment: "unlikeComment",

    getPostFiles: "getPostFiles",

    getPostImageStream: "getPostImageStream",

    getPostVideoStream: "getPostVideoStream",

    getUnverifiedPosts: "getUnverifiedPosts",
  },

  socials: {
    followUser: "followUser",

    unfollowUser: "unfollowUser",

    getFollowers: "getFollowers",

    getFollowings: "getFollowings",

    checkIfFollowed: "checkIfFollowed",
  },

  subscriptions: {
    createSubscription: "createSubscription",
    updateSubscription: "updateSubscription",
    deleteSubscription: "deleteSubscription",
    getAllSubscriptions: "getAllSubscriptions",
    getActiveSubscribersAmount: "getActiveSubscribersAmount",
    getMyActiveSubscription: "getMyActiveSubscription",
    getMySubscriptionHistory: "getMySubscriptionHistory",
    subscribeToSubscription: "subscribeToSubscription",
    createSubscriptionShortInfo: "createSubscriptionShortInfo",
    createSubscriptionFeatures: "createSubscriptionFeatures",
    getSubscriptionIconStream: "getSubscriptionIconStream",
    updateSubscriptionShortInfo: "updateSubscriptionShortInfo",
    updateSubscriptionFeature: "updateSubscriptionFeature",
    deleteSubscriptionShortInfo: "deleteSubscriptionShortInfo",
    deleteSubscriptionFeature: "deleteSubscriptionFeature",
    updateSubscriptionShortInfoIcon: "updateSubscriptionShortInfoIcon",
    updateSubscriptionFeatureIcon: "updateSubscriptionFeatureIcon",
    getTotalSubscriptionsAmount: "getTotalSubscriptionsAmount",
  },

  treeQuizes: {
    createTreeQuiz: "createTreeQuiz",
    addTreeQuizFirstQuestion: "addTreeQuizFirstQuestion",
    getTreeQuiz: "getTreeQuiz",
    createTreeQuizQuestion: "createTreeQuizQuestion",
    addAnswerToTreeQuestion: "addAnswerToTreeQuestion",
    removeAnswerFromTreeQuestion: "removeAnswerFromTreeQuestion",
    getTreeQuizQuestion: "getTreeQuizQuestion",
    createTreeQuizAnswer: "createTreeQuizAnswer",
    changeTreeQuizAnswer: "changeTreeQuizAnswer",
    getTreeQuizAnswer: "getTreeQuizAnswer",
    saveTreeQuizAnswerResult: "saveTreeQuizAnswerResult",
    cancelTreeQuizAnswerResult: "cancelTreeQuizAnswerResult",
    createTreeQuizResult: "createTreeQuizResult",
    addAnswerResultToTreeQuizResult: "addAnswerResultToTreeQuizResult",
    getTreeQuizResult: "getTreeQuizResult",
    getRecommendationsFromTreeQuiz: "getRecommendationsFromTreeQuiz",
    getRecommendationQuiz: "getRecommendationQuiz",
    buildQuizes: "buildQuizes",
    buildTestSpecialists: "buildTestSpecialists",
  },

  twilio: {
    sendRegisterVerificationCode: "sendRegisterVerificationCode",
    verifyRegisterConfirmationCode: "verifyRegisterConfirmationCode",
  },

  deployFixes: {
    specialistIdToSpecialistIds: "specialistIdToSpecialistIds",

    specialistUsersToSpecialists: "specialistUsersToSpecialists",

    addLatinNameToUsers: "addLatinNameToUsers",

    scheduleReworkFix: "scheduleReworkFix",

    fixNamesMultilanguage: "fixNamesMultilanguage",
    fixIntroVideosMultilanguage: "fixIntroVideosMultilanguage",
  },
};

export const constants = {
  companyInviteLink: "http://localhost:3000/join-company/",
  projectInviteLink: "http://localhost:3000/join-project/",
};
