import { network } from "../config";
import {
  TreeQuiz,
  TreeQuizAnswerResult,
  TreeQuizQuestion,
  TreeQuizResult,
} from "../types/TreeQuiz";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { SessionPackage } from "../types/TypeSession";
import { TimeRange } from "../types/TypeSpecialist";
import { TypeUserData } from "../types/TypeUsers";
import customKy from "./kyExtend";

const { treeQuizes } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "tree-quizes/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class TreeQuizApi {
  static async saveTreeQuizAnswerResult(
    token: string,
    answerIds: string[],
    questionId: string
  ): Promise<
    TypeDefaultResponse & {
      answerResult?: TreeQuizAnswerResult | null;
      nextQuestion?: TreeQuizQuestion | null;
      progress?: number;
    }
  > {
    return await customKy
      .post(`${baseURL}${treeQuizes.saveTreeQuizAnswerResult}`, {
        headers: getAuthHeader(token),
        json: { answerIds, questionId },
      })
      .then((res) => res.json());
  }

  static async getRecommendationQuiz(
    token: string
  ): Promise<TypeDefaultResponse & { treeQuiz?: TreeQuiz }> {
    return await customKy
      .get(`${baseURL}${treeQuizes.getRecommendationQuiz}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getRecommendationsFromTreeQuiz(
    token: string,
    page: number,
    limit: number,
    alreadyFetched?: string[]
  ): Promise<
    TypeDefaultResponse & {
      recommendations?: (TypeUserData & { package?: SessionPackage })[];
      overlapDays?: {
        specialist: string;
        overlaps: { day: string; overlapTime: TimeRange[] }[];
      }[];
    }
  > {
    return await customKy
      .post(
        `${baseURL}${treeQuizes.getRecommendationsFromTreeQuiz}/${page}/${limit}`,
        {
          headers: getAuthHeader(token),
          json: { alreadyFetched },
        }
      )
      .then((res) => res.json());
  }

  static async getTreeQuizResult(
    token: string
  ): Promise<TypeDefaultResponse & { treeQuizResult?: TreeQuizResult }> {
    return await customKy
      .get(`${baseURL}${treeQuizes.getTreeQuizResult}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
  static async cancelTreeQuizAnswerResult(
    token: string,
    payload: {
      userId: string;
    }
  ): Promise<
    TypeDefaultResponse & {
      nextQuestion?: TreeQuizQuestion;
      progress?: number;
    }
  > {
    return await customKy
      .post(`${baseURL}${treeQuizes.cancelTreeQuizAnswerResult}`, {
        headers: getAuthHeader(token),
        json: { payload },
      })
      .then((res) => res.json());
  }
}
