import { useContext, useEffect, useState } from "react";
import { ContextProvider } from "../../../../contextProvider";
import strings from "../../../../localization";
import { SessionComment } from "../../../../types/TypeSession";
import s from "../SpecialistProfileContent.module.css";
import ReviewCard from "./ReviewCard/ReviewCard";

type ReviewsSectionProps = {
  commentsData: SessionComment[];
};

const ReviewsSection = ({ commentsData }: ReviewsSectionProps) => {
  const { userData, selectLang } = useContext(ContextProvider);
  const [currentSelectLang, setCurrentSelectLang] = useState("");

  useEffect(() => {
    const availableLang = userData
      ? userData.selectedLanguage
      : selectLang ?? "en";
    setCurrentSelectLang(availableLang);
  }, [selectLang, userData]);

  if (!commentsData || !commentsData.length) {
    return null;
  }

  return (
    <div className={s.reviewsBlock}>
      {commentsData && commentsData.length ? (
        <>
          <h4 className={s.blockLable}>
            {strings.reviewSpecProfile} ({commentsData.length})
          </h4>
          <div className={s.commentsListBlock}>
            {commentsData.map((item, index) => (
              <ReviewCard
                rating={item.rating}
                customComment={item.customComment}
                staticComments={item.defaultComments.map(
                  (item) =>
                    item.labels.find((el) => el.language === currentSelectLang)
                      ?.text!
                )}
                key={index}
              />
            ))}
          </div>
        </>
      ) : null}{" "}
    </div>
  );
};

export default ReviewsSection;
