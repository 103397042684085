import Compressor from "compressorjs";
import { getDocument } from "pdfjs-dist";

export const compressFiles = (
  files: File[],
  setCompressedFiles: React.Dispatch<React.SetStateAction<File[]>>
): void => {
  const compressed: File[] = [];

  files.forEach((file) => {
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const compressedFile = new File([result], file.name, {
          type: result.type,
          lastModified: Date.now(),
        });

        compressed.push(compressedFile);

        if (compressed.length === files.length) {
          setCompressedFiles((prev) => [...prev, ...compressed]);
        }
      },
      error(err) {
        console.error(err.message);
      },
    });
  });
};

export const getPdfThumbnail = async (pdfFileUrl: string) => {
  const pdf = await getDocument(pdfFileUrl).promise;
  const page = await pdf.getPage(1); // Получаем первую страницу

  const viewport = page.getViewport({ scale: 1 }); // Можешь изменить масштаб
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Canvas context not available"); // Обрабатываем случай, если контекст не получен
  }
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  const renderContext = {
    canvasContext: context,
    viewport: viewport,
  };
  await page.render(renderContext).promise;
  return canvas.toDataURL(); // Возвращаем изображение в формате base64
};
