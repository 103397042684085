import Newsfeed from "../../../../components/Newsfeed/Newsfeed";
import { TypeUserData } from "../../../../types/TypeUsers";
import s from "./PublishedPosts.module.css";

type PublishedPostsProps = {
  profileUserData: TypeUserData | null | undefined;
};

const PublishedPosts = ({ profileUserData }: PublishedPostsProps) => {
  return (
    <div className={s.container}>
      {profileUserData && <Newsfeed userId={profileUserData._id} />}
    </div>
  );
};

export default PublishedPosts;
