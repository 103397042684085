import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ProjectApi from "../../../api/projectApi";
import { ReactComponent as EditIcon } from "../../../assets/Notes/edit.svg";
import { ReactComponent as PlusIcon } from "../../../assets/plus-icon.svg";
import Input from "../../../components/Input/Input";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import StandartButton from "../../../components/StandartButton/StandartButton";
import UserAvatarList from "../../../components/UserAvatarList/UserAvatarList";
import strings from "../../../localization";
import { Project } from "../../../types/Company";
import { TypeUpdatedUserInfo } from "../../../types/TypeUsers";
import { formateDate } from "../../../utils/general";
import { SuccesNotify } from "../../../utils/toaster";
import s from "./ProgramCard.module.css";

type ProgramCardProps = {
  programTitle: string;
  date: string;
  participantsList: string[];
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  isSelected: boolean;
  selectProgram: Project | null;
  companyProgramsData: Project[] | null;
  setSelectedProgram: Dispatch<SetStateAction<Project | null>>;
};

const ProgramCard = ({
  programTitle,
  date,
  participantsList,
  setModalVisible,
  isSelected,
  selectProgram,
  companyProgramsData,
  setSelectedProgram,
}: ProgramCardProps) => {
  const token = localStorage.getItem("token");
  const [updateData, setUpdateData] = useState<TypeUpdatedUserInfo>({
    _id: selectProgram ? selectProgram._id : "",
  });
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const [showLimit, setShowLimit] = useState(3);

  useEffect(() => {
    const setShowLimitHandler = () => {
      const showLimitCount =
        window.innerWidth > 1700
          ? 7
          : window.innerWidth > 1600
          ? 4
          : window.innerWidth > 1400
          ? 3
          : 2;
      setShowLimit(showLimitCount);
    };
    window.addEventListener("resize", setShowLimitHandler);
    return () => {
      window.removeEventListener("resize", setShowLimitHandler);
    };
  }, []);

  useEffect(() => {
    setUpdateData({ _id: selectProgram ? selectProgram._id : "" });
  }, [selectProgram]);

  const updateProgram = async () => {
    if (token) {
      const payloadUpdateData = Object.keys(updateData)
        .filter((key) => {
          const value = updateData[key];
          return value !== "" && (typeof value !== "number" || !isNaN(value));
        })
        .reduce((acc, key) => {
          //@ts-ignore
          acc[key] = updateData[key];
          return acc;
        }, {});

      const keysUpdate = Object.keys(payloadUpdateData);
      if (keysUpdate.length === 1 && keysUpdate[0] === "_id")
        return setUpdateModalVisible(false);
      const response = await ProjectApi.update(
        token,
        updateData as TypeUpdatedUserInfo
      );

      if (response.status) {
        const currentData = companyProgramsData || [];
        const index = currentData.findIndex(
          (project) => project._id === response.project._id
        );
        if (index !== -1) {
          currentData[index] = response.project;
        }
        setSelectedProgram(response.project);
        setUpdateModalVisible(false);
        SuccesNotify(strings.projectUpdatedNotify);
      }
    }
  };

  const formateDateForProgram = (value: Date) => {
    const year = value.getFullYear();
    const month = (value.getMonth() + 1).toString().padStart(2, "0");
    const day = value.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  return (
    <div
      className={s.container}
      style={{
        border: isSelected
          ? "1px solid var(--primary-color)"
          : "1px solid transparent",
      }}
    >
      <div className={s.titleBlock}>
        <div>{programTitle}</div>
        <div className={s.actionBlock}>
          <span className={s.editButtonBlock}>
            <EditIcon
              className={s.editIcon}
              onClick={() => setUpdateModalVisible(true)}
            />
          </span>
          {/*  <span className={s.deleteButtonBlock}>
            <DeleteIcon className={s.deletIcon} />
          </span> */}
        </div>
      </div>
      <div className={s.participantsBlock}>
        <UserAvatarList userIds={participantsList} showLimit={showLimit} />
        <button
          className={s.addLinkButton}
          onClick={() => setModalVisible(true)}
        >
          <PlusIcon className={s.plusIcon} />
        </button>
      </div>
      <div className={s.dateTitle}>{formateDate(new Date(date))}</div>
      <ModalWindow
        setIsOpen={setUpdateModalVisible}
        isOpen={updateModalVisible}
        width="500px"
        padding="20px"
      >
        <div className={s.newProgramContainer}>
          <h2>{strings.updateProgramModalTitle}</h2>
          <div>
            <span className={s.inputTitle}>{strings.enterTitleProgram}</span>
            <Input
              onChangeInput={(value) =>
                setUpdateData((prev) => ({ ...prev, title: value }))
              }
              inputValue={
                "title" in updateData ? updateData.title : selectProgram?.title
              }
              isVisible
              required
            />
          </div>
          <div className={s.noteTextData}>
            <span className={s.inputTitle}>
              {strings.enterDescriptionProgram}
            </span>
            <textarea
              name=""
              id=""
              cols={30}
              rows={7}
              value={
                "description" in updateData
                  ? updateData.description
                  : selectProgram?.description
              }
              className={s.textAreaNewNote}
              onChange={(event) =>
                setUpdateData((prev) => ({
                  ...prev,
                  description: event.target.value,
                }))
              }
            ></textarea>
            <div className={s.inputsBlock}>
              {/*   <div className={s.inputElement}>
                <span className={s.inputTitle}>{strings.clientLimitLable}</span>
                <input
                  type="number"
                  min={1}
                  value={
                    "clientLimit" in updateData
                      ? updateData.clientLimit
                      : selectProgram?.clientLimit
                  }
                  onChange={(e) =>
                    setUpdateData((prev) => ({
                      ...prev,
                      clientLimit: e.target.valueAsNumber,
                    }))
                  }
                />
              </div>
              <div className={s.inputElement}>
                <span className={s.inputTitle}>
                  {strings.specialistsLimitLabel}
                </span>
                <input
                  type="number"
                  min={1}
                  value={
                    "specialistLimit" in updateData &&
                    updateData.specialistLimit
                      ? updateData.specialistLimit
                      : "specialistLimit" in updateData &&
                        !updateData.specialistLimit
                      ? ""
                      : selectProgram?.specialistLimit
                  }
                  onChange={(e) =>
                    setUpdateData((prev) => ({
                      ...prev,
                      specialistLimit: e.target.valueAsNumber,
                    }))
                  }
                />
              </div> */}
              <div className={s.inputElement}>
                <span className={s.inputTitle}>{strings.programEndDate}</span>
                <input
                  type="date"
                  value={formateDateForProgram(
                    updateData.endDate
                      ? new Date(updateData.endDate)
                      : new Date(selectProgram?.endDate!)
                  )}
                  onChange={(e) =>
                    setUpdateData((prev) => ({
                      ...prev,
                      endDate: new Date(e.target.value),
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className={s.newProgramButtonBlock}>
            <StandartButton
              buttonTitle={strings.saveUpdateRogramBtn}
              action={updateProgram}
              width="150px"
              disabled={
                Object.values(updateData).some(
                  (value) => value === "" || Number.isNaN(value) || !value
                )
                  ? true
                  : false
              }
              tooltipTitle={
                Object.values(updateData).some(
                  (value) => value === "" || Number.isNaN(value) || !value
                )
                  ? "Fill in all the fields"
                  : ""
              }
            />
          </div>
        </div>
      </ModalWindow>
    </div>
  );
};

export default ProgramCard;
