const MySessions = () => {
  return (
    <div
      style={{
        height: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
      data-testid="sessions-page"
    >
      <h1 style={{ textAlign: "center" }}>MySessions</h1>
    </div>
  );
};

export default MySessions;
