import { CircularProgress, Radio, ThemeProvider, Tooltip } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SpecialistApi from "../../api/specialistApi";
import { ReactComponent as ClockIcon } from "../../assets/Profile/specialist/clock.svg";
import { ReactComponent as Upload } from "../../assets/SpecTools/upload.svg";
import Input from "../../components/Input/Input";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import MuiSwitch from "../../components/MuiSwitch/MuiSwitch";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import NewShedule from "../../components/Schedule/NewShedule";
import StandartButton from "../../components/StandartButton/StandartButton";
import { videoExtensions } from "../../constans/filesExtensions";
import { ISO639LangReversed } from "../../constans/languagesList";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { SpecialistLevel } from "../../types/TypeSpecialist";
import { updateSpecialistIntroVideo } from "../../utils/setupProfileInfo";
import { transformSchedule } from "../../utils/shedule";
import { theme } from "../../utils/theme";
import Notify from "../../utils/toaster";
import SheduleSettings from "../Profile/SpecialistProfileContent/EditSpecialistProfile/SheduleSettings/SheduleSettings";
import s from "./AddNewSpecialization.module.css";

type MainSpecialiszationsData = {
  value: string;
  lable: string;
};

export type NewSpeciality = {
  _id?: string;
  mainSpecializations: string[];
  subSpecializations: string[];
  practiceHours: number | undefined;
  yearsOfExperience: number | undefined;
  aboutMyself: { text: string; language: string }[];
  aboutTherapy: { text: string; language: string }[];
  price: number | undefined;
  level: string | undefined;
  bufferTime?: { after: number | undefined; before: number | undefined };
  bookingTimeStep?: number;
  minimumBookingsGap?: number;
};

const initialSpecData: NewSpeciality = {
  mainSpecializations: [],
  subSpecializations: [],
  practiceHours: undefined,
  yearsOfExperience: undefined,
  aboutMyself: [],
  aboutTherapy: [],
  price: undefined,
  level: undefined,
  bufferTime: { after: 10, before: 10 },
  bookingTimeStep: 30,
  minimumBookingsGap: 24,
};

const emptyDaysState = [
  {
    dayValue: "Monday",
    daytitle: strings.scheduleMonday,
    isOpen: false,
    switch: false,
    workTime: [{ from: "", to: "" }],
  },
  {
    dayValue: "Tuesday",
    daytitle: strings.scheduleTuesday,
    isOpen: false,
    switch: false,
    workTime: [{ from: "", to: "" }],
  },
  {
    dayValue: "Wednesday",
    daytitle: strings.scheduleWednesday,
    isOpen: false,
    switch: false,
    workTime: [{ from: "", to: "" }],
  },
  {
    dayValue: "Thursday",
    daytitle: strings.scheduleThursday,
    isOpen: false,
    switch: false,
    workTime: [{ from: "", to: "" }],
  },
  {
    dayValue: "Friday",
    daytitle: strings.scheduleFriday,
    isOpen: false,
    switch: false,
    workTime: [{ from: "", to: "" }],
  },
  {
    dayValue: "Saturday",
    daytitle: strings.scheduleSaturday,
    isOpen: false,
    switch: false,
    workTime: [{ from: "", to: "" }],
  },
  {
    dayValue: "Sunday",
    daytitle: strings.scheduleSunday,
    isOpen: false,
    switch: false,
    workTime: [{ from: "", to: "" }],
  },
];

const AddNewSpecialization = () => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const navigate = useNavigate();
  const [newSpeciality, setNewSpeciality] =
    useState<NewSpeciality>(initialSpecData);
  const [newAboutMyself, setNewAboutMyself] = useState<
    { text: string; language: string; selected: boolean }[] | undefined
  >();
  const [newAboutTeraphy, setNewAboutTeraphy] = useState<
    { text: string; language: string; selected: boolean }[] | undefined
  >();
  const [checkedSpecializations, setCheckedSpecializations] = useState<
    MainSpecialiszationsData[]
  >([]);
  const [mainSpecializationsData, setMainSpecializationsData] = useState<
    MainSpecialiszationsData[]
  >([]);
  const [levelsData, setLevelsData] = useState<SpecialistLevel[] | undefined>();
  const [video, setVideo] = useState<{ language: string; file: File }[] | null>(
    null
  );
  const [sheduleModalIsOpen, setSheduleModalIsOpen] = useState(false);
  const [daysState, setDaysState] = useState(emptyDaysState);
  const [selectLangForTextField, setSelectLangForTextField] = useState(
    userData &&
      userData.preferredLanguages &&
      userData.preferredLanguages.length
      ? ISO639LangReversed[userData.preferredLanguages[0]]
      : "uk"
  );

  const getSubSpecializations = async (mainSpec: string) => {
    if (token && userData) {
      const subSpecResponse = await SpecialistApi.getSubSpecializations(token, [
        mainSpec,
      ]);

      if (subSpecResponse.status && subSpecResponse.subSpecializations) {
        const subSpecData = subSpecResponse.subSpecializations.map((item) => ({
          value: item._id,
          lable: item.labels.find(
            (item) => item.language === userData?.selectedLanguage
          )?.text!,
        }));

        setCheckedSpecializations(subSpecData);
      }
    }
  };

  const addSpeciality = async () => {
    if (!userData || !token) return;
    const response = await SpecialistApi.addNewSpecialistProfileToUser(
      token,
      userData._id
    );

    if (response.status && response.specialistId) {
      const newSpecialityData = {
        ...newSpeciality,
        schedule: transformSchedule(daysState),
        _id: response.specialistId,
      };

      const updateResponse = await SpecialistApi.update(
        newSpecialityData,
        token
      );
      if (video && token) {
        const filesData = video.map((item) => ({
          language: item.language,
          video: item.file,
        }));
        await updateSpecialistIntroVideo(
          response.specialistId,
          filesData,
          token
        );
      }
      if (updateResponse.status) {
        localStorage.setItem("isSpecialist", true.toString());
        navigate(
          `/${userData.latinName}-${userData.latinSurname}-${userData._id.slice(
            -7
          )}`
        );
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    if (!newAboutMyself) return;
    const aboutMyselfToUpdate = newAboutMyself
      .filter((el) => el.text.length > 0)
      .map((item) => ({
        text: item.text,
        language: item.language,
      }));
    setNewSpeciality((prev) => ({
      ...prev,
      aboutMyself: aboutMyselfToUpdate,
    }));
  }, [newAboutMyself]);

  useEffect(() => {
    if (!newAboutTeraphy) return;
    const aboutTeraphyToUpdate = newAboutTeraphy
      .filter((el) => el.text.length > 0)
      .map((item) => ({
        text: item.text,
        language: item.language,
      }));
    setNewSpeciality((prev) => ({
      ...prev,
      aboutTherapy: aboutTeraphyToUpdate,
    }));
  }, [newAboutTeraphy]);

  useEffect(() => {
    if (
      !newSpeciality ||
      !newSpeciality.mainSpecializations ||
      !newSpeciality.mainSpecializations.length
    )
      return;
    getSubSpecializations(newSpeciality.mainSpecializations[0]);
  }, [newSpeciality, newSpeciality && newSpeciality.mainSpecializations]);

  useEffect(() => {
    if (!userData) return;
    const emptyText = userData.preferredLanguages!.map((item, index) => ({
      text: "",
      language: ISO639LangReversed[item],
      selected: index === 0,
    }));
    setNewAboutMyself(emptyText);
    setNewAboutTeraphy(emptyText);
  }, [userData]);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userData) {
        const mainSpecializationsResponse =
          await SpecialistApi.getMainSpecializations(token);

        if (
          mainSpecializationsResponse.status &&
          mainSpecializationsResponse.mainSpecializations
        ) {
          const currentMainSpec = userData.specialistIds
            ? userData.specialistIds.map((item) => item.mainSpecializations[0])
            : [];
          const availableMainSpecData =
            mainSpecializationsResponse.mainSpecializations.filter(
              (item1) =>
                !currentMainSpec.some((item2) => item1._id === item2._id)
            );

          setMainSpecializationsData(
            availableMainSpecData.map((item) => ({
              value: item._id,
              lable: item.labels.find(
                (item) => item.language === userData?.selectedLanguage
              )?.text!,
            }))
          );
        }
      }
    };
    makeAsync();
  }, [userData]);

  const textFiledSetArr = [setNewAboutTeraphy, setNewAboutMyself];
  useEffect(() => {
    textFiledSetArr.map((setItem) =>
      setItem((prev) => {
        if (prev) {
          return prev.map((element) => {
            if (element.language === selectLangForTextField) {
              return {
                ...element,
                selected: true,
              };
            } else {
              return {
                ...element,
                selected: false,
              };
            }
          });
        } else return undefined;
      })
    );
  }, [selectLangForTextField]);

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const videoElement = document.createElement("video");
      const fileReader = new FileReader();

      fileReader.onload = () => {
        videoElement.src = fileReader.result as string;
        videoElement.onloadedmetadata = () => {
          const aspectRatio =
            videoElement.videoHeight / videoElement.videoWidth;
          if (aspectRatio < 1) {
            Notify(strings.onlyVerticalVideo);
            setVideo(null);
          } else {
            setVideo((prevVideos) => {
              if (!prevVideos) {
                // Если стейт пустой, добавляем новый объект
                return [{ language: selectLangForTextField, file: file }];
              }

              // Проверяем, есть ли объект с выбранным языком
              const videoIndex = prevVideos.findIndex(
                (video) => video.language === selectLangForTextField
              );

              if (videoIndex !== -1) {
                // Если видео с таким языком найдено, обновляем его
                const updatedVideos = [...prevVideos];
                updatedVideos[videoIndex].file = file;
                return updatedVideos;
              } else {
                // Если нет, добавляем новое видео в массив
                return [
                  ...prevVideos,
                  { language: selectLangForTextField, file: file },
                ];
              }
            });
          }
        };
      };

      fileReader.readAsDataURL(file);
    } else {
      setVideo(null);
    }
  };
  const getCurrentVideoSrc = () => {
    if (video && video.find((el) => el.language === selectLangForTextField)) {
      return URL.createObjectURL(
        video.find((el) => el.language === selectLangForTextField)?.file!
      );
    }
    return undefined;
  };
  const validateData = () => {
    if (
      !newSpeciality.mainSpecializations.length ||
      !newSpeciality.subSpecializations.length ||
      !newSpeciality.price
    ) {
      return false;
    }
    return true;
  };

  const getLevels = async () => {
    if (
      !newSpeciality.mainSpecializations ||
      !newSpeciality.mainSpecializations.length
    )
      return;
    const payload = {
      mainSpecId: newSpeciality.mainSpecializations[0],
    };
    const response = await SpecialistApi.getAllSpecialistLevels(payload);

    if (response.status && response.levels && response.levels.length) {
      setLevelsData(response.levels);
    }
  };

  useEffect(() => {
    if (!newSpeciality.mainSpecializations) return;
    getLevels();
  }, [newSpeciality.mainSpecializations]);

  const hasValidTimeRange = (
    ranges: {
      from: string;
      to: string;
    }[]
  ): boolean => {
    return ranges.some((range) => range.from !== "" && range.to !== "");
  };

  const onChangeUserDataText = (
    value: any,
    objKey: keyof NewSpeciality,
    maxLength?: number
  ) => {
    let trimmedValue = value.trim();
    if (maxLength && trimmedValue.length > maxLength) {
      trimmedValue = trimmedValue.substring(0, maxLength);
    }

    setNewSpeciality((prev) => ({
      ...prev,
      [objKey]: trimmedValue,
    }));
  };

  if (!newSpeciality) {
    return (
      <div className="loader-container">
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    );
  }

  return (
    <div className={s.container}>
      <div className={s.leftProfileBlock}>
        <div className={s.headerEditBlock}>
          <div></div>

          <StandartButton
            buttonTitle={strings.profileSaveBtn}
            action={addSpeciality}
            tooltipTitle={strings.packageRequiredFieldsNotify}
            disabled={!validateData()}
          />

          {/* <button className={s.saveProfileButton} onClick={updateData}>
          <span>{strings.profileSaveBtn}</span>
        </button> */}
        </div>
        <div className={s.multiselectInfoBlock}>
          {newAboutMyself && (
            <div className={s.aboutWriteBlock}>
              <div className={s.langTabBlock}>
                {userData!.preferredLanguages &&
                  userData!.preferredLanguages.length > 1 &&
                  userData!.preferredLanguages.map((item, index) => (
                    <div
                      key={index}
                      className={
                        selectLangForTextField === ISO639LangReversed[item]
                          ? s.activeLangTab
                          : s.notActiveLangTab
                      }
                      onClick={() =>
                        setSelectLangForTextField(ISO639LangReversed[item])
                      }
                    >
                      {
                        //@ts-expect-error
                        strings[item]
                      }
                      {selectLangForTextField === ISO639LangReversed[item] && (
                        <span className={s.langTabIndicator}></span>
                      )}
                    </div>
                  ))}
              </div>
              <div className={s.videoBlock}>
                <h2>{strings.videoTitle}</h2>
                <label>
                  <div className={s.uplaodFileBtn}>
                    <Upload />
                    {strings.uplaodVideo}
                  </div>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept={videoExtensions.join(", ")}
                    onChange={(e) => handleVideoChange(e)}
                  />
                </label>

                {getCurrentVideoSrc() && (
                  <video
                    src={getCurrentVideoSrc()}
                    className={s.video}
                    controls
                  ></video>
                )}
              </div>
              <div className={s.headLableBlock}>
                <h3>{strings.aboutMeSpecProfile}</h3>
                <div className={s.charterCalc}>
                  {newAboutMyself.find((el) => el.selected)?.text.length +
                    "/1500"}
                </div>
              </div>
              <textarea
                name=""
                id=""
                cols={30}
                rows={10}
                value={newAboutMyself.find((el) => el.selected)?.text!}
                className={s.textAreaNewNote}
                onChange={(event) =>
                  setNewAboutMyself((prev) => {
                    return prev
                      ? prev.map((item) => {
                          if (item.selected) {
                            return {
                              text:
                                event.target.value.length > 1500
                                  ? item.text
                                  : event.target.value,
                              language: item.language,
                              selected: true,
                            };
                          } else return item;
                        })
                      : undefined;
                  })
                }
              ></textarea>
            </div>
          )}
          {newAboutTeraphy && (
            <div className={s.aboutWriteBlock}>
              <div className={s.headLableBlock}>
                {/*   <div> */}
                <h3>{strings.howSessionGoesSpecProfile}</h3>
                <div className={s.charterCalc}>
                  {newAboutTeraphy.find((el) => el.selected)?.text.length +
                    "/1500"}
                </div>
                {/*      </div> */}
              </div>
              <textarea
                name=""
                id=""
                cols={30}
                rows={10}
                value={newAboutTeraphy.find((el) => el.selected)?.text!}
                className={s.textAreaNewNote}
                onChange={(event) =>
                  setNewAboutTeraphy((prev) => {
                    return prev
                      ? prev.map((item) => {
                          if (item.selected) {
                            return {
                              text:
                                event.target.value.length > 1500
                                  ? item.text
                                  : event.target.value,
                              language: item.language,
                              selected: true,
                            };
                          } else return item;
                        })
                      : undefined;
                  })
                }
              ></textarea>
            </div>
          )}
        </div>
        {levelsData && levelsData.length ? (
          <div className={s.levelsBlock}>
            <h3>{strings.levelStepTitle}</h3>
            <span className={s.blockDescriptionTitle}>
              {strings.levelStepDescriptuion}
            </span>
            {levelsData &&
              levelsData.map((item, index) => (
                <div
                  key={index}
                  className={s.selectLevelBlock}
                  onClick={() =>
                    setNewSpeciality((prev) => ({
                      ...prev,
                      level: item._id,
                    }))
                  }
                >
                  <Radio
                    checked={newSpeciality.level === item._id}
                    sx={{
                      color: "black",
                      "&.Mui-checked": {
                        color: "#3FB083",
                      },
                    }}
                  />

                  <div className={s.levelTextBlock}>
                    <span>
                      {
                        item.title.find(
                          (el) => el.language === userData?.selectedLanguage
                        )?.text
                      }
                    </span>
                    <span>
                      {
                        item.description.find(
                          (el) => el.language === userData?.selectedLanguage
                        )?.text
                      }
                    </span>
                  </div>
                </div>
              ))}
          </div>
        ) : null}
      </div>
      <div className={s.rightProfileBlock}>
        <div className={s.professionalBlock}>
          <div className={s.inputBlock}>
            <span>
              {strings.presSession}
              <span className={s.required}>*</span>
            </span>
            <Input
              type="number"
              inputValue={
                newSpeciality.price ? newSpeciality.price.toString() : ""
              }
              isVisible
              required
              onChangeInput={(value) =>
                setNewSpeciality((prev) => ({ ...prev!, price: Number(value) }))
              }
            />
          </div>
          <div className={s.inputBlock}>
            <span>{strings.specProfileYearsExp}</span>
            <Input
              type="number"
              inputValue={
                newSpeciality.yearsOfExperience
                  ? newSpeciality.yearsOfExperience.toString()
                  : ""
              }
              isVisible
              required
              onChangeInput={(value) =>
                setNewSpeciality((prev) => ({
                  ...prev!,
                  yearsOfExperience: Number(value),
                }))
              }
            />
          </div>
          <div className={s.inputBlock}>
            <span>{strings.specProfilePracticeHours}</span>
            <Input
              type="number"
              inputValue={
                newSpeciality.practiceHours
                  ? newSpeciality.practiceHours.toString()
                  : ""
              }
              isVisible
              required
              onChangeInput={(value) =>
                setNewSpeciality((prev) => ({
                  ...prev!,
                  practiceHours: Number(value),
                }))
              }
            />
          </div>
          <div className={s.qualificationBlock}>
            <h2>{strings.qualification}</h2>
            <div className={s.multiSelectBlock}>
              <span>
                {strings.filterMainSpec} <span className={s.required}>*</span>
              </span>
              <MultipleSelect
                multiplie={false}
                data={mainSpecializationsData}
                setValue={(value) => {
                  setNewSpeciality((prev) => ({
                    ...prev!,
                    subSpecializations: [],
                    mainSpecializations: [value] as string[],
                  }));
                }}
                value={
                  newSpeciality.mainSpecializations
                    ? newSpeciality.mainSpecializations.toString()
                    : ""
                }
              />
            </div>
            <div className={s.multiSelectBlock}>
              <span>
                {strings.filterSubSpec}
                <span className={s.required}>*</span>
              </span>
              <MultipleSelect
                multiplie={true}
                data={checkedSpecializations}
                setValue={(value) =>
                  setNewSpeciality((prev) => ({
                    ...prev!,
                    subSpecializations: value as string[],
                  }))
                }
                disabled={
                  newSpeciality.mainSpecializations &&
                  newSpeciality.mainSpecializations.length
                    ? false
                    : true
                }
                value={newSpeciality.subSpecializations}
              />
            </div>
          </div>
        </div>

        <div className={s.sheduleBlock}>
          <h2>{strings.timeToBookTitle}</h2>

          <div>
            {daysState && daysState.length
              ? daysState.map((item, index) => (
                  <div className={s.scheduleElement} key={index}>
                    <div className={s.sheduleClockBlock}>
                      <ClockIcon />
                      <div className={s.dayTimeBlock}>
                        <span>{item.daytitle}</span>
                        <span>
                          {!hasValidTimeRange(item.workTime) || !item.switch ? (
                            strings.dayOffShedulTitle
                          ) : (
                            <span className={s.workingDayTitleBlock}>
                              {strings.workingDay}
                              <Tooltip
                                title={
                                  <div>
                                    {item.workTime.map((time, index) => (
                                      <div key={index}>
                                        {time.from} - {time.to}
                                      </div>
                                    ))}
                                  </div>
                                }
                                arrow
                              >
                                <span className={s.sheduleTooltip}>!</span>
                              </Tooltip>
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className={s.sheduleSwitchBlock}>
                      <MuiSwitch
                        lable=""
                        checked={item.switch}
                        setChecked={(value) => {
                          const updatedSchedule = daysState.map((day) =>
                            day.dayValue === item.dayValue
                              ? { ...day, switch: value }
                              : day
                          );
                          setDaysState(updatedSchedule);
                        }}
                      />
                    </div>
                  </div>
                ))
              : null}
          </div>
          <StandartButton
            action={() => {
              setSheduleModalIsOpen(true);
            }}
            buttonTitle={strings.changeBtn}
          />

          <ModalWindow
            isOpen={sheduleModalIsOpen}
            setIsOpen={setSheduleModalIsOpen}
            width="1000px"
            padding="0"
            bgColor="transparent"
            showCloseBtn={false}
          >
            <div>
              <div className={s.sheduleSettingsModalBlock}>
                <NewShedule
                  allDaysState={daysState}
                  setDaysState={setDaysState}
                  //@ts-expect-error
                  onChangeUserDataText={onChangeUserDataText}
                  profileUserData={userData}
                />

                <SheduleSettings
                  setNewSpecialistData={setNewSpeciality}
                  specialistUpdateData={newSpeciality}
                  scheduleSettingsData={{
                    bufferTime: newSpeciality.bufferTime,
                    bookingTimeStep: newSpeciality.bookingTimeStep,
                    minimumBookingsGap: newSpeciality.minimumBookingsGap,
                  }}
                />
              </div>
              <div className={s.sheduleSettingsModalBtnBlock}>
                <button
                  onClick={() => setSheduleModalIsOpen(false)}
                  className={s.cancelShedulBtn}
                >
                  {strings.cancelShedulModal}
                </button>
                <StandartButton
                  buttonTitle={strings.saveSheduleModal}
                  action={() => setSheduleModalIsOpen(false)}
                  width="50%"
                />
              </div>
            </div>
          </ModalWindow>
        </div>
      </div>
    </div>
  );
};

export default AddNewSpecialization;
