import React, { useState } from "react";
import { ReactComponent as StarIcon } from "../../../../../assets/Profile/specialist/green-star.svg";
import ModalWindow from "../../../../../components/ModalWindow/ModalWindow";
import strings from "../../../../../localization";
import s from "./ReviewCard.module.css";

interface ReviewProps {
  customComment?: string;
  staticComments?: string[];
  rating: number;
}

const ReviewCard: React.FC<ReviewProps> = ({
  customComment,
  staticComments,
  rating,
}) => {
  const [showFullReview, setShowFullReview] = useState(false);
  const sliceStaticCommentsNumber = window.innerWidth > 768 ? 5 : 2;
  const customCommentSliceNumber = window.innerWidth > 768 ? 350 : 300;
  const isLongReview =
    (customComment && customComment.length > customCommentSliceNumber) ||
    (staticComments && staticComments.length > sliceStaticCommentsNumber);

  return (
    <div className={s.card}>
      <div className={s.ratingBlock}>
        {Array.from({ length: rating }).map((_, index) => (
          <StarIcon className={s.starIcon} key={index} />
        ))}
        <span>{rating + ".0"}</span>
      </div>
      {customComment && (
        <p className={s.customComment}>
          {customComment.slice(0, customCommentSliceNumber) +
            (customComment.length > customCommentSliceNumber ? "..." : "")}
        </p>
      )}

      {staticComments && (
        <div className={s.staticComments}>
          {staticComments
            .slice(0, sliceStaticCommentsNumber)
            .map((comment, index) => (
              <span key={index} className={s.staticComment}>
                {comment}
              </span>
            ))}
        </div>
      )}

      {isLongReview && (
        <button
          className={s.showMoreButton}
          onClick={() => setShowFullReview(true)}
        >
          {strings.showAllComment}
        </button>
      )}
      <ModalWindow
        isOpen={showFullReview}
        setIsOpen={setShowFullReview}
        width="fit-content"
      >
        <div className={s.card}>
          <div className={s.ratingBlock}>
            {Array.from({ length: rating }).map((_, index) => (
              <StarIcon className={s.starIcon} key={index} />
            ))}
            <span>{rating + ".0"}</span>
          </div>
          {customComment && <p>{customComment}</p>}

          {staticComments && (
            <div className={s.staticComments}>
              {staticComments.map((comment, index) => (
                <span key={index} className={s.staticComment}>
                  {comment}
                </span>
              ))}
            </div>
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

export default ReviewCard;
