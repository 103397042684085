import { network } from "../config";
import { NotificationsData } from "../types/Notifications";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import customKy from "./kyExtend";

const { notifies, google } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "notify/";
const googleUrl = process.env.REACT_APP_HTTP_CONNECTION_STRING + "google/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class NotificationsApi {
  static async getUnreadNotifies(
    token: string,
    userId: string
  ): Promise<
    TypeDefaultResponse & {
      notifies: NotificationsData[];
    }
  > {
    return await customKy
      .get(`${baseURL}${notifies.getUnreadNotifies}/${userId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async markNotifyAsRead(
    token: string,
    payload: { notifiesIds: string[] }
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .post(`${baseURL}${notifies.markNotifyAsRead}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async generateCode(token: string): Promise<
    TypeDefaultResponse & {
      url?: string;
    }
  > {
    return await customKy
      .get(`${googleUrl}${google.generateCode}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async checkGoogleRefreshToken(
    token: string
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .get(`${googleUrl}${google.checkGoogleRefreshToken}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
}
