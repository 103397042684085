export type WorkTime = {
  from: string;
  to: string;
};

export type DaySchedule = {
  dayValue: string;
  daytitle: string;
  isOpen: boolean;
  workTime: WorkTime[];
  switch: boolean;
};

export const transformSchedule = (daysState: DaySchedule[]) => {
  const workTimeData: Record<string, { time: string[]; switch: boolean }> =
    daysState.reduce((acc, { dayValue, workTime, switch: switchState }) => {
      const dayKey = dayValue.toLowerCase();

      if (workTime.length === 0) {
        acc[dayKey] = { time: ["DayOff"], switch: switchState };
      } else {
        acc[dayKey] = {
          time: workTime.map((item) => item.from + "-" + item.to),
          switch: switchState,
        };
      }
      return acc;
    }, {} as Record<string, { time: string[]; switch: boolean }>);

  const transformedSchedule: Record<
    string,
    { time: string[]; switch: boolean }
  > = {};

  for (const day in workTimeData) {
    const { time, switch: switchState } = workTimeData[day];

    if (time.length === 1 && time[0] === "-") {
      transformedSchedule[day] = { time: ["DayOff"], switch: switchState };
    } else {
      const filteredTime = time.filter((t) => t !== "-");
      transformedSchedule[day] = {
        time: filteredTime.length > 0 ? filteredTime : ["DayOff"],
        switch: switchState,
      };
    }
  }

  return transformedSchedule;
};
