import { useCallback, useContext, useState } from "react";
import SessionsApi from "../../../../../api/sessionApi";
import { ContextProvider } from "../../../../../contextProvider";
import useInfiniteScroll from "../../../../../hooks/InfinityScroll";
import { SessionComment } from "../../../../../types/TypeSession";
import ReviewCard from "../ReviewCard/ReviewCard";
import s from "./AllReviews.module.css";

type AllReviewsProps = {
  userId: string;
};

const AllReviews = ({ userId }: AllReviewsProps) => {
  const { userData } = useContext(ContextProvider);
  const [commentsData, setCommentsData] = useState<
    SessionComment[] | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const getSpacialistReviews = useCallback(
    async (page: number) => {
      setIsLoading(true);
      try {
        const commentsResponse = await SessionsApi.getSpecialistsComments(
          userId,
          15,
          page
        );

        if (commentsResponse.status) {
          setHasMore(commentsResponse.comments.length === 15);
          setCommentsData((prev) =>
            prev
              ? [...prev, ...commentsResponse.comments]
              : commentsResponse.comments
          );
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [userId]
  );

  const { scrollContainerRef } = useInfiniteScroll({
    fetchData: getSpacialistReviews,
    hasMore,
    isLoading: isLoading,
  });

  return (
    <div className={s.container} ref={scrollContainerRef}>
      {commentsData && commentsData.length
        ? commentsData.map((item, index) => (
            <ReviewCard
              rating={item.rating}
              customComment={item.customComment}
              staticComments={item.defaultComments.map(
                (item) =>
                  item.labels.find(
                    (el) => el.language === userData?.selectedLanguage
                  )?.text!
              )}
              key={index}
            />
          ))
        : null}
    </div>
  );
};

export default AllReviews;
