import { useEffect } from "react";
import { useCustomTimer } from "../../hooks/Timer";

interface TimeLeft {
  hours: number;
  minutes: number;
  seconds: number;
}

type TimerComponentProps = {
  date: Date;
  timeLimit: number;
  renderTimerFunc: (timeObj: TimeLeft) => JSX.Element;
  timeOffFunc?: (value: true) => void;
};

const TimerComponent = ({
  date,
  timeLimit,
  renderTimerFunc,
  timeOffFunc,
}: TimerComponentProps) => {
  const remainingTime = useCustomTimer(date, timeLimit);
  useEffect(() => {
    if (
      !timeOffFunc ||
      remainingTime.hours + remainingTime.minutes + remainingTime.seconds > 0
    )
      return;
    timeOffFunc(true);
  }, [remainingTime]);

  return <div>{renderTimerFunc(remainingTime)}</div>;
};

export default TimerComponent;
