import { CircularProgress, ThemeProvider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import CompanyApi from "../../../api/companyApi";
import ProjectApi from "../../../api/projectApi";
import SettingIcon from "../../../assets/Company/settings.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/Notes/delete.svg";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import NavigateToProfile from "../../../components/NavigateToProfile/NavigateToProfile";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { Project, Roles, TypeCompanyEmployee } from "../../../types/Company";
import { theme } from "../../../utils/theme";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import s from "./EmployerCard.module.css";

type EmployerCardProps = {
  userId: string;
  fullName: string;
  role: { value: string; translatedValue: string };
  program: string[];
  email: string;
  setFilteredEmployers: Dispatch<SetStateAction<TypeCompanyEmployee[] | null>>;
};

const EmployerCard = ({
  fullName,
  role,
  program,
  email,
  userId,
  setFilteredEmployers,
}: EmployerCardProps) => {
  const token = localStorage.getItem("token");
  const { userCompany } = useContext(ContextProvider);
  const [companyProjects, setCompanyProjects] = useState<Project[] | null>(
    null
  );
  const [removeLoader, setRemoveLoader] = useState(false);
  const [programSetupLoader, setProgramSetupLoader] = useState(false);
  const [removeUserModalVisible, setRemoveUserModalVisible] = useState(false);
  const [
    removeUserFormProjectModalVisible,
    setRemoveUserFormProjectModalVisible,
  ] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(
    null
  );
  const [programsSetupModalVisible, setProgramsSetupModalVisible] =
    useState(false);
  const [userProgramData, setUserProgramData] = useState(program);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userCompany) {
        const response = await CompanyApi.getCompanyProjects(
          token,
          userCompany[0]._id
        );
        if (response.status) setCompanyProjects(response.projects);
      }
    };
    makeAsync();
  }, [userCompany]);

  const removeUserfromCompany = async () => {
    if (token && userCompany) {
      const payload = { userIdToRemove: userId, companyId: userCompany[0]._id };
      setRemoveLoader(true);
      const response = await CompanyApi.removeUserFromCompany(token, payload);
      setRemoveLoader(false);
      if (response.status) {
        setFilteredEmployers((prev) => {
          if (!prev) return prev;
          return prev.filter((emploee) => emploee.user._id !== userId);
        });
        SuccesNotify(strings.userHasRemoved);
        setRemoveUserModalVisible(false);
      }
    }
  };

  const removeUserFromProject = async () => {
    if (token && selectedProjectId) {
      const payload = {
        userIdToRemove: userId,
        projectId: selectedProjectId,
      };
      setProgramSetupLoader(true);
      setRemoveUserFormProjectModalVisible(false);
      const response = await ProjectApi.removeUserFromProject(token, payload);
      setProgramsSetupModalVisible(false);
      setProgramSetupLoader(false);
      if (response.status) {
        const removedTitle =
          companyProjects &&
          companyProjects.filter(
            (project) => project._id === selectedProjectId
          );
        setUserProgramData((prev) =>
          prev.filter((program) => program !== removedTitle![0].title)
        );
        SuccesNotify(strings.removeUserSucces);
      } else {
        Notify(response.message ? response.message : strings.serverError);
      }
    }
  };
  const inviteUserToProjects = async (projectId: string) => {
    if (token) {
      setProgramSetupLoader(true);
      const response = await ProjectApi.inviteUserToProject(token, {
        emails: [email],
        projectId: projectId,
        role: Roles.DEEFAULT_USER,
      });

      if (response[0].status) {
        SuccesNotify(strings.invitationSent);
        setProgramsSetupModalVisible(false);
      }
      setProgramSetupLoader(false);
    }
  };

  return (
    <div className={s.cardContainer}>
      <div className={s.nameBlock}>
        <NavigateToProfile userId={userId}>
          <CircleAvatar
            name={fullName.split(" ")[0]}
            surname={fullName.split(" ")[1]}
            userId={userId}
          />
        </NavigateToProfile>
        <NavigateToProfile userId={userId}>
          <div className={s.nameTitleBlock}>
            <span className={s.nameTitle}>{fullName}</span>
          </div>
        </NavigateToProfile>
      </div>

      <div className={s.positionBlock}>{role.translatedValue}</div>
      <div className={s.programBlock}>
        <div style={{ width: "70%" }}>
          {userProgramData.map((item, index) => (
            <span className={s.programTitleEl} key={index}>
              {item}
              {userProgramData.length > 1 &&
                index < userProgramData.length - 1 &&
                ","}
            </span>
          ))}
        </div>
        {role.value !== "specialist" && (
          <img
            src={SettingIcon}
            alt=""
            className={s.settingIcon}
            onClick={() => setProgramsSetupModalVisible(true)}
          />
        )}
        <div className={s.mobileDeletBlock}>
          {role.value !== "owner" && (
            <Tooltip
              title={strings.removeFromCompanyTooltip}
              placement="right"
              arrow
            >
              <span
                className={s.deleteButtonBlock}
                onClick={() => setRemoveUserModalVisible(true)}
              >
                <DeleteIcon className={s.deletIcon} />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      <div className={s.completedBlock}>
        <span className={s.emailTitle}>{email}</span>
        <div className={s.tripleDotIconBlock}>
          {role.value !== "owner" && (
            <Tooltip
              title={strings.removeFromCompanyTooltip}
              placement="right"
              arrow
            >
              <span
                className={s.deleteButtonBlock}
                onClick={() => setRemoveUserModalVisible(true)}
              >
                <DeleteIcon className={s.deletIcon} />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      <ModalWindow
        isOpen={programsSetupModalVisible}
        setIsOpen={setProgramsSetupModalVisible}
        width="fit-content"
        bgColor={programSetupLoader ? "transparent" : "white"}
      >
        {programSetupLoader ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ThemeProvider theme={theme}>
              <CircularProgress size={140} color="primary" />
            </ThemeProvider>
          </div>
        ) : (
          <div className={s.programsModalContainer}>
            <h2>{strings.programsModalTitle}</h2>
            <div className={s.programsList}>
              {companyProjects &&
                companyProjects.map((item, index) => (
                  <div className={s.programElement} key={index}>
                    <span>{item.title}</span>
                    {userProgramData.includes(item.title) ? (
                      <button
                        className={s.removeButton}
                        onClick={() => {
                          setSelectedProjectId(item._id);
                          setRemoveUserFormProjectModalVisible(true);
                        }}
                      >
                        {strings.removeEmployeeBtn}
                      </button>
                    ) : (
                      <StandartButton
                        action={() => inviteUserToProjects(item._id)}
                        buttonTitle={strings.employeeInviteBtn}
                        height="35px"
                        fontSize="15px"
                        width="100px"
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      </ModalWindow>
      <ConfirmationModal
        confirmFunc={removeUserfromCompany}
        confirmTitle={strings.employeeWarningModalTitle1}
        modalVisible={removeUserModalVisible}
        setModalVisible={setRemoveUserModalVisible}
        loader={removeLoader}
      />
      <ConfirmationModal
        confirmFunc={removeUserFromProject}
        confirmTitle={strings.employeeWarningModalTitle2}
        modalVisible={removeUserFormProjectModalVisible}
        setModalVisible={setRemoveUserFormProjectModalVisible}
      />
    </div>
  );
};

export default EmployerCard;
