import { CircularProgress, ThemeProvider } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import SessionsApi from "../../api/sessionApi";
import CommentComponent from "../../components/CommentComponent/CommentComponent";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import { ContextProvider } from "../../contextProvider";
import {
  filterRecentSessions,
  formatDateTimeWithOutTimezone,
} from "../../utils/general";
import { theme } from "../../utils/theme";
import s from "./Initial.module.css";

import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import AnimateHeight from "react-animate-height";
import NotesAPI from "../../api/notesApi";
import { ReactComponent as ChevronUp } from "../../assets/HomePage/chevron-up.svg";
import { ReactComponent as CloseIcon } from "../../assets/HomePage/close.svg";
import { ReactComponent as EmptyBookingsIcon } from "../../assets/HomePage/noRequest.svg";
import EmptySessionsIcon from "../../assets/HomePage/noSessions.svg";
import { ReactComponent as PlusIcon } from "../../assets/Profile/specialist/add.svg";
import Input from "../../components/Input/Input";
import MuiCalendar from "../../components/MuiCalendar/MuiCalendar";
import Newsfeed from "../../components/Newsfeed/Newsfeed";
import NextSessionCard from "../../components/NextSessionCard/NextSessionCard";
import NoteCard from "../../components/NoteCard/NoteCard";
import StandartButton from "../../components/StandartButton/StandartButton";
import strings from "../../localization";
import { Note } from "../../types/TypeNotes";
import { Booking, SessionPackage } from "../../types/TypeSession";
import { SpecialistTag } from "../../types/TypeSpecialist";
import { convertDateForDaySlots } from "../../utils/dateActions";
import { getTranslation } from "../../utils/getTranslation";
import Notify from "../../utils/toaster";
import TreeQuiz from "../TreeQuiz/TreeQuiz";
import PackageBookingCard from "./PackageBookingCard/PackageBookingCard";

dayjs.extend(customParseFormat);
export type SessionsData = {
  userId: string;
  clientData: { name: string; surname: string; _id: string };
  specialistData: { name: string; surname: string; _id: string };
  specialistId?: string;
  bookingId: string;
  acceptSession: boolean;
  bookedSessionId?: string;
  creationDate?: Date;
  sessionDate: string;
  sessionTime: string;
  duration: number;
  isAccepted?: boolean;
  isMySession: boolean;
  project?: { _id: string; title: string };
  package?: SessionPackage;
  subject?: string;
  tags?: SpecialistTag[];
};

export type PackageBookingType = {
  userId: string;
  packageId: string;
  packageTitle: { language: string; text: string }[];
  specialistName: string | undefined;
  creationDate: Date;
  specialistSurname: string | undefined;
  isAccepted?: boolean;
  isMySession: boolean;
  bookings: Booking[];
  project?: { _id: string; title: string };
};
const currentDate = new Date();

const day = currentDate.getDate().toString().padStart(2, "0");
const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // добавляем 1, так как месяцы в JS начинаются с 0
const year = currentDate.getFullYear().toString().slice(-2); // берем только последние две цифры года

const formattedDate = `${day}.${month}.${year}`;
const Initial = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const isSpecialistStored = localStorage.getItem("isSpecialist");
  const isSpecialist =
    isSpecialistStored !== null ? JSON.parse(isSpecialistStored) : false;
  const { userData /* isSpecialist, setIsSpecialist */ } =
    useContext(ContextProvider);
  const firstBlockRef = useRef<HTMLDivElement | null>(null);
  const notesBlockRef = useRef<HTMLDivElement | null>(null);
  const [sessionsData, setSessionsData] = useState<null | SessionsData[]>(null);
  const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
  const [isLoader, setIsLoader] = useState({
    sessionCardLoader: true,
  });
  const [filtredSessionData, setFiltredSessionData] = useState<
    null | SessionsData[]
  >(null);
  const [newNoteModal, setNewNoteModal] = useState(false);
  const [bookSessionsData, setBookSessionsData] = useState<
    null | SessionsData[]
  >(null);
  const [packagesBookingData, setPackagesBookingData] = useState<
    PackageBookingType[] | null
  >(null);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [sessionsForSelectDate, setSessionsForSelectDate] = useState<
    null | SessionsData[]
  >(null);
  const [daySlots, setDaySlots] = useState<string[]>([]);
  const [notesData, setNotesData] = useState<Note[] | null>(null);
  const [page, setPage] = useState(1);
  const [noteLoading, setNoteLoading] = useState(false);
  const [selectNote, setSelectNote] = useState<Note | null>(null);
  const [newNote, setNewNote] = useState({
    title: "",
    date: formattedDate,
    noteText: "",
  });
  const [newPostModalVisible, setNewPostModalVisible] = useState(false);
  const [activeMiddleContenTab, setActiveMiddleContenTab] = useState<
    "quize" | "newsfeed"
  >(isSpecialist ? "newsfeed" : "quize");
  const [isOpenCards, setIsOpenCards] = useState({ notes: true });

  useEffect(() => {
    if (!filtredSessionData || !selectedDate) return;
    const sessionsDates = filtredSessionData.map((item) =>
      convertDateForDaySlots(item.sessionDate)
    );

    setDaySlots(sessionsDates);
    const filteredSessions = filtredSessionData.filter((session) => {
      const sessionDay = dayjs(session.sessionDate, "DD.MM");
      return sessionDay.isSame(selectedDate, "day");
    });

    setSessionsForSelectDate(filteredSessions);
  }, [filtredSessionData, selectedDate]);

  useEffect(() => {
    if (!sessionsData || !bookSessionsData || !userData) return;

    const newClientSessionData = filterRecentSessions(
      sessionsData,
      userData.timezone
    );

    setFiltredSessionData(
      filterRecentSessions(newClientSessionData, userData.timezone)
    );
  }, [bookSessionsData, sessionsData]);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !location.state || !location.state.sessionId) return;
      const commentLeaveResponse = await SessionsApi.checkIfCanLeaveComment(
        token,
        location.state.sessionId
      );
      if (commentLeaveResponse.status) {
        setCommentModalIsOpen(
          location.state && location.state.ratingOpen
            ? location.state.ratingOpen
            : false
        );
      }
    };
    makeAsync();
  }, []);

  useEffect(() => {
    setIsLoader({
      sessionCardLoader: sessionsData ? false : true,
    });
  }, [sessionsData]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && userData && userData.bookings) {
      const makeAsync = async () => {
        const responseBookings = await SessionsApi.getUsersBooking(
          userData._id,
          token
        );

        const responsePackagesBookings =
          await SessionsApi.getUsersBookedPackages(token, userData._id);

        if (
          responsePackagesBookings.status &&
          responsePackagesBookings.packages
        ) {
          const bookPackages = responsePackagesBookings.packages.map((item) => {
            const isMyBooking = item.from._id === userData._id;
            return {
              userId: !isMyBooking ? item.from._id : item.for._id,
              packageId: item._id,
              packageTitle: item.title,
              specialistName: !isMyBooking
                ? getTranslation(item.from.name!, userData.selectedLanguage)
                : getTranslation(item.for.name!, userData.selectedLanguage),
              creationDate: item.bookings[0].createdAt!,
              specialistSurname: !isMyBooking
                ? getTranslation(item.from.surname!, userData.selectedLanguage)
                : getTranslation(item.for.surname!, userData.selectedLanguage),
              isAccepted: item.isAccepted,
              isMySession: isMyBooking,
              bookings: item.bookings,
            };
          });

          setPackagesBookingData(bookPackages);
        }

        if (responseBookings.status && responseBookings.bookings) {
          const bookSessions = responseBookings.bookings.map((item) => {
            const isMyBooking = item.from._id === userData._id;
            return {
              userId: !isMyBooking ? item.from._id : item.for._id,
              clientData: {
                _id: item.from._id,
                name: getTranslation(
                  item.from.name!,
                  userData.selectedLanguage
                ),
                surname: getTranslation(
                  item.from.surname!,
                  userData.selectedLanguage
                ),
              },
              specialistData: {
                _id: item.for._id,
                name: getTranslation(item.for.name!, userData.selectedLanguage),
                surname: getTranslation(
                  item.for.surname!,
                  userData.selectedLanguage
                ),
              },
              bookingId: item._id,
              bookedSessionId: item.bookedSessions && item.bookedSessions[0],
              acceptSession: item.isAccepted,
              creationDate: item.createdAt,
              duration: item.datesAndTime[0].duration,
              sessionDate: formatDateTimeWithOutTimezone(
                item.datesAndTime[0].date
              ).formattedDate,
              sessionTime: formatDateTimeWithOutTimezone(
                item.datesAndTime[0].date
              ).formattedTime,
              isAccepted: item.isAccepted,
              isMySession: isMyBooking,
              project: item.project,
              subject: item.subject,
              tags: item.tags,
            };
          });

          setBookSessionsData(bookSessions);
        }

        const today = new Date();
        const startDate = new Date();
        startDate.setDate(today.getDate() - 0);
        const futureDate = new Date();
        futureDate.setDate(today.getDate() + 30);

        const sessionResponse = await SessionsApi.getAllUserSessions(
          userData._id,
          token,
          startDate,
          futureDate
        );

        if (sessionResponse.status && sessionResponse.sessions) {
          const sessions = sessionResponse.sessions.map((item) => {
            const clientData = item.invitedUsers.find(
              (user) =>
                !user.specialistIds ||
                !user.specialistIds.length ||
                !user.specialistIds.some((specId) =>
                  item.specialists.includes(specId._id)
                )
            );
            const specialistData = item.invitedUsers.find(
              (user) =>
                user.specialistIds &&
                user.specialistIds.length &&
                user.specialistIds.some((specId) =>
                  item.specialists.includes(specId._id)
                )
            );

            const iAmSpec =
              item.invitedUsers.find(
                (el) => el.specialistIds && el.specialistIds.length > 0
              )!._id === userData._id;
            const avatar =
              iAmSpec && clientData
                ? clientData.avatar
                : !iAmSpec && specialistData
                ? specialistData.avatar
                : "";
            const userId =
              iAmSpec && clientData
                ? clientData._id
                : !iAmSpec && specialistData
                ? specialistData._id
                : "";
            return {
              userId: userId,
              clientData: {
                _id: clientData!._id,
                name: getTranslation(
                  clientData!.name!,
                  userData.selectedLanguage
                ),
                surname: getTranslation(
                  clientData!.surname!,
                  userData.selectedLanguage
                ),
              },
              specialistData: {
                _id: specialistData!._id,
                name: getTranslation(
                  specialistData!.name!,
                  userData.selectedLanguage
                ),
                surname: getTranslation(
                  specialistData!.surname!,
                  userData.selectedLanguage
                ),
              },
              specialistId: item.specialists && item.specialists[0],
              isMySession: iAmSpec,
              bookingId: item._id,
              bookedSessionId: item._id,
              acceptSession: true,
              specializations:
                (specialistData &&
                  specialistData.specialistIds![0].specializations) ||
                [],
              duration: item.datesAndTime[0].duration,
              avatar: avatar,
              sessionDate: formatDateTimeWithOutTimezone(
                item.datesAndTime[0].date
              ).formattedDate,
              sessionTime: formatDateTimeWithOutTimezone(
                item.datesAndTime[0].date
              ).formattedTime,
              project: item.project,
              package: item.packageRef,
            };
          });

          setSessionsData(sessions);
        }
      };

      makeAsync();
    }
  }, [isSpecialist]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    if (
      notesBlockRef.current &&
      notesBlockRef.current.scrollTop + notesBlockRef.current.clientHeight >=
        notesBlockRef.current.scrollHeight - 10 &&
      !noteLoading
    ) {
      setNoteLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (notesBlockRef.current) {
      notesBlockRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (notesBlockRef.current) {
        notesBlockRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userData && userData._id) {
        const response = await NotesAPI.getUserNotes(
          token,
          userData._id,
          10,
          page
        );

        if (response.status) {
          setNotesData((prevNotes) => {
            if (!prevNotes) return response.notes;
            const uniqueNotes = response.notes.filter((newNote) => {
              return !prevNotes.some(
                (prevNote) => prevNote._id === newNote._id
              );
            });

            return [...prevNotes, ...uniqueNotes];
          });
          setNoteLoading(false);
        }
      }
    };

    makeAsync();
  }, [page]);
  const createNote = async () => {
    if (token && userData && userData._id) {
      const payload = {
        userId: userData._id,
        newNote: {
          title: newNote.title,
          description: newNote.noteText,
          date: new Date(),
        },
      };
      const response = await NotesAPI.createNote(token, payload);

      if (!response.status && response.message) {
        Notify(response.message);
      }
      if (response.status) {
        /*  setUpdateNoteData(updateNoteData + 1); */
        setNotesData(notesData && [...notesData, response.note]);
        setNewNoteModal(false);
        setNewNote({
          title: "",
          date: formattedDate,
          noteText: "",
        });
      }
    }
  };

  const sortNotes = () => {
    if (!notesData || !notesData.length) return;
    const sortedNotes = notesData.sort(
      (a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf()
    );
    return sortedNotes;
  };

  if (isLoader.sessionCardLoader) {
    return (
      <div className={s.mainLoaderContainer}>
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    );
  }

  return (
    <>
      <div className={s.container} data-testid="initial-page">
        <div
          className={`${s.contentBlock} ${s.contentBlock1}`}
          ref={firstBlockRef}
        >
          <div className={s.insideContentBlock1}>
            <div
            /*  style={{
                height:
                  firstBlockRef && firstBlockRef.current
                    ? firstBlockRef.current.offsetHeight / 2
                    : "auto",
              }} */
            >
              <MuiCalendar
                setSelectedDate={setSelectedDate}
                isSelectedMode
                selectedDate={selectedDate}
                dateSlots={daySlots}
              />
            </div>
            <div
              className={s.sessionListBlock}
              /*     style={{
                height:
                  firstBlockRef && firstBlockRef.current
                    ? firstBlockRef.current.offsetHeight / 2
                    : "auto",
              }} */
            >
              {sessionsForSelectDate && sessionsForSelectDate.length ? (
                sessionsForSelectDate.map((item, index) => (
                  <NextSessionCard
                    key={index}
                    userId={item.userId}
                    bookedSessionId={item.bookedSessionId}
                    bookingId={item.bookingId}
                    date={item.sessionDate}
                    time={item.sessionTime}
                    sessionTheme={strings.sessionPlannedTo}
                    duration={item.duration}
                    setBookSessionsData={setBookSessionsData}
                    bookSessionsData={bookSessionsData}
                    setSessionsData={setSessionsData}
                    isBooking={false}
                    isMySessionRequest={item.isMySession}
                    project={item.project}
                    sessionPackage={item.package}
                    specialistId={item.specialistId}
                    clientData={item.clientData}
                    specialistData={item.specialistData}
                  />
                ))
              ) : (
                <div className={s.emptyBlock}>
                  <img
                    src={EmptySessionsIcon}
                    alt=""
                    className={s.emptyContentIcon}
                  />
                  <h2 className={s.noDataTitle}>{strings.noSessions}</h2>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={`${s.contentBlock} ${s.contentBlock2}`}>
          <div className={s.headerBlock}>
            <span>
              {activeMiddleContenTab === "newsfeed"
                ? strings.newsfeedTitle
                : strings.treeQuizMenuTitle}
            </span>
            {!isSpecialist && (
              <div className={s.headerChevronBlock}>
                <ChevronUp
                  className={`${s.chevronLeft} ${
                    activeMiddleContenTab === "quize" ? s.notActiveChevron : ""
                  }`}
                  onClick={() => setActiveMiddleContenTab("quize")}
                />
                <ChevronUp
                  className={`${s.chevronRight} ${
                    activeMiddleContenTab === "newsfeed"
                      ? s.notActiveChevron
                      : ""
                  }`}
                  onClick={() => setActiveMiddleContenTab("newsfeed")}
                />
              </div>
            )}
            {/*    {userData && userData.roles?.includes("SPECIALIST") && (
              <span onClick={() => setNewPostModalVisible(true)}>+</span>
            )} */}
          </div>
          <div className={s.insideNewsfeedContentBlock}>
            <div
              ref={notesBlockRef}
              className={s.notesListContainer}
              style={{
                maxHeight:
                  firstBlockRef && firstBlockRef.current
                    ? firstBlockRef.current.offsetHeight - 80
                    : "auto",
              }}
            >
              {activeMiddleContenTab === "newsfeed" ? (
                <Newsfeed
                  newPostModalVisible={newPostModalVisible}
                  setNewPostModalVisible={setNewPostModalVisible}
                />
              ) : (
                <TreeQuiz
                  isInitialPageContainer
                  containerHeight={
                    firstBlockRef && firstBlockRef.current
                      ? firstBlockRef.current.offsetHeight - 80 + "px"
                      : "auto"
                  }
                />
              )}
            </div>
          </div>
        </div>

        {isLoader.sessionCardLoader ? (
          <div className={`${s.contentBlock} ${s.contentBlock3}`}>
            <div className={s.headerBlock}>
              <span>{strings.sessionReques}</span>
            </div>
            <div className={s.insideContentBlock}>
              <div
                className={s.sessionsList}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ThemeProvider theme={theme}>
                  <CircularProgress size={100} color="primary" />
                </ThemeProvider>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`${s.contentBlock} ${s.contentBlock3} ${s.columnContent}`}
            style={{ backgroundColor: "transparent" }}
          >
            <div
              className={s.smallResponseContentBlock}
              style={{
                maxHeight: isOpenCards.notes ? "49%" : "calc(100% - 60px)",
                minHeight: "49%",
              }}
            >
              <div className={s.headerBlock}>
                <span>{strings.sessionReques}</span>
              </div>
              <div className={s.insideContentBlock}>
                {bookSessionsData &&
                bookSessionsData.length &&
                userData &&
                filterRecentSessions(
                  bookSessionsData,
                  userData.timezone
                ).filter((session) => !session.isAccepted).length
                  ? filterRecentSessions(bookSessionsData, userData.timezone)
                      .filter((session) => !session.isAccepted)
                      .map((item, index) => {
                        return (
                          <NextSessionCard
                            key={index}
                            userId={item.userId}
                            bookedSessionId={item.bookedSessionId}
                            bookingId={item.bookingId}
                            date={item.sessionDate}
                            time={item.sessionTime}
                            sessionTheme={strings.sessionPlannedTo}
                            duration={item.duration}
                            setBookSessionsData={setBookSessionsData}
                            bookSessionsData={bookSessionsData}
                            setSessionsData={setSessionsData}
                            isBooking={true}
                            creationDate={item.creationDate}
                            isMySessionRequest={item.isMySession}
                            project={item.project}
                            clientData={item.clientData}
                            specialistData={item.specialistData}
                            subject={item.subject}
                            tags={item.tags}
                          />
                        );
                      })
                  : null}
                {packagesBookingData && packagesBookingData.length
                  ? packagesBookingData.map((item, index) => (
                      <PackageBookingCard
                        key={index}
                        packageBookingData={item}
                        setPackageBookingData={setPackagesBookingData}
                        setSessionsData={setSessionsData}
                      />
                    ))
                  : null}
                {(!packagesBookingData || !packagesBookingData.length) &&
                  (!bookSessionsData ||
                    !bookSessionsData.filter((session) => !session.isAccepted)
                      .length) && (
                    <div className={s.emptyBlock}>
                      <EmptyBookingsIcon />
                      <h2 className={s.noDataTitle}>{strings.noRequest}</h2>
                    </div>
                  )}
              </div>
            </div>
            <div className={s.smallContentBlock}>
              <div className={s.headerBlock}>
                <span>{strings.notesMenuTitle}</span>
                <div className={s.cardActionBlock}>
                  <ChevronUp
                    className={isOpenCards.notes ? s.chevronDown : s.chevronUp}
                    onClick={() =>
                      setIsOpenCards((prev) => ({
                        ...prev,
                        notes: !prev.notes,
                      }))
                    }
                  />
                  <span onClick={() => setNewNoteModal(true)}>
                    <PlusIcon className={s.plusIcon} />
                  </span>
                </div>
              </div>
              <AnimateHeight height={isOpenCards.notes ? "auto" : 0}>
                <div
                  className={s.insideContentBlock}
                  style={{
                    height: firstBlockRef.current
                      ? firstBlockRef.current.offsetHeight / 2 - 60
                      : "100%",
                  }}
                >
                  <div
                    ref={notesBlockRef}
                    className={s.notesListContainer}
                    /*  style={{
                    maxHeight:
                      firstBlockRef && firstBlockRef.current
                        ? firstBlockRef.current.offsetHeight - 80
                        : "auto",
                  }} */
                  >
                    {notesData && notesData.length ? (
                      sortNotes()!.map((item, index) => (
                        <div className={s.noteCardBlock} key={index}>
                          <NoteCard
                            noteId={item._id}
                            date={item.date.toString()}
                            title={item.title}
                            setNotesData={setNotesData}
                            setSelectNote={setSelectNote}
                            noteData={item}
                            selectNote={selectNote}
                            isDashboardCard={true}
                          />
                        </div>
                      ))
                    ) : (
                      <h2 className={s.noDataTitle}>{strings.noNotes}</h2>
                    )}
                  </div>
                  {noteLoading && <div></div>}
                </div>
              </AnimateHeight>
            </div>
          </div>
        )}
      </div>
      <ModalWindow
        isOpen={newNoteModal}
        setIsOpen={setNewNoteModal}
        width="fit-content"
        position={window.innerWidth > 768 ? "right" : "bottom"}
        padding="0"
        showCloseBtn={false}
      >
        <div className={s.newNoteBlock}>
          <div className={s.newNoteHeader}>
            <span>{strings.newNoteBtn}</span>
            <CloseIcon
              className={s.closeIcon}
              onClick={() => setNewNoteModal(false)}
            />
          </div>
          {/* <span className={s.newNoteTitle}>{strings.newNoteHeadTitle}</span> */}
          <div className={s.newNoteInputBlock}>
            <span>{strings.newNoteTitleLable}</span>
            <Input
              inputValue={newNote.title}
              onChangeInput={(value: any) =>
                setNewNote((prev) => ({ ...prev, title: value }))
              }
              isVisible
              required
              placeholder={strings.myNotePlaceHolder}
            />
          </div>
          <div className={s.newNoteInputBlock}>
            <span>{strings.newNoteDescriptionLable}</span>
            <textarea
              name=""
              id=""
              placeholder={strings.myDescPlaceholder}
              cols={30}
              rows={10}
              value={newNote.noteText}
              className={s.textAreaNewNote}
              onChange={(e) =>
                setNewNote((prev) => ({
                  ...prev,
                  noteText: e.target.value,
                }))
              }
            ></textarea>
          </div>
          <div className={s.createNoteBlock}>
            <StandartButton
              action={createNote}
              buttonTitle={strings.newNoteCreateBtn}
              width="200px"
              fontSize="20px"
              disabled={
                newNote.noteText.replace(/\s+/g, "") === "" ||
                newNote.title.replace(/\s+/g, "") === ""
              }
              tooltipTitle={
                newNote.noteText.replace(/\s+/g, "") === "" ||
                newNote.title.replace(/\s+/g, "") === ""
                  ? strings.newNoteHeadTitle
                  : ""
              }
            />
          </div>
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={commentModalIsOpen}
        setIsOpen={setCommentModalIsOpen}
        width="fit-content"
      >
        <CommentComponent
          sessionId={
            location.state && location.state.sessionId
              ? location.state.sessionId
              : null
          }
          closeModal={() => setCommentModalIsOpen(false)}
        />
      </ModalWindow>
    </>
  );
};

export default Initial;
