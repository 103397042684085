import { Dispatch, SetStateAction, useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useNavigate } from "react-router-dom";
import Peer from "simple-peer";
import SessionsApi from "../../../api/sessionApi";
import ChevronUpIcon from "../../../assets/HomePage/chevron-up.svg";
import { ReactComponent as Handset } from "../../../assets/VideoSession/handset.svg";
import { ReactComponent as MicroSlashIcon } from "../../../assets/VideoSession/microphone-slash.svg";
import { ReactComponent as MicroIcon } from "../../../assets/VideoSession/microphone.svg";
import { ReactComponent as TriangelIcon } from "../../../assets/VideoSession/triangle.svg";
import { ReactComponent as CameraSlashIcon } from "../../../assets/VideoSession/video-camera-slash.svg";
import { ReactComponent as CameraIcon } from "../../../assets/VideoSession/video-camera.svg";
import { ReactComponent as Volume } from "../../../assets/VideoSession/volume.svg";
import { DevicesList } from "../SessionVideoChat";
import s from "./BottomMenu.module.css";

type TypeBottomMenuProps = {
  myStream: MediaStream | undefined;
  peers: { id: string; peer: Peer.Instance }[];
  inputAudioDevices: DevicesList[] | null;
  setInputAudioDevices: Dispatch<SetStateAction<DevicesList[] | null>>;
  outputAudioDevices: DevicesList[] | null;
  setOutputAudioDevices: Dispatch<SetStateAction<DevicesList[] | null>>;
  inputVideoDevices: DevicesList[] | null;
  setInputVideoDevices: Dispatch<SetStateAction<DevicesList[] | null>>;
  setMyStream: any;
  sessionId: string;
  camera: boolean;
  setCamera: Dispatch<SetStateAction<boolean>>;
};

const BottomMenu = ({
  myStream,
  peers,
  inputAudioDevices,
  setInputAudioDevices,
  outputAudioDevices,
  setOutputAudioDevices,
  inputVideoDevices,
  setInputVideoDevices,
  setMyStream,
  sessionId,
  camera,
  setCamera,
}: TypeBottomMenuProps) => {
  const navigation = useNavigate();
  const token = localStorage.getItem("token");
  const [microphone, setMicrophone] = useState(false);

  const [devicesChooseVisible, setDevicesChooseVisible] = useState({
    camera: false,
    audio: false,
  });
  const [devicesHeight, setDevicesHeight] = useState({
    video: false,
    inputAudio: false,
    outputAudio: false,
  });

  const handleMicrophoneClick = () => {
    const videDeviceId =
      inputVideoDevices &&
      inputVideoDevices.find((device) => device.isSelect === true)?.deviseId;
    const audioDeviceId =
      inputAudioDevices &&
      inputAudioDevices.find((device) => device.isSelect === true)?.deviseId;
    navigator.mediaDevices
      .getUserMedia({
        video: { deviceId: videDeviceId || "" },
        audio: { deviceId: audioDeviceId || "" },
      })
      .then((stream) => {
        if (!myStream) return;
        const prevVideoTrack = myStream.getVideoTracks()[0];
        stream.getTracks().forEach((track) => {
          track.enabled = prevVideoTrack.enabled;
        });
        stream.getAudioTracks().forEach((track) => {
          track.enabled = !microphone;
        });

        setMicrophone((prev) => !prev);
        setMyStream(stream);
      });
  };

  useEffect(() => {
    const updateDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();

        const audioInputDevices = devices.filter(
          (device) => device.kind === "audioinput"
        );
        const audioOutputDevices = devices.filter(
          (device) => device.kind === "audiooutput"
        );
        const videoInputDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );

        setInputAudioDevices(
          audioInputDevices.map((item) => ({
            title: item.label,
            deviseId: item.deviceId,
            isSelect: item.deviceId === "default" ? true : false,
          }))
        );
        setOutputAudioDevices(
          audioOutputDevices.map((item) => ({
            title: item.label,
            deviseId: item.deviceId,
            isSelect: item.deviceId === "default" ? true : false,
          }))
        );
        setInputVideoDevices(
          videoInputDevices.map((item, index) => ({
            title: item.label,
            deviseId: item.deviceId,
            isSelect: index === 0 ? true : false,
          }))
        );
        if (audioInputDevices.length === 0) {
          setMicrophone(false);
        }

        if (videoInputDevices.length === 0) {
          setCamera(false);
        }
      } catch (error) {
        console.error("Error enumerating devices:", error);
      }
    };

    updateDevices();
  }, []);

  const handleCameraClick = () => {
    const videDeviceId =
      inputVideoDevices &&
      inputVideoDevices.find((device) => device.isSelect === true)?.deviseId;
    navigator.mediaDevices
      .getUserMedia({
        video: { deviceId: videDeviceId || "" },
        audio: true,
      })
      .then((stream) => {
        if (!myStream) return;
        const prevAudioTrack = myStream.getAudioTracks()[0];
        stream.getTracks().forEach((track) => {
          track.enabled = prevAudioTrack.enabled;
        });
        stream.getVideoTracks().forEach((track) => {
          track.enabled = !camera;
        });
        if (
          peers &&
          peers.some(
            (peerObj: { id: string; peer: Peer.Instance }) =>
              !peerObj ||
              !peerObj.peer ||
              peerObj.peer.destroyed || //@ts-expect-error
              !peerObj.peer.channelName
          )
        )
          return;
        setCamera((prev) => !prev);
        setMyStream(stream);
      });
  };

  const sessionDisconnect = async () => {
    if (token) {
      const commentLeaveResponse = await SessionsApi.checkIfCanLeaveComment(
        token,
        sessionId
      );

      if (!commentLeaveResponse.status) {
        navigation("/home");
      } else {
        navigation("/home", {
          state: {
            sessionId: sessionId,
            ratingOpen: true,
          },
        });
      }
    }
  };

  const menuElements = [
    {
      icon: microphone ? MicroIcon : MicroSlashIcon,
      action: () => handleMicrophoneClick(),
      className: microphone ? s.iconBg : s.disableIconBg,
      categoryTitle: "audio",
    },
    {
      icon: camera ? CameraIcon : CameraSlashIcon,
      action: () => handleCameraClick(),
      className: camera ? s.iconBg : s.disableIconBg,
      categoryTitle: "camera",
    },
    {
      icon: Handset,
      action: () => sessionDisconnect(),
      className: s.handsetBg,
      categoryTitle: "disconnect",
    },
  ];
  const handleChooseDeviceVisible = (title: string) => {
    setDevicesChooseVisible(
      title === "camera"
        ? { camera: !devicesChooseVisible.camera, audio: false }
        : title === "audio"
        ? { camera: false, audio: !devicesChooseVisible.audio }
        : { camera: false, audio: false }
    );
  };
  const handleDeviceClick = (index: number, devicesArr: DevicesList[]) => {
    const updatedDevices = devicesArr.map((device, i) => ({
      ...device,
      isSelect: i === index,
    }));
    return updatedDevices;
  };

  return (
    <div className={s.container}>
      <div className={s.chooseDeviceBlock}>
        {(devicesChooseVisible.audio || devicesChooseVisible.camera) &&
          (devicesChooseVisible.camera ? (
            <div className={s.chooseCameraBlock}>
              <AnimateHeight
                height={!devicesHeight.video ? 0 : "auto"}
                className={s.cameraListBlock}
              >
                {inputVideoDevices?.map((item, index) => (
                  <div
                    onClick={() => {
                      const updateSelectDevice = handleDeviceClick(
                        index,
                        inputVideoDevices
                      );
                      setInputVideoDevices(
                        updateSelectDevice ? updateSelectDevice : null
                      );
                    }}
                    style={{
                      marginBottom:
                        index === inputVideoDevices.length - 1 ? "10px" : 0,
                    }}
                    key={index}
                    className={
                      item.isSelect ? s.selectedDeviceTitle : s.divecTitle
                    }
                  >
                    {item.title}
                  </div>
                ))}
              </AnimateHeight>

              <div
                className={
                  devicesHeight.video
                    ? s.chooseCameraLableOpen
                    : s.chooseCameraLable
                }
                onClick={() =>
                  setDevicesHeight({
                    video: !devicesHeight.video,
                    inputAudio: false,
                    outputAudio: false,
                  })
                }
              >
                <CameraIcon className={s.cameraIcon} />
                <span>
                  {inputVideoDevices &&
                    inputVideoDevices.find((device) => device.isSelect === true)
                      ?.title}
                </span>
                <TriangelIcon className={s.triangelIcon} />
              </div>
            </div>
          ) : (
            <div className={s.chooseAudioBlock}>
              <div className={s.chooseMicro}>
                <AnimateHeight
                  height={!devicesHeight.inputAudio ? 0 : "auto"}
                  className={s.cameraListBlock}
                >
                  {inputAudioDevices?.map((item, index) => (
                    <div
                      onClick={() => {
                        const updateSelectDevice = handleDeviceClick(
                          index,
                          inputAudioDevices
                        );
                        setInputAudioDevices(
                          updateSelectDevice ? updateSelectDevice : null
                        );
                      }}
                      style={{
                        marginBottom:
                          index === inputAudioDevices.length - 1 ? "10px" : 0,
                      }}
                      key={index}
                      className={
                        item.isSelect ? s.selectedDeviceTitle : s.divecTitle
                      }
                    >
                      {item.title}
                    </div>
                  ))}
                </AnimateHeight>
                <div
                  className={
                    devicesHeight.inputAudio
                      ? s.chooseCameraLableOpen
                      : s.chooseCameraLable
                  }
                  onClick={() =>
                    setDevicesHeight({
                      video: false,
                      inputAudio: !devicesHeight.inputAudio,
                      outputAudio: false,
                    })
                  }
                >
                  <MicroIcon className={s.microIcon} />

                  <span>
                    {inputAudioDevices &&
                      inputAudioDevices.find(
                        (device) => device.isSelect === true
                      )?.title}
                  </span>
                  <TriangelIcon className={s.triangelIcon} />
                </div>
              </div>
              <div className={s.chooseVolume}>
                <AnimateHeight
                  height={!devicesHeight.outputAudio ? 0 : "auto"}
                  className={s.cameraListBlock}
                >
                  {outputAudioDevices?.map((item, index) => (
                    <div
                      onClick={() => {
                        const updateSelectDevice = handleDeviceClick(
                          index,
                          outputAudioDevices
                        );
                        setOutputAudioDevices(
                          updateSelectDevice ? updateSelectDevice : null
                        );
                      }}
                      style={{
                        marginBottom:
                          index === outputAudioDevices.length - 1 ? "10px" : 0,
                      }}
                      key={index}
                      className={
                        item.isSelect ? s.selectedDeviceTitle : s.divecTitle
                      }
                    >
                      {item.title}
                    </div>
                  ))}
                </AnimateHeight>
                <div
                  className={
                    devicesHeight.outputAudio
                      ? s.chooseCameraLableOpen
                      : s.chooseCameraLable
                  }
                  onClick={() =>
                    setDevicesHeight({
                      video: false,
                      inputAudio: false,
                      outputAudio: !devicesHeight.outputAudio,
                    })
                  }
                >
                  <Volume className={s.volumeIcon} />
                  <span>
                    {outputAudioDevices &&
                      outputAudioDevices.find(
                        (device) => device.isSelect === true
                      )?.title}
                  </span>
                  <TriangelIcon className={s.triangelIcon} />
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className={s.mainContainer}>
        {menuElements.map((item, index) => (
          <div
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              handleChooseDeviceVisible(item.categoryTitle);
            }}
            className={
              index === menuElements.length - 1 ? "" : s.bottomMenuButtonsBlock
            }
          >
            {index !== menuElements.length - 1 && (
              <img src={ChevronUpIcon} alt="" className={s.chevronIcon} />
            )}
            <div
              className={item.className}
              onClick={(e) => {
                e.stopPropagation();
                item.action();
              }}
              key={index}
              role="button"
            >
              <item.icon className={s.icon} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BottomMenu;
