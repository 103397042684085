import { useEffect, useRef, useState } from "react";

interface UseInfiniteScrollParams {
  fetchData: (page: number) => void;
  hasMore: boolean;
  isLoading: boolean;
  threshold?: number;
  debounceTime?: number;
  initialPage?: number;
}

interface UseInfiniteScrollResult {
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  page: number;
}

const useInfiniteScroll = ({
  fetchData,
  hasMore,
  isLoading,
  threshold = 100,
  debounceTime = 200,
  initialPage = 1,
}: UseInfiniteScrollParams): UseInfiniteScrollResult => {
  const [page, setPage] = useState(initialPage);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const loadMore = () => {
    if (!isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  // Дебаунс функция для оптимизации частых вызовов
  const debounce = (func: (...args: any[]) => void, wait: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  // Обработчик скролла с проверкой на порог и загрузку новых данных
  const handleScroll = debounce(() => {
    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - threshold) {
        loadMore();
      }
    }
  }, debounceTime);

  // Подписка на событие прокрутки
  useEffect(() => {
    const currentRef = scrollContainerRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  // Вызываем функцию загрузки данных при изменении страницы
  useEffect(() => {
    fetchData(page);
  }, [page, fetchData]);

  return { scrollContainerRef, page };
};

export default useInfiniteScroll;
