import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Dispatch, SetStateAction, useContext } from "react";
import { ReactComponent as CheckICon } from "../../../assets/TreeQuiz/check.svg";
import { ReactComponent as QuestionIcon } from "../../../assets/TreeQuiz/question.svg";
import { ReactComponent as RectangleCheckICon } from "../../../assets/TreeQuiz/rectangle-check.svg";
import { ContextProvider } from "../../../contextProvider";
import { TreeQuizAnswer } from "../../../types/TreeQuiz";
import s from "./AnswerElement.module.css";
type AnswerElementProps = {
  answer: TreeQuizAnswer;
  isSelectAnswer: boolean;
  setAnswersResult: Dispatch<SetStateAction<string[]>>;
  isMultiSelect: boolean;
};

const AnswerElement = ({
  answer,
  isSelectAnswer,
  setAnswersResult,
  isMultiSelect,
}: AnswerElementProps) => {
  const { userData } = useContext(ContextProvider);
  const selectMultiAnswerHandler = () => {
    setAnswersResult((prev) =>
      isSelectAnswer
        ? prev.filter((el) => el !== answer._id)
        : [...prev, answer._id]
    );
  };
  const selectSingleAnswerHandler = () => {
    setAnswersResult([answer._id]);
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(14),
      borderRadius: "10px",
      filter: "drop-shadow(0 0 5px rgba(0, 0, 0, 0.5))",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "white",
    },
  }));

  return (
    <div
      className={isSelectAnswer ? s.selectContainer : s.container}
      style={{ opacity: answer.possibleAmountOfSpecialists === 0 ? 0.5 : 1 }}
      onClick={
        answer.possibleAmountOfSpecialists === 0
          ? undefined
          : isMultiSelect
          ? selectMultiAnswerHandler
          : selectSingleAnswerHandler
      }
    >
      <div className={s.leftBlock}>
        {isMultiSelect &&
          (isSelectAnswer ? (
            <RectangleCheckICon />
          ) : (
            <span className={s.notCheck}></span>
          ))}
        <span className={s.answerTitle}>
          {answer.labels.find(
            (el) => el.language === userData?.selectedLanguage
          )?.text ?? answer.answer}
        </span>
        {answer.descriptionLabels && answer.descriptionLabels.length ? (
          <HtmlTooltip
            title={
              <div className={s.tooltipBlock}>
                {
                  answer.descriptionLabels.find(
                    (el) => userData?.selectedLanguage === el.language
                  )?.text
                }
              </div>
            }
          >
            <QuestionIcon width={20} height={20} />
          </HtmlTooltip>
        ) : null}
      </div>
      <div className={s.rightBlock}>
        <span
          className={
            isSelectAnswer ? s.selectAmountSpecTitle : s.amountSpecTitle
          }
        >
          {answer.possibleAmountOfSpecialists}
        </span>
        {!isMultiSelect && isSelectAnswer ? <CheckICon /> : null}
      </div>
    </div>
  );
};

export default AnswerElement;
