import { Checkbox } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import EyeSlashIcon from "../../../assets/Auth/eye-slash.svg";
import EyeIcon from "../../../assets/Auth/eye.svg";
import FacebookIcon from "../../../assets/Auth/facebook.svg";
import GoogleIcon from "../../../assets/Auth/google.svg";
import IcloudIcon from "../../../assets/Auth/icloud.svg";
import TwitterIcon from "../../../assets/Auth/twitter.svg";
import Input from "../../../components/Input/Input";
import PhoneNumberInput from "../../../components/PhoneNumberInput/PhoneNumberInput";
import StandartButton from "../../../components/StandartButton/StandartButton";
import strings from "../../../localization";
import s from "./MobileAuthorization.module.css";

type MobileAuthorizationProps = {
  authValues: {
    phoneNumberOrEmail: string;
    password: string;
    rememberMe: boolean;
  };
  setAuthValues: Dispatch<
    SetStateAction<{
      phoneNumberOrEmail: string;
      password: string;
      rememberMe: boolean;
    }>
  >;
  registarValues: { phoneNumber: string; email: string; password: string };
  setRegisterValues: Dispatch<
    SetStateAction<{ phoneNumber: string; email: string; password: string }>
  >;
  authHandler: () => void;
  passwordVisible: { auth: boolean; register: boolean };
  setPasswordVisible: Dispatch<
    SetStateAction<{ auth: boolean; register: boolean }>
  >;
  handleRegister: () => void;
  regError: string;
  authError: string;
};

const MobileAuthorization = ({
  authValues,
  setAuthValues,
  registarValues,
  setRegisterValues,
  authHandler,
  passwordVisible,
  setPasswordVisible,
  handleRegister,
  regError,
  authError,
}: MobileAuthorizationProps) => {
  const [isSignUp, setIsSignUp] = useState(false);

  const onChangeAuth = (
    value: string,
    objKey: keyof { phoneNumberOrEmail: string; password: string }
  ) => {
    setAuthValues((prev) => ({ ...prev, [objKey]: value }));
  };
  const onChangeRegister = (
    value: string,
    objKey: keyof { name: string; email: string; password: string }
  ) => {
    setRegisterValues((prev) => ({ ...prev, [objKey]: value }));
  };
  return (
    <div className={s.conatiner}>
      {!isSignUp ? (
        <div className={s.signInBlock}>
          <h1>{strings.signInTitle}</h1>
          <div className={s.changeSignBlock}>
            <span>{strings.newUserMob}</span>
            <button className={s.changeSignButton}>
              <span
                className={s.changeSignTitle}
                onClick={() => setIsSignUp(true)}
              >
                {strings.createAccountMob}
              </span>
              <span className={s.underLine}></span>
            </button>
          </div>
          <div className={s.inputsBlock}>
            <Input
              lable={strings.phoneOrEmailLable}
              inputValue={authValues.phoneNumberOrEmail}
              onChangeInput={(value) =>
                onChangeAuth(value, "phoneNumberOrEmail")
              }
              isVisible={true}
              placeholder={"+380 11 111 1111"}
              required
            />

            <div className={s.passwordInputContainer}>
              <Input
                lable={strings.passwordAuthLable}
                inputValue={authValues.password}
                onChangeInput={(value) => onChangeAuth(value, "password")}
                isVisible={passwordVisible.auth}
                placeholder="********"
                required
              />
              {authError !== "" && (
                <p className={s.errorMessege}>{authError}</p>
              )}
              <img
                data-testid="register-visible-switcher"
                src={passwordVisible.auth ? EyeSlashIcon : EyeIcon}
                alt=""
                className={s.eyeIcon}
                onClick={() =>
                  setPasswordVisible((prev) => ({
                    ...prev,
                    auth: !prev.auth,
                  }))
                }
              />
            </div>
            <div className={s.rememberBlock}>
              <Checkbox
                color="success"
                checked={authValues.rememberMe}
                onClick={() =>
                  setAuthValues((prev) => ({
                    ...prev,
                    rememberMe: !prev.rememberMe,
                  }))
                }
                inputProps={{ "aria-label": "controlled" }}
              />
              <span>{strings.rememberMe}</span>
            </div>
          </div>
          <div className={s.forgotPasswordBlock}>
            <button className={s.changeSignButton}>
              <span
                className={s.changeSignTitle}
                onClick={() => setIsSignUp(true)}
              >
                {strings.forgotPassword}
              </span>
              <span className={s.underLine}></span>
            </button>
          </div>
          <div className={s.buttonBlock}>
            <StandartButton
              buttonTitle="Continue"
              action={authHandler}
              width="200px"
            />
          </div>
          <div className={s.altSign}>
            <span className={s.altLine}></span>
            <span className={s.altTitle}>{strings.orSignWithMob}</span>
            <span className={s.altLine}></span>
          </div>
          <div className={s.socialContainer}>
            <a href="#" className={s.social}>
              <img src={FacebookIcon} alt="" />
            </a>
            <a href="#" className={s.social}>
              <img src={GoogleIcon} alt="" />
            </a>
            <a href="#" className={s.social}>
              <img src={TwitterIcon} alt="" />
            </a>
            <a href="#" className={s.social}>
              <img src={IcloudIcon} alt="" />
            </a>
          </div>
        </div>
      ) : (
        <div className={s.signUpBlock}>
          <h1>{strings.signUpBtn}</h1>
          <div className={s.changeSignBlock}>
            <span>{strings.alreadyHaveAccMob}</span>
            <button className={s.changeSignButton}>
              <span
                className={s.changeSignTitle}
                onClick={() => setIsSignUp(false)}
              >
                {strings.signInBtn}
              </span>
              <span className={s.underLine}></span>
            </button>
          </div>
          <div className={s.inputsBlock}>
            <div>
              <span className={s.inputLable}>{strings.phoneNumberLable}</span>
              <PhoneNumberInput
                value={registarValues.phoneNumber}
                onChangeFunction={(value) =>
                  setRegisterValues((prev) => ({ ...prev, phoneNumber: value }))
                }
              />
            </div>
            <Input
              lable={strings.emailPlaceHolder}
              inputValue={registarValues.email}
              onChangeInput={(value) => onChangeRegister(value, "email")}
              isVisible={true}
              placeholder="name@example.com"
              required
            />
            <div className={s.passwordInputContainer}>
              <Input
                lable={strings.passwordPlaceHolder}
                inputValue={registarValues.password}
                onChangeInput={(value) => onChangeRegister(value, "password")}
                isVisible={passwordVisible.register}
                required
              />
              {regError !== "" && <p className={s.errorMessege}>{regError}</p>}
              <img
                data-testid="register-visible-switcher"
                src={passwordVisible.register ? EyeSlashIcon : EyeIcon}
                alt=""
                className={s.eyeIcon}
                onClick={() =>
                  setPasswordVisible((prev) => ({
                    ...prev,
                    register: !prev.register,
                  }))
                }
              />
            </div>
          </div>
          <div className={s.buttonBlock}>
            <StandartButton
              buttonTitle="Continue"
              action={handleRegister}
              width="200px"
            />
          </div>
          <div className={s.altSign}>
            <span className={s.altLine}></span>
            <span className={s.altTitle}>{strings.orSignWithMob}</span>
            <span className={s.altLine}></span>
          </div>
          <div className={s.socialContainer}>
            <a href="#" className={s.social}>
              <img src={FacebookIcon} alt="" />
            </a>
            <a href="#" className={s.social}>
              <img src={GoogleIcon} alt="" />
            </a>
            <a href="#" className={s.social}>
              <img src={TwitterIcon} alt="" />
            </a>
            <a href="#" className={s.social}>
              <img src={IcloudIcon} alt="" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileAuthorization;
