import { CircularProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as LogOutIcon } from "../../assets/SideBar/logout.svg";
import ArrowRightIcon from "../../assets/SpecialistSetupInfo/arrow-right.svg";
import HeaderChangeLangs from "../../components/HeaderChangeLangs/HeaderChangeLangs";
import { ContextProvider } from "../../contextProvider";
import localizationJson from "../../localization.json";
import { SpecialistData } from "../../types/TypeSpecialist";
import { logout } from "../../utils/general";
import { theme } from "../../utils/theme";
import AboutYourself from "./AboutYourself/AboutYourself";
import CreatingProfile from "./CreatingProfile/CreatingProfile";
import Price from "./Price/Price";
import SetupInfoSidebar from "./SetupInfoSidebar/SetupInfoSidebar";
import s from "./SpecialistSetupInfo.module.css";
import SpecializationStep from "./SpecializationStep/SpecializationStep";

type TypespecializationList = {
  _id: string;
  title: string;
  checked: boolean;
};

const SpecialistSetupInfo = () => {
  const { userData } = useContext(ContextProvider);
  const { setSelectLang } = useContext(ContextProvider);
  const [isLoading, setIsLoading] = useState(false);
  const [specializationSubStep, setSpecializationSubStep] = useState({
    current: 1,
    total: 3,
  });
  const [profileSubStep, setProfileSubStep] = useState({
    current: 1,
    total: 3,
  });
  const [aboutSubStep, setAboutSubStep] = useState({ current: 1, total: 3 });
  const [priceSubStep, setPriceSubStep] = useState({ current: 1, total: 1 });
  const [activeStep, setActiveStep] = useState(1);
  const [specialistInfo, setSpecialistInfo] = useState<SpecialistData | null>(
    null
  );
  const [checkedSpecializations, setCheckedSpecializations] = useState<
    TypespecializationList[]
  >([]);

  useEffect(() => {
    const browserLanguage = navigator.language.split("-")[0];
    const languageArray = Object.keys(localizationJson);

    if (languageArray.includes(browserLanguage)) {
      setSelectLang(browserLanguage);
    }

    setSelectLang((prev) => (prev ? prev : "en"));
  }, []);

  const handlerPrevStep = () => {
    if (
      (profileSubStep.current === 1 && activeStep === 2) ||
      (aboutSubStep.current === 1 && activeStep === 3) ||
      (priceSubStep.current === 1 && activeStep === 4)
    ) {
      setActiveStep(activeStep - 1);
    } else if (activeStep === 1 && specializationSubStep.current > 1) {
      setSpecializationSubStep((prev) => ({
        ...prev,
        current: prev.current - 1,
      }));
      /*    setCheckedSpecializations([]); */
    } else if (activeStep === 2 && profileSubStep.current > 1) {
      setProfileSubStep((prev) => ({ ...prev, current: prev.current - 1 }));
    } else if (activeStep === 3 && aboutSubStep.current > 1) {
      setAboutSubStep((prev) => ({ ...prev, current: prev.current - 1 }));
    } else setPriceSubStep((prev) => ({ ...prev, current: prev.current - 1 }));
  };

  return (
    <div className={s.container}>
      <div className={s.headerContainer}>
        {activeStep === 1 && specializationSubStep.current === 1 ? (
          <div></div>
        ) : (
          <div
            role="button"
            className={s.arrowImageBlock}
            onClick={handlerPrevStep}
          >
            <img src={ArrowRightIcon} alt="" className={s.arrowImage} />
          </div>
        )}

        <h3></h3>
        <div className={s.profileIconBlcok}>
          <HeaderChangeLangs />
          <LogOutIcon
            className={s.loguotIcon}
            onClick={() => logout(userData!._id)}
          />
        </div>
      </div>
      <div className={s.content}>
        <div className={s.setupSidebarBlock}>
          <SetupInfoSidebar activeStep={activeStep} />
        </div>
        <div className={s.mainContent}>
          {isLoading ? (
            <div className={s.loaderContaienr}>
              <ThemeProvider theme={theme}>
                <CircularProgress size={150} color="primary" />
              </ThemeProvider>
            </div>
          ) : (
            <div>
              {activeStep === 1 && (
                <SpecializationStep
                  checkedSpecializations={checkedSpecializations}
                  setCheckedSpecializations={setCheckedSpecializations}
                  subStep={specializationSubStep}
                  setActiveStep={setActiveStep}
                  setSubStep={setSpecializationSubStep}
                  setLoader={setIsLoading}
                  setSpecialistInfo={setSpecialistInfo}
                  specialistInfo={specialistInfo}
                />
              )}
              {activeStep === 2 && (
                <CreatingProfile
                  setActiveStep={setActiveStep}
                  setSubStep={setProfileSubStep}
                  subStep={profileSubStep}
                  setLoader={setIsLoading}
                  setSpecialistInfo={setSpecialistInfo}
                  specialistInfo={specialistInfo}
                />
              )}
              {activeStep === 3 && (
                <AboutYourself
                  setActiveStep={setActiveStep}
                  setSubStep={setAboutSubStep}
                  subStep={aboutSubStep}
                  setLoader={setIsLoading}
                />
              )}
              {activeStep === 4 && (
                <Price setSubStep={setPriceSubStep} subStep={priceSubStep} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpecialistSetupInfo;
