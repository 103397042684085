import s from "./DashboardCard.module.css";

type DashboardCardProps = {
  title: string;
  value: number | undefined;
};

const DashboardCard = ({ title, value }: DashboardCardProps) => {
  return (
    <div className={s.container}>
      <div className={s.headCardBlock}>
        <span>{title}</span>
        {/* <span>{timeRange}</span> */}
      </div>
      <div className={s.valueInfoBlock}>
        <span className={s.valueTitle}>{value}</span>
        {/* <span className={s.percentBlock}>
          <img src={ArrrowUpIcon} alt="" />
          {percent}
        </span> */}
      </div>
    </div>
  );
};

export default DashboardCard;
