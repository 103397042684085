import { CircularProgress, ThemeProvider } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import SessionsApi from "../../../api/sessionApi";
import Calendar, { TypeEventData } from "../../../components/Calendar/Calendar";
import { ContextProvider } from "../../../contextProvider";
import { getTranslation } from "../../../utils/getTranslation";
import { theme } from "../../../utils/theme";
import s from "./EventsCalendar.module.css";

const EventsCalendar = () => {
  const token = localStorage.getItem("token");
  const isSpecialistStored = localStorage.getItem("isSpecialist");
  const isSpecialist =
    isSpecialistStored !== null ? JSON.parse(isSpecialistStored) : false;
  const { userData } = useContext(ContextProvider);
  const [eventData, setEventData] = useState<TypeEventData[] | null>(null);
  const [isLoader, setIsLoader] = useState({
    calendarLoader: true,
  });

  const calcEndTimeSesiion = (date: Date, duration: number) => {
    const stringDate = new Date(
      //@ts-expect-error
      date.replace("Z", "")
    );
    const newDate = new Date(stringDate.getTime()); // Создаем копию исходной даты
    newDate.setMinutes(stringDate.getMinutes() + duration);
    return newDate;
  };

  const toLocalISOString = (date: Date): string => {
    const pad = (num: number) => (num < 10 ? "0" + num : num.toString());

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const milliseconds = date.getMilliseconds().toString().padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  };
  useEffect(() => {
    if (token && userData) {
      const makeAsync = async () => {
        const today = new Date();
        const startDate = new Date();
        startDate.setDate(today.getDate() - 30);
        const futureDate = new Date();
        futureDate.setDate(today.getDate() + 30);

        const sessionResponse = await SessionsApi.getAllUserSessions(
          userData._id,
          token,
          startDate,
          futureDate
        );

        if (sessionResponse.status && sessionResponse.sessions) {
          const eventList = sessionResponse.sessions.map((item, index) => {
            const iAmSpec =
              item.invitedUsers.find((el) => el.specialistIds)?._id ===
              userData._id;
            const startDate = new Date(item.datesAndTime[0].date);
            const endDate = calcEndTimeSesiion(
              item.datesAndTime[0].date,
              item.datesAndTime[0].duration
            );

            const specialistData = item.invitedUsers.find(
              (user) => user.specialistIds
            );
            const clientData = item.invitedUsers.find(
              (user) => !user.specialistIds
            );

            const name =
              iAmSpec && specialistData
                ? getTranslation(clientData?.name, userData.selectedLanguage)
                : !iAmSpec && clientData
                ? getTranslation(
                    specialistData?.name,
                    userData.selectedLanguage
                  )
                : "";

            const surname =
              iAmSpec && specialistData
                ? getTranslation(clientData?.surname, userData.selectedLanguage)
                : !iAmSpec && clientData
                ? getTranslation(
                    specialistData?.surname,
                    userData.selectedLanguage
                  )
                : "";
            const userId =
              iAmSpec && specialistData
                ? clientData?._id
                : !iAmSpec && clientData
                ? specialistData?._id
                : "";

            return {
              id: index,
              userId: userId,
              title: `Coaching session with ${name} ${surname}`,
              start: startDate.toISOString(),
              end: toLocalISOString(endDate),
              bookedSessionId: item._id,
              name: name,
              surname: surname,
            };
          });

          setEventData(eventList);
        }
        setIsLoader((prev) => ({ ...prev, calendarLoader: false }));
      };

      makeAsync();
    }
  }, [isSpecialist]);
  return (
    <div className={s.container}>
      <div className={s.calendar}>
        {isLoader.calendarLoader ? (
          <div className="loader-container" style={{ height: "100%" }}>
            <ThemeProvider theme={theme}>
              <CircularProgress size={100} color="primary" />
            </ThemeProvider>
          </div>
        ) : (
          <Calendar eventsData={eventData} />
        )}
      </div>
    </div>
  );
};

export default EventsCalendar;
