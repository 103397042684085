import { Radio } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SpecialistApi from "../../../api/specialistApi";
import EditIcon from "../../../assets/Profile/cloud-plus.svg";
import { ReactComponent as PlusIcon } from "../../../assets/Profile/specialist/add.svg";
import {
  documentExtensions,
  imageExtensions,
} from "../../../constans/filesExtensions";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import {
  Education,
  EducationCertificatesResponse,
  Federation,
  FederationsCertificatesResponse,
  Qualification,
  QualificationCertificatesResponse,
} from "../../../types/TypeSpecialist";
import { compressFiles } from "../../../utils/compressionFile";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import Input from "../../Input/Input";
import MuiDatePicker from "../../MuiDatePicker/MuiDatePicker";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";
import StandartButton from "../../StandartButton/StandartButton";
import s from "../AddCertificatesModal.module.css";

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

type FederationsCertificates = {
  selectData: {
    education?: Education[];
    qualification?: Qualification[];
    federation?: Federation[];
  };
  setActionLoaders: Dispatch<SetStateAction<{ addedCertificate: boolean }>>;
  specialistId: string;
  specialization: string;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setNewCertificate: Dispatch<SetStateAction<CertificatesData>>;
  subSpecData: {
    value: string;
    lable: string;
  }[];
};

type NewEducation = {
  newEducation: {
    educationText: string | undefined;
    courseText: string | undefined;
    accreditationText: string | undefined;
  };
  educationToSave: {
    file: File | undefined;
    educationCourseId: string | undefined;
    educationAccreditationId?: string;
    educationId: string | undefined;
    membershipStart: Date | undefined;
    membershipEnd: Date | undefined;
    finished: boolean;
    studyHours: number | undefined;
    subSpecializations: string[] | undefined;
  };
};

const initialEducation: NewEducation = {
  newEducation: {
    educationText: undefined,
    courseText: undefined,
    accreditationText: undefined,
  },
  educationToSave: {
    file: undefined,
    educationCourseId: undefined,
    educationAccreditationId: undefined,
    educationId: undefined,
    membershipStart: undefined,
    membershipEnd: undefined,
    finished: true,
    studyHours: undefined,
    subSpecializations: undefined,
  },
};

const EducationsCertificates = ({
  selectData,
  setActionLoaders,
  specialistId,
  specialization,
  setModalOpen,
  setNewCertificate,
  subSpecData,
}: FederationsCertificates) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [selectEducation, setSelectEducation] = useState<string>("");
  const [selectCourse, setSelectCourse] = useState<string>("");
  const [selectAccreditation, setSelectAccreditation] = useState<string>("");
  const [addEducation, setAddEducation] =
    useState<NewEducation>(initialEducation);
  const [coursesData, setCoursesData] = useState<
    { value: string; lable: string }[]
  >([]);
  const [accreditationsData, setAccreditationsData] = useState<
    { value: string; lable: string }[]
  >([]);

  const studyFinishedData = [
    { lable: strings.finishedYes, value: true },
    { lable: strings.finishedNo, value: false },
  ];

  const handleCertificateChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      if (file && file.type.includes("pdf")) {
        setAddEducation((prev) => ({
          ...prev,
          educationToSave: {
            ...prev.educationToSave,
            file: file,
          },
        }));
      }
      if (file && !file.type.includes("pdf")) {
        const compressedFile = await compressFiles(file);
        if (compressedFile.file) {
          setAddEducation((prev) => ({
            ...prev,
            educationToSave: {
              ...prev.educationToSave,
              file: compressedFile.file,
            },
          }));
        }
      }
    }
  };

  const createEducation = async () => {
    if (!token || !userData) return;
    const payload = {
      educationText: [
        {
          text: addEducation.newEducation.educationText!,
          language: userData.selectedLanguage!,
        },
      ],
      specialization: specialization,
    };
    const response = await SpecialistApi.createEducation(token, payload);

    return { status: response.status, educationId: response.education?._id };
  };

  const createEducationCourse = async (educationId: string) => {
    if (!token || !userData) return;
    const payload = {
      courseText: [
        {
          text: addEducation.newEducation.courseText!,
          language: userData.selectedLanguage!,
        },
      ],
      educationId: educationId,
    };
    const response = await SpecialistApi.createEducationCourse(token, payload);
    if (response.status && response.course) {
      return response.course._id;
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
    return undefined;
  };
  const createEducationAccreditation = async (courseId: string) => {
    if (!token || !userData) return;
    const payload = {
      accreditationText: [
        {
          text: addEducation.newEducation.accreditationText!,
          language: userData.selectedLanguage!,
        },
      ],
      courseId: courseId,
    };
    const response = await SpecialistApi.createEducationAccreditation(
      token,
      payload
    );

    if (response.status && response.accreditation) {
      return response.accreditation._id;
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
    return undefined;
  };

  const saveEducation = async () => {
    if (!token) return;
    setActionLoaders((prev) => ({ ...prev, addedCertificate: true }));
    let educationId: string | undefined = undefined;
    let courseId: string | undefined = undefined;
    let accreditationId: string | undefined = undefined;
    if (selectEducation === "other") {
      const createEducationResponse = await createEducation();
      educationId = createEducationResponse?.educationId;
    }
    if (selectEducation !== "other") {
      educationId = selectEducation;
    }
    if (
      (selectCourse === "other" || selectEducation === "other") &&
      educationId
    ) {
      courseId = await createEducationCourse(educationId);
    }
    if (selectCourse !== "other" && selectEducation !== "other") {
      courseId = selectCourse;
    }
    if (
      (selectCourse === "other" ||
        selectEducation === "other" ||
        selectAccreditation === "other") &&
      courseId &&
      addEducation.newEducation.courseText &&
      addEducation.newEducation.courseText.length
    ) {
      accreditationId = await createEducationAccreditation(courseId);
    }
    if (
      selectCourse !== "other" &&
      selectEducation !== "other" &&
      selectAccreditation !== "other"
    ) {
      accreditationId = selectAccreditation;
    }
    if (educationId && courseId) {
      const formData = new FormData();
      if (addEducation.educationToSave.file) {
        formData.append("file", addEducation.educationToSave.file);
      }

      formData.append("educationId", educationId);
      formData.append("educationCourseId", courseId);
      accreditationId &&
        formData.append("educationAccreditationId", accreditationId);
      formData.append(
        "finished",
        addEducation.educationToSave.finished.toString()
      );
      formData.append(
        "studyHours",
        addEducation.educationToSave.studyHours!.toString()
      );
      formData.append(
        "membershipStart",
        addEducation.educationToSave.membershipStart!.toISOString()
      );
      formData.append(
        "membershipEnd",
        addEducation.educationToSave.membershipEnd!.toISOString()
      );
      if (addEducation.educationToSave.subSpecializations?.length) {
        addEducation.educationToSave.subSpecializations.forEach(
          (subSpecialization) => {
            formData.append("subSpecializations", subSpecialization);
          }
        );
      }
      const mainResponse = await SpecialistApi.uploadEducationCertificates(
        specialistId,
        formData,
        token
      );
      if (mainResponse.status) {
        setNewCertificate((prev) => ({
          ...prev,
          education: prev.education
            ? [...prev.education, mainResponse.certificate]
            : [mainResponse.certificate],
        }));
        SuccesNotify(strings.federationSucces);
        setAddEducation(initialEducation);
        setModalOpen(false);
        setSelectEducation("");
        setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
      }
      if (!mainResponse.status && mainResponse.message) {
        Notify(mainResponse.message);
        setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
      }
    }
  };
  const validationNewCertificate = () => {
    const educationToSave = addEducation.educationToSave;
    const newEducation = addEducation.newEducation;

    if (!selectEducation) return false;
    if (
      !educationToSave.membershipStart ||
      !educationToSave.membershipEnd ||
      !educationToSave.studyHours ||
      (educationToSave.finished && !educationToSave.file)
    )
      return false;
    if (
      (selectEducation === "other" && !newEducation.educationText) ||
      (selectAccreditation === "other" && !newEducation.accreditationText) ||
      (selectCourse === "other" && !newEducation.courseText)
    )
      return false;
    return true;
  };

  useEffect(() => {
    (async () => {
      if (
        !selectEducation ||
        !selectEducation.length ||
        selectEducation === "other"
      )
        return;
      const response = await SpecialistApi.getEducationCourses(selectEducation);
      if (response.status && response.courses) {
        setCoursesData(
          response.courses.map((item) => ({
            value: item._id,
            lable: item.text.find(
              (el) => el.language === userData?.selectedLanguage
            )?.text!,
          }))
        );
      }
    })();
  }, [selectEducation]);

  useEffect(() => {
    (async () => {
      if (!selectCourse.length || selectCourse === "other") return;
      const response = await SpecialistApi.getEducationAccreditations(
        selectCourse
      );
      if (response.status && response.accreditations) {
        setAccreditationsData(
          response.accreditations.map((item) => ({
            value: item._id,
            lable: item.text.find(
              (el) => el.language === userData?.selectedLanguage
            )?.text!,
          }))
        );
      }
    })();
  }, [selectCourse]);

  return (
    <div className={`${s.container} ${s.federationContainer}`}>
      {selectData &&
        selectData.education &&
        (selectEducation !== "other" ? (
          <MultipleSelect
            data={[
              ...(selectData.education.map((item) => ({
                lable:
                  item.text.find(
                    (el) => el.language === userData?.selectedLanguage
                  )?.text ?? item.text[0].text,
                value: item._id,
              })) ?? []),
              { lable: strings.otherCertificate, value: "other" },
            ]}
            multiplie={false}
            setValue={(value) => setSelectEducation(value as string)}
            value={selectEducation}
            padding="10px"
            margin="0 0 20px 0"
            lable={strings.educationTitle}
          />
        ) : (
          <div className={s.newFederationBlock}>
            <Input
              inputValue={addEducation.newEducation.educationText ?? ""}
              isVisible
              required
              onChangeInput={(value) =>
                setAddEducation((prev) => ({
                  ...prev,
                  newEducation: {
                    ...prev.newEducation,
                    educationText: value,
                  },
                }))
              }
              lable={strings.educationTitle}
              margin="0 0 20px 0"
            />
          </div>
        ))}
      {selectEducation === "other" || selectCourse === "other" ? (
        <Input
          inputValue={addEducation.newEducation.courseText ?? ""}
          isVisible
          required
          onChangeInput={(value) =>
            setAddEducation((prev) => ({
              ...prev,
              newEducation: {
                ...prev.newEducation,
                courseText: value,
              },
            }))
          }
          lable={strings.courseTitle}
          margin="0 0 20px 0"
        />
      ) : (
        <MultipleSelect
          data={[
            ...coursesData,
            { lable: strings.otherCertificate, value: "other" },
          ]}
          multiplie={false}
          setValue={(value) => setSelectCourse(value as string)}
          value={selectCourse}
          lable={strings.courseTitle}
          padding="10px"
          margin="0 0 20px 0"
          disabled={selectEducation.length ? false : true}
        />
      )}
      {selectEducation === "other" ||
      selectCourse === "other" ||
      selectAccreditation === "other" ? (
        <Input
          inputValue={addEducation.newEducation.accreditationText ?? ""}
          isVisible
          required
          onChangeInput={(value) =>
            setAddEducation((prev) => ({
              ...prev,
              newEducation: {
                ...prev.newEducation,
                accreditationText: value,
              },
            }))
          }
          lable={strings.accreditationLable}
          margin="0 0 20px 0"
        />
      ) : (
        <MultipleSelect
          data={[
            ...accreditationsData,
            { lable: strings.otherCertificate, value: "other" },
          ]}
          multiplie={false}
          setValue={(value) => setSelectAccreditation(value as string)}
          value={selectAccreditation}
          lable={strings.accreditationLable}
          padding="10px"
          margin="0 0 20px 0"
          disabled={selectCourse.length ? false : true}
        />
      )}
      <div className={s.multiSelectBlock}>
        <MultipleSelect
          multiplie={true}
          data={subSpecData}
          setValue={(value) =>
            setAddEducation((prev) => ({
              ...prev,
              educationToSave: {
                ...prev.educationToSave,
                subSpecializations: value as string[],
              },
            }))
          }
          value={addEducation.educationToSave.subSpecializations ?? []}
          lable={strings.qualification}
          checkBox
          chip
          padding="10px"
          margin="0 0 20px 0"
        />
      </div>
      <div>
        <span className={s.secondLable}>{strings.areYouFinishStudy}</span>
        {studyFinishedData.map((item, index) => (
          <div
            key={index}
            className={s.selectFinishedStudyBlock}
            onClick={() =>
              setAddEducation((prev) => ({
                ...prev,
                educationToSave: {
                  ...prev.educationToSave,
                  finished: item.value,
                },
              }))
            }
          >
            <Radio
              checked={addEducation.educationToSave.finished === item.value}
              sx={{
                color: "black",
                "&.Mui-checked": {
                  color: "#3FB083",
                },
              }}
            />
            <span>{item.lable}</span>
          </div>
        ))}
      </div>
      <div>
        <span className={s.secondLable}>{strings.studyHoursLable}</span>
        <Input
          inputValue={
            addEducation.educationToSave.studyHours
              ? addEducation.educationToSave.studyHours.toString()
              : ""
          }
          isVisible
          required
          type="number"
          onChangeInput={(value) => {
            const numericValue = value === "" ? 0 : Number(value);
            if (Number.isInteger(numericValue) && numericValue >= 0) {
              setAddEducation((prev) => ({
                ...prev,
                educationToSave: {
                  ...prev.educationToSave,
                  studyHours: numericValue,
                },
              }));
            }
          }}
        />
      </div>
      <div className={s.dateBlock}>
        <span className={s.secondLable}>
          {strings.educationDatesDescription}
        </span>
        <div className={s.dateInputBlock}>
          <div className={s.dateInput}>
            <MuiDatePicker
              value={
                addEducation.educationToSave.membershipStart &&
                dayjs(addEducation.educationToSave.membershipStart)
              }
              setValue={(value: Dayjs | null) =>
                setAddEducation((prev) => ({
                  ...prev,
                  educationToSave: {
                    ...prev.educationToSave,
                    membershipStart: value?.toDate(),
                  },
                }))
              }
            />
          </div>
          <div className={s.dateInput}>
            <MuiDatePicker
              value={
                addEducation.educationToSave.membershipEnd &&
                dayjs(addEducation.educationToSave.membershipEnd)
              }
              setValue={(value: Dayjs | null) =>
                setAddEducation((prev) => ({
                  ...prev,
                  educationToSave: {
                    ...prev.educationToSave,
                    membershipEnd: value?.toDate(),
                  },
                }))
              }
            />
          </div>
        </div>
      </div>
      {addEducation.educationToSave.finished && (
        <div className={s.uploadCertificateBlock}>
          <span className={s.headLable}>{strings.certificatesTitle}</span>
          <span className={s.secondLable}>{strings.certificateDescr}</span>
          <div className={s.uploadFilesActionBlock}>
            {addEducation.educationToSave.file && (
              <div>{addEducation.educationToSave.file.name}</div>
            )}
            <label className={s.fileInputBlock}>
              <input
                style={{ display: "none" }}
                type="file"
                accept={[...imageExtensions, ...documentExtensions].join(", ")}
                multiple={false}
                onChange={handleCertificateChange}
              />
              {addEducation.educationToSave.file ? (
                <span className={s.updateAvatarBtn} role="button">
                  <img src={EditIcon} alt="" />
                  <span>{strings.replace}</span>
                </span>
              ) : (
                <div className={s.addCertificateBtn}>
                  <PlusIcon />
                  <span>{strings.addCertificate}</span>
                </div>
              )}
            </label>
          </div>
        </div>
      )}
      <StandartButton
        action={saveEducation}
        buttonTitle={strings.saveCertificateBtn}
        width="100%"
        disabled={!validationNewCertificate()}
      />
    </div>
  );
};

export default EducationsCertificates;
