import countries from "i18n-iso-countries";
import { Dispatch, SetStateAction, useEffect } from "react";
import ReactFlagsSelect from "react-flags-select";
import UsersApi from "../../../../api/usersApi";
import CityPicker, {
  PlaceType,
} from "../../../../components/CitySelect/CitySelect";
import DropdownSelect from "../../../../components/DropdownSelect/DropdownSelect";
import Input from "../../../../components/Input/Input";
import MultipleSelect from "../../../../components/MultipleSelect/MultipleSelect";
import RadioButtonsGroup from "../../../../components/RadioGroup/RadioGroup";
import {
  ISO639Lang,
  popularLanguages,
} from "../../../../constans/languagesList";
import strings from "../../../../localization";
import {
  daysList,
  generateNumbersArray,
  yearsList,
} from "../../../../utils/setupProfileInfo";
import s from "../CreatingProfile.module.css";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

type TextInputValues = {
  name: string;
  surname: string;
  latinName: string;
  latinSurname: string;
  practics: string;
  experience: string;
};

type SubStep1Props = {
  textInputValues: TextInputValues;
  setTextInputValues: Dispatch<SetStateAction<TextInputValues>>;
  dateOfBith: { day: number; month: number; year: number };
  setDateOfBith: Dispatch<
    SetStateAction<{ day: number; month: number; year: number }>
  >;
  genderValue: "male" | "female" | string;
  setGenderValue: Dispatch<SetStateAction<"male" | "female" | string>>;
  selectedLangs: string[];
  setSelectedLangs: Dispatch<SetStateAction<string[]>>;
  selectedCountry: string;
  setSelectedCountry: Dispatch<SetStateAction<string>>;
  selectedCity: PlaceType | null;
  setSelectedCity: Dispatch<SetStateAction<PlaceType | null>>;
  nameSurnameValues: {
    name: { text: string; language: string }[] | undefined;
    surname: { text: string; language: string }[] | undefined;
  };
  setNameSurnameValues: Dispatch<
    SetStateAction<{
      name: { text: string; language: string }[] | undefined;
      surname: { text: string; language: string }[] | undefined;
    }>
  >;
};

const SubStep1 = ({
  textInputValues,
  setDateOfBith,
  dateOfBith,
  setTextInputValues,
  genderValue,
  setGenderValue,
  setSelectedLangs,
  selectedCountry,
  setSelectedCountry,
  selectedLangs,
  setSelectedCity,
  selectedCity,
  nameSurnameValues,
  setNameSurnameValues,
}: SubStep1Props) => {
  const dateBirthUpdate = (
    value: string,
    objKey: keyof { day: number; month: number; year: number }
  ) => {
    setDateOfBith((prev) => ({ ...prev, [objKey]: Number(value) }));
  };

  const monthNames = [
    strings.jan,
    strings.feb,
    strings.mar,
    strings.apr,
    strings.may,
    strings.jun,
    strings.jul,
    strings.aug,
    strings.sep,
    strings.oct,
    strings.nov,
    strings.dec,
  ];

  const getUserGeoInfo = async () => {
    const response = await UsersApi.getUserGeoInfo();
    if (response.status && response.geo) {
      setSelectedCountry(response.geo.country);
    }
  };

  useEffect(() => {
    getUserGeoInfo();
  }, []);

  const monthList = generateNumbersArray(1, 12).map((item, index) => {
    return {
      value: item,
      lable: monthNames[index],
    };
  });
  const languagesList = popularLanguages.map((item) => ({
    value: item,
    lable: item,
  }));

  const denderRadioFroupData = [
    {
      value: "male",
      lable: strings.genderLableMale,
    },
    {
      value: "female",
      lable: strings.genderLableFemale,
    },
  ];

  return (
    <div className={s.subStepBlock}>
      <div className={s.substepContent1}>
        <div className={s.nameSurnameBlock}>
          <div className={s.inputBlock}>
            <p className={s.lableTitle}>
              {strings.setupNameLable}
              <span className={s.require}>*</span>
            </p>
            <div className={s.nameValuesList}>
              {nameSurnameValues.name &&
                nameSurnameValues.name.map((item, index) => (
                  <Input
                    key={index}
                    inputValue={item.text}
                    onChangeInput={(value) =>
                      setNameSurnameValues((prev) => {
                        if (item.language === "en") {
                          value = value.replace(/[^a-zA-Z\s]/g, "");
                        }
                        return prev.name
                          ? {
                              ...prev,
                              name: prev.name.map((element) => {
                                if (item.language === element.language) {
                                  return {
                                    text: value,
                                    language: item.language,
                                  };
                                } else return element;
                              }),
                            }
                          : {
                              ...prev,
                              name: undefined,
                              surname: undefined,
                            };
                      })
                    }
                    isVisible
                    required
                    //@ts-expect-error
                    lable={strings[ISO639Lang[item.language]]}
                  />
                ))}
            </div>
          </div>
          <div className={s.inputBlock}>
            <p className={s.lableTitle}>
              {strings.setupSurnameLable}
              <span className={s.require}>*</span>
            </p>
            <div className={s.nameValuesList}>
              {nameSurnameValues.surname &&
                nameSurnameValues.surname.map((item, index) => (
                  <Input
                    key={index}
                    inputValue={item.text}
                    onChangeInput={(value) =>
                      setNameSurnameValues((prev) => {
                        if (item.language === "en") {
                          value = value.replace(/[^a-zA-Z\s]/g, "");
                        }
                        return prev.surname
                          ? {
                              ...prev,
                              surname: prev.surname.map((element) => {
                                if (item.language === element.language) {
                                  return {
                                    text: value,
                                    language: item.language,
                                  };
                                } else return element;
                              }),
                            }
                          : {
                              ...prev,
                              name: undefined,
                              surname: undefined,
                            };
                      })
                    }
                    isVisible
                    required
                    //@ts-expect-error
                    lable={strings[ISO639Lang[item.language]]}
                  />
                ))}
            </div>
          </div>
        </div>

        <p className={s.lableTitle} style={{ marginBottom: "20px" }}>
          {strings.setupDateOfBirthLable} <span className={s.require}> *</span>
        </p>
        <div className={s.dateOfBirthBlock}>
          <DropdownSelect
            data={daysList}
            value={dateOfBith.day.toString()}
            setValue={dateBirthUpdate}
            placeHolder={strings.setupDayLable}
            width="30%"
            objKey="day"
          />
          <DropdownSelect
            data={monthList}
            value={dateOfBith.month.toString()}
            setValue={dateBirthUpdate}
            placeHolder={strings.setupmonthLable}
            width="30%"
            objKey="month"
          />
          <DropdownSelect
            data={yearsList}
            value={dateOfBith.year.toString()}
            setValue={dateBirthUpdate}
            placeHolder={strings.setupYearLable}
            width="30%"
            objKey="year"
          />
        </div>
        <div className={s.countryLangSelectBlock}>
          <p className={s.lableTitle} style={{ marginBottom: "20px" }}>
            {strings.setupChooseContryLable}
            <span className={s.require}> *</span>
          </p>

          <div className={s.countyBlock}>
            <ReactFlagsSelect
              blacklistCountries
              countries={["RU"]}
              className={s.countrySelect}
              selected={selectedCountry}
              onSelect={(code) => setSelectedCountry(code)}
              placeholder={strings.setupCountryPlaceholder}
              searchable
            />

            <div className={s.cityPickerBlock}>
              <CityPicker
                selectedCity={selectedCity}
                selectedCountry={selectedCountry}
                onCitySelect={setSelectedCity}
                placeHolder={strings.setupCityPlaceHolder}
              />
            </div>
            <div className={s.languageBlock}>
              <MultipleSelect
                data={languagesList}
                setValue={(value) => setSelectedLangs(value as string[])}
                value={selectedLangs}
                multiplie={true}
                lable={strings.setupLanguagePlaceHodler}
                checkBox
                lableTop="0"
              />
            </div>
          </div>
        </div>
        <div className={s.experienceBlock}>
          <div className={s.experienceElement}>
            <p className={s.lableTitle}>
              {strings.setupPracticalHours}
              <span className={s.require}> *</span>
            </p>
            <Input
              inputValue={textInputValues.practics}
              onChangeInput={(value) => {
                setTextInputValues((prev) => ({
                  ...prev,
                  practics: value,
                }));
              }}
              isVisible
              required
              padding="18px 20px"
              fontSize="16px"
              margin="0 0 10px 0"
              type="number"
            />

            <p className={s.lableTitle}>{strings.setupExample1}</p>
          </div>
          <div className={s.experienceElement}>
            <p className={s.lableTitle}>
              {strings.setupExperienceTitle}
              <span className={s.require}> *</span>
            </p>
            <Input
              inputValue={textInputValues.experience}
              onChangeInput={(value) => {
                setTextInputValues((prev) => ({
                  ...prev,
                  experience: value,
                }));
              }}
              isVisible
              required
              padding="18px 20px"
              fontSize="16px"
              margin="0 0 10px 0"
              type="number"
            />

            <p className={s.lableTitle}>{strings.setupExample2}</p>
          </div>
        </div>
        <div className={s.genderBlock}>
          <p className={s.lableTitle}>
            {strings.whatGender}
            <span className={s.require}> *</span>
          </p>
          <RadioButtonsGroup
            data={denderRadioFroupData}
            defaultValue="male"
            value={genderValue}
            setValue={setGenderValue}
          />
        </div>
      </div>
    </div>
  );
};

export default SubStep1;
