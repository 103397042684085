export const formatNumber = (number: number) => {
  if (number === 0) {
    return "";
  }
  if (number >= 1000) {
    return (number / 1000).toFixed(1) + "k";
  }
  return number; //1.2k
};

export const calculateDiscountedPrice = (
  originalPrice: number,
  discountPercent: number
): number => {
  if (discountPercent === 0) return originalPrice;
  const discountAmount = (originalPrice * discountPercent) / 100;
  return originalPrice - discountAmount;
};
