import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

type DropdownSelectProps = {
  data: { value: string; lable: string }[];
  setValue: (
    value: string,
    objKey: keyof { day: number; month: number; year: number }
  ) => void;
  value: string;
  placeHolder: string;
  width?: string;
  objKey: keyof { day: number; month: number; year: number };
  lableColor?: string;
};

const DropdownSelect = ({
  data,
  setValue,
  value,
  placeHolder,
  width,
  objKey,
  lableColor,
}: DropdownSelectProps) => {
  const inputStyle = {
    border: "none",
    "&:focus": {
      borderColor: "#3FB083",
    },
  };

  return (
    <div style={{ width: width ? width : "100%" }}>
      <FormControl
        variant="outlined"
        sx={{ minWidth: 50, width: "100%", margin: "8px 8px 8px 0" }}
      >
        <InputLabel
          id="demo-simple-select-filled-label"
          style={{
            color: lableColor ? lableColor : "#3FB083",
            fontSize: "20px",
            top: -15,
            left: -13,
          }}
        >
          {placeHolder}
        </InputLabel>
        <Select
          color="success"
          style={{
            borderRadius: "20px",
            backgroundColor: "#f0f0f0",
            borderColor: "red",
            ...inputStyle,
          }}
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={value}
          onChange={(e) => setValue(e.target.value, objKey)}
          inputProps={{
            style: {
              ...inputStyle,
            },
          }}
          SelectDisplayProps={{
            style: {
              borderRadius: "20px",
              backgroundColor: "#f0f0f0",
              border: "none",
            },
          }}
          MenuProps={{
            style: {
              borderRadius: "20px",
              marginTop: "0.5rem",
              maxHeight: "500px", // Устанавливаем максимальную высоту
            },
          }}
        >
          {data.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              {item.lable}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default DropdownSelect;
