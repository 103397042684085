import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import SpecialistApi from "../../../api/specialistApi";
import UsersApi from "../../../api/usersApi";
import NextSlideIcon from "../../../assets/SpecialistSetupInfo/next-slide.svg";
import { PlaceType } from "../../../components/CitySelect/CitySelect";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ISO639LangReversed } from "../../../constans/languagesList";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { SpecialistData } from "../../../types/TypeSpecialist";
import {
  areAllStatesValid,
  updateSpecialistIntroVideo,
  updateUserAvatar,
} from "../../../utils/setupProfileInfo";
import Notify from "../../../utils/toaster";
import s from "./CreatingProfile.module.css";
import SubStep1 from "./SubSteps/SubStep1";
import SubSteps2 from "./SubSteps/SubSteps2";
import SubSteps3 from "./SubSteps/SubSteps3";

type CreatingProfileProps = {
  setActiveStep: Dispatch<SetStateAction<number>>;
  subStep: { current: number; total: number };
  setSubStep: Dispatch<SetStateAction<{ current: number; total: number }>>;
  setLoader: Dispatch<SetStateAction<boolean>>;
  setSpecialistInfo: Dispatch<SetStateAction<SpecialistData | null>>;
  specialistInfo: SpecialistData | null;
};

const CreatingProfile = ({
  setActiveStep,
  subStep,
  setSubStep,
  setLoader,
  setSpecialistInfo,
  specialistInfo,
}: CreatingProfileProps) => {
  const { userData, setUserData } = useContext(ContextProvider);
  const [dateOfBith, setDateOfBith] = useState({
    day: 1,
    month: 1,
    year: 2000,
  });
  const [textInputValues, setTextInputValues] = useState({
    name: "",
    surname: "",
    latinName: "",
    latinSurname: "",
    practics: "",
    experience: "",
  });
  const [genderValue, setGenderValue] = useState<"male" | "female" | string>(
    "male"
  );
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState<PlaceType | null>(null);
  const [selectedLangs, setSelectedLangs] = useState<string[]>([]);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [video, setVideo] = useState<{ language: string; file: File }[] | null>(
    null
  );
  const [nameSurnameValues, setNameSurnameValues] = useState<{
    name: { text: string; language: string }[] | undefined;
    surname: { text: string; language: string }[] | undefined;
  }>({ name: undefined, surname: undefined });

  useEffect(() => {
    const emptyNameSurname = [
      ...selectedLangs
        .filter((el) => el !== "English")
        .map((item) => ({
          text: "",
          langage: ISO639LangReversed[item],
        })),
      { text: "", langage: "en" },
    ];
    const currentName = emptyNameSurname.map((item) => ({
      text: nameSurnameValues.name?.find((el) => el.language === item.langage)
        ? nameSurnameValues.name?.find((el) => el.language === item.langage)
            ?.text!
        : "",
      language: item.langage,
    }));
    const currentSurname = emptyNameSurname.map((item) => ({
      text: nameSurnameValues.surname?.find(
        (el) => el.language === item.langage
      )
        ? nameSurnameValues.surname?.find((el) => el.language === item.langage)
            ?.text!
        : "",
      language: item.langage,
    }));
    setNameSurnameValues({ name: currentName, surname: currentSurname });
  }, [selectedLangs]);

  const nextStep = async () => {
    const token = localStorage.getItem("token");
    const userDate = new Date(
      dateOfBith.year,
      dateOfBith.month - 1,
      dateOfBith.day
    );

    if (
      token &&
      userData &&
      userData._id &&
      (userData?.specialistIds ||
        (userData?.specialistIds && userData?.specialistIds[0]._id))
    ) {
      setLoader(true);
      const specialistId = userData?.specialistIds[0]._id
        ? userData?.specialistIds[0]._id
        : userData.specialistIds[0].toString();
      if (subStep.current === 1) {
        if (
          !areAllStatesValid(
            nameSurnameValues.name,
            nameSurnameValues.surname,
            textInputValues,
            selectedCountry,
            selectedCity,
            selectedLangs
          )
        ) {
          setLoader(false);
          return Notify(strings.fillAllFields);
        }

        const updateUserDataResponse = await UsersApi.updateUserData(token, {
          _id: userData?._id,
          name: nameSurnameValues.name,
          surname: nameSurnameValues.surname,
          latinName: nameSurnameValues.name!.find((el) => el.language === "en")
            ?.text,
          latinSurname: nameSurnameValues.surname!.find(
            (el) => el.language === "en"
          )?.text,
          dateOfBirth: userDate,
          gender: genderValue,
          preferredLanguages: selectedLangs,
          location:
            selectedCity?.terms[0].value +
            ", " +
            selectedCity?.terms[selectedCity?.terms.length - 1].value,
        });
        if (updateUserDataResponse.status && updateUserDataResponse.userData) {
          //@ts-expect-error
          setUserData((prev) => {
            if (!prev) return null;

            return {
              ...updateUserDataResponse.userData,
              specialistIds: prev.specialistIds ?? [],
              _id: updateUserDataResponse.userData!._id ?? prev._id,
            };
          });
        }
        const response = await SpecialistApi.update(
          {
            _id: specialistId,
            practiceHours: Number(textInputValues.practics),
            yearsOfExperience: Number(textInputValues.experience),
          },
          token
        );
        if (response.status) setSpecialistInfo(response.specialistData);

        setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
      } else if (subStep.current === 2) {
        if (!video && !avatar) {
          setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
          return setLoader(false);
        }
        if (avatar) {
          await updateUserAvatar(avatar, userData._id, token);
        }
        if (!video) {
          setLoader(false);
          return setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
        }
        const filesData = video.map((item) => ({
          language: item.language,
          video: item.file,
        }));
        await updateSpecialistIntroVideo(specialistId, filesData, token);

        setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
      } else if (subStep.current === 3) {
        setActiveStep((prev) => prev + 1);
        return setLoader(false);
      }
      setLoader(false);
    }
  };

  return (
    <div>
      {subStep.current === 1 && (
        <SubStep1
          dateOfBith={dateOfBith}
          setDateOfBith={setDateOfBith}
          setTextInputValues={setTextInputValues}
          textInputValues={textInputValues}
          genderValue={genderValue}
          setGenderValue={setGenderValue}
          selectedCountry={selectedCountry}
          selectedLangs={selectedLangs}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          setSelectedCountry={setSelectedCountry}
          setSelectedLangs={setSelectedLangs}
          nameSurnameValues={nameSurnameValues}
          setNameSurnameValues={setNameSurnameValues}
        />
      )}
      {subStep.current === 2 && (
        <SubSteps2
          avatar={avatar}
          setAvatar={setAvatar}
          setVideo={setVideo}
          video={video}
          specialistInfo={specialistInfo}
        />
      )}
      {subStep.current === 3 && <SubSteps3 specialistInfo={specialistInfo} />}
      <div className={s.continueButtonBlock}>
        {subStep.current === subStep.total ? (
          <StandartButton
            buttonTitle={strings.continueBtnSetupProfile}
            action={nextStep}
            width="250px"
          />
        ) : (
          <div role="button" className={s.nextButtonBlock}>
            <img
              className={s.nextImage}
              src={NextSlideIcon}
              alt=""
              onClick={nextStep}
            />
          </div>
        )}
      </div>
      <div className={s.mobileContinueButtonBlock}>
        <StandartButton
          buttonTitle={strings.continueBtnSetupProfile}
          action={nextStep}
          width="70%"
        />
      </div>
    </div>
  );
};

export default CreatingProfile;
