import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import SocialApi from "../../../api/socialApi";
import { ReactComponent as SubscribeIcon } from "../../../assets/Profile/profile-add.svg";
import { ReactComponent as UnsubscribeIcon } from "../../../assets/Profile/unsubscribe.svg";
import strings from "../../../localization";
import Notify from "../../../utils/toaster";
import s from "./SubscribeBtn.module.css";

type SubscribeBtn = {
  userId: string;
};

const SubscribeBtn = ({ userId }: SubscribeBtn) => {
  const token = localStorage.getItem("token");
  const [isSubscribe, setIsSubscribe] = useState(false);

  useEffect(() => {
    const maleAsync = async () => {
      if (!token) return;
      const response = await SocialApi.checkIfFollowed(token, userId);
      if (response.status) {
        setIsSubscribe(response.followed);
      }
    };
    maleAsync();
  }, [userId, token]);

  const followUser = async () => {
    if (!token) return;
    setIsSubscribe(true);
    const response = await SocialApi.followUser(token, {
      userIdToFollow: userId,
    });

    if (!response.status && response.message) {
      Notify(response.message);
      setIsSubscribe(false);
    }
  };

  const unfollowUser = async () => {
    if (!token) return;
    setIsSubscribe(false);
    const response = await SocialApi.unfollowUser(token, {
      userIdToUnfollow: userId,
    });

    if (!response.status && response.message) {
      Notify(response.message);

      setIsSubscribe(true);
    }
  };

  return (
    <div className={s.container}>
      {isSubscribe ? (
        <Tooltip arrow title={strings.unsubscribeBtn}>
          <div className={s.unsubContainer}>
            <div role="button" className={s.unsubBtn} onClick={unfollowUser}>
              <UnsubscribeIcon className={s.unsubBtnIcon} />
            </div>
          </div>
        </Tooltip>
      ) : (
        <Tooltip arrow title={strings.subscribeBtn}>
          <div className={s.subscribeBtn} role="button" onClick={followUser}>
            <SubscribeIcon />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default SubscribeBtn;
