import AnimateHeight from "react-animate-height";
import CheckIcon from "../../../assets/SpecialistSetupInfo/check-square.svg";
import strings from "../../../localization";
import s from "./SetupInfoSidebar.module.css";

type TpyeSetupInfoSidebarProps = {
  activeStep: number;
};

const SetupInfoSidebar = ({ activeStep }: TpyeSetupInfoSidebarProps) => {
  return (
    <div className={s.container}>
      <ul className={s.sidebarMenu}>
        <li className={s.menuElement}>
          <div className={s.menuElBlock}>
            <p className={activeStep === 1 ? s.activeStep : s.notActiveStep}>
              {strings.yourSpecialization}
            </p>
            {activeStep === 2 && <img src={CheckIcon} alt="" />}
          </div>
          <AnimateHeight
            id="example-panel"
            duration={500}
            height={activeStep === 1 ? "auto" : 0}
          >
            <ul className={s.subMenu}>
              <li>{strings.chooseSpecialization}</li>
            </ul>
          </AnimateHeight>
        </li>
        <li className={s.menuElement}>
          <div className={s.menuElBlock}>
            <p className={activeStep === 2 ? s.activeStep : s.notActiveStep}>
              {strings.createProfile}
            </p>
            {activeStep === 3 && <img src={CheckIcon} alt="" />}
          </div>
          <AnimateHeight
            id="example-panel"
            duration={500}
            height={activeStep === 2 ? "auto" : 0}
          >
            <ul className={s.subMenu}>
              <li>{strings.personalData}</li>
              <li>{strings.avatarVideo}</li>
              <li>{strings.diplomas}</li>
            </ul>
          </AnimateHeight>
        </li>
        <li className={s.menuElement}>
          <div className={s.menuElBlock}>
            <p className={activeStep === 3 ? s.activeStep : s.notActiveStep}>
              {strings.about}
            </p>
            {activeStep === 4 && <img src={CheckIcon} alt="" />}
          </div>
          <AnimateHeight
            id="example-panel"
            duration={500}
            height={activeStep === 3 ? "auto" : 0}
          >
            <ul className={s.subMenu}>
              <li>{strings.baseInfo}</li>
              <li>{strings.howWork}</li>
              <li>{strings.brief}</li>
            </ul>
          </AnimateHeight>
        </li>
        <li className={s.menuElement}>
          <div className={s.menuElBlock}>
            <p className={activeStep === 4 ? s.activeStep : s.notActiveStep}>
              {strings.price}
            </p>
            {activeStep === 5 && <img src={CheckIcon} alt="" />}
          </div>
          <AnimateHeight
            id="example-panel"
            duration={500}
            height={activeStep === 4 ? "auto" : 0}
          >
            <ul className={s.subMenu}>
              <li>{strings.enterPrice}</li>
            </ul>
          </AnimateHeight>
        </li>
      </ul>
    </div>
  );
};

export default SetupInfoSidebar;
