import dayjs, { Dayjs } from "dayjs";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SpecialistApi from "../../../api/specialistApi";
import EditIcon from "../../../assets/Profile/cloud-plus.svg";
import { ReactComponent as PlusIcon } from "../../../assets/Profile/specialist/add.svg";
import {
  documentExtensions,
  imageExtensions,
} from "../../../constans/filesExtensions";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import {
  Education,
  EducationCertificatesResponse,
  Federation,
  FederationsCertificatesResponse,
  Qualification,
  QualificationCertificatesResponse,
} from "../../../types/TypeSpecialist";
import { compressFiles } from "../../../utils/compressionFile";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import Input from "../../Input/Input";
import MuiDatePicker from "../../MuiDatePicker/MuiDatePicker";
import MultipleSelect from "../../MultipleSelect/MultipleSelect";
import StandartButton from "../../StandartButton/StandartButton";
import s from "../AddCertificatesModal.module.css";

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

type FederationsCertificates = {
  selectData: {
    education?: Education[];
    qualification?: Qualification[];
    federation?: Federation[];
  };
  setActionLoaders: Dispatch<SetStateAction<{ addedCertificate: boolean }>>;
  specialistId: string;
  specialization: string;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setNewCertificate: Dispatch<SetStateAction<CertificatesData>>;
};

type NewQualification = {
  newQualification: {
    qualificationText: string | undefined;
    federationText: string | undefined;
    badge: {
      file: File | undefined;
      url: string | undefined;
      qualificationId: string | undefined;
    };
  };
  qualificationToSave: {
    file: File | undefined;
    qualificationId: string | undefined;
    membershipStart: Date | undefined;
    membershipEnd: Date | undefined;
    federationProfileUrl: string | undefined;
  };
};

const initialQualification: NewQualification = {
  newQualification: {
    qualificationText: undefined,
    federationText: undefined,
    badge: {
      file: undefined,
      url: undefined,
      qualificationId: undefined,
    },
  },
  qualificationToSave: {
    file: undefined,
    qualificationId: undefined,
    membershipStart: undefined,
    membershipEnd: undefined,
    federationProfileUrl: undefined,
  },
};

const QualificationsCertificates = ({
  selectData,
  setActionLoaders,
  specialistId,
  specialization,
  setModalOpen,
  setNewCertificate,
}: FederationsCertificates) => {
  const { userData } = useContext(ContextProvider);
  const token = localStorage.getItem("token");
  const [selectQualification, setSelectQualification] = useState<string>("");
  const [selectFederation, setSelectFederation] = useState<string>("");
  const [addQualification, setAddQualification] =
    useState<NewQualification>(initialQualification);
  const [qualificationsData, setQualificationsData] = useState<Qualification[]>(
    []
  );

  const getQualifications = async (federationId: string) => {
    const response = await SpecialistApi.getQualifications(
      specialization,
      federationId
    );

    if (response.status && response.qualifications) {
      setQualificationsData(response.qualifications);
    }
  };
  useEffect(() => {
    if (!selectFederation.length || selectFederation === "other") return;
    getQualifications(selectFederation);
  }, [selectFederation]);

  const validateURL = (url: string | undefined) => {
    if (!url) return false;
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleCertificateChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      if (file && file.type.includes("pdf")) {
        setAddQualification((prev) => ({
          ...prev,
          qualificationToSave: {
            ...prev.qualificationToSave,
            file: file,
          },
        }));
      }
      if (file && !file.type.includes("pdf")) {
        const compressedFile = await compressFiles(file);
        if (compressedFile.file) {
          setAddQualification((prev) => ({
            ...prev,
            qualificationToSave: {
              ...prev.qualificationToSave,
              file: compressedFile.file,
            },
          }));
        }
      }
    }
  };

  /*   const handleBadgeIconChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];

      if (file) {
        const compressedFile = await compressFiles(file);
        if (compressedFile.file) {
          setAddQualification((prev) => ({
            ...prev,
            newQualification: {
              ...prev.newQualification,
              badge: {
                ...prev.newQualification.badge,
                file: compressedFile.file,
              },
            },
          }));
        }
      }
    }
  }; */
  const createQualification = async (federationId: string) => {
    if (!token || !userData) return;
    const payload = {
      qualificationText: [
        {
          text: addQualification.newQualification.qualificationText!,
          language: userData.selectedLanguage!,
        },
      ],
      specialization: specialization,
      federationId: federationId,
    };
    const response = await SpecialistApi.createQualification(token, payload);
    return response.qualification?._id;
  };
  const createFederation = async () => {
    if (!token || !userData) return;
    const payload = {
      federationText: [
        {
          text: addQualification.newQualification.federationText!,
          language: userData.selectedLanguage!,
        },
      ],
      specialization: specialization,
    };
    const response = await SpecialistApi.createFederation(token, payload);
    return { status: response.status, federationId: response.federation?._id };
  };
  /*   const createFederationBadge = async () => {
    if (!token) return;
    const formData = new FormData();
    formData.append("file", addQualification.newQualification.badge.file!);
    formData.append("url", addQualification.newQualification.badge.url!);
    const response = await SpecialistApi.createFederationBadge(token, formData);
    return { status: response.status, badgeId: response.badge?._id };
  }; */
  const saveFederation = async () => {
    if (!token) return;
    setActionLoaders((prev) => ({ ...prev, addedCertificate: true }));
    let qualificationId: string | undefined = undefined;
    let federationId: string | undefined = undefined;
    if (selectQualification && selectQualification !== "other") {
      qualificationId = selectQualification;
    }
    if (selectFederation === "other") {
      const createFederationResponse = await createFederation();
      federationId = createFederationResponse?.federationId;
    }
    if (selectFederation && selectFederation !== "other") {
      federationId = selectFederation;
    }
    if (
      federationId &&
      (selectFederation === "other" || selectQualification === "other")
    ) {
      qualificationId = await createQualification(federationId);
    }
    if (qualificationId) {
      const formData = new FormData();
      formData.append("file", addQualification.qualificationToSave.file!);
      formData.append("qualificationId", qualificationId);
      formData.append(
        "membershipStart",
        addQualification.qualificationToSave.membershipStart!.toISOString()
      );
      formData.append(
        "membershipEnd",
        addQualification.qualificationToSave.membershipEnd!.toISOString()
      );
      formData.append(
        "federationProfileUrl",
        addQualification.qualificationToSave.federationProfileUrl!
      );
      const mainResponse = await SpecialistApi.uploadQualificationCertificates(
        specialistId,
        formData,
        token
      );
      if (mainResponse.status) {
        SuccesNotify(strings.federationSucces);
        setAddQualification(initialQualification);
        setModalOpen(false);
        setSelectQualification("");
        setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
        setNewCertificate((prev) => ({
          ...prev,
          qualification: prev.qualification
            ? [...prev.qualification, mainResponse.certificate]
            : [mainResponse.certificate],
        }));
      }
      if (!mainResponse.status && mainResponse.message) {
        Notify(mainResponse.message);
        setActionLoaders((prev) => ({ ...prev, addedCertificate: false }));
      }
    }
  };

  const validationNewCertificate = () => {
    const qualificationToSave = addQualification.qualificationToSave;
    const newQualification = addQualification.newQualification;

    if (!selectQualification.length && !newQualification.qualificationText)
      return false;
    if (
      !validateURL(qualificationToSave.federationProfileUrl) ||
      !qualificationToSave.membershipStart ||
      !qualificationToSave.membershipEnd ||
      !qualificationToSave.file
    )
      return false;
    if (
      selectFederation === "other" &&
      (!newQualification.federationText ||
        !newQualification.federationText.length)
    )
      return false;
    if (
      (selectQualification === "other" || selectFederation === "other") &&
      (!newQualification.qualificationText ||
        !newQualification.qualificationText.length) /* ||
        !newQualification.badge.file ||
        !validateURL(newQualification.badge.url)) */
    )
      return false;
    return true;
  };

  return (
    <div className={`${s.container} ${s.federationContainer}`}>
      {selectData &&
        selectData.federation &&
        (selectFederation !== "other" ? (
          <MultipleSelect
            data={[
              ...(selectData.federation.map((item) => ({
                lable:
                  item.text.find(
                    (el) => el.language === userData?.selectedLanguage
                  )?.text ?? item.text[0].text,
                value: item._id,
              })) ?? []),
              { lable: strings.otherCertificate, value: "other" },
            ]}
            multiplie={false}
            setValue={(value) => setSelectFederation(value as string)}
            value={selectFederation}
            padding="10px"
            lable={strings.federationTitle}
            margin="0 0 20px 0"
          />
        ) : (
          <div className={s.newFederationBlock}>
            <Input
              inputValue={
                addQualification.newQualification.federationText ?? ""
              }
              isVisible
              required
              onChangeInput={(value) =>
                setAddQualification((prev) => ({
                  ...prev,
                  newQualification: {
                    ...prev.newQualification,
                    federationText: value,
                  },
                }))
              }
              lable={strings.federationTitle}
              margin="0 0 20px 0"
            />
          </div>
        ))}
      {qualificationsData &&
        (selectQualification !== "other" && selectFederation !== "other" ? (
          <MultipleSelect
            data={[
              ...(qualificationsData.map((item) => ({
                lable:
                  item.text.find(
                    (el) => el.language === userData?.selectedLanguage
                  )?.text ?? item.text[0].text,
                value: item._id,
              })) ?? []),
              { lable: strings.otherCertificate, value: "other" },
            ]}
            multiplie={false}
            setValue={(value) => setSelectQualification(value as string)}
            value={selectQualification}
            padding="10px"
            lable={strings.qualificationCert}
            disabled={!selectFederation.length ? true : false}
          />
        ) : (
          <div className={s.newFederationBlock}>
            <Input
              inputValue={
                addQualification.newQualification.qualificationText ?? ""
              }
              isVisible
              required
              onChangeInput={(value) =>
                setAddQualification((prev) => ({
                  ...prev,
                  newQualification: {
                    ...prev.newQualification,
                    qualificationText: value,
                  },
                }))
              }
              lable={strings.qualificationCert}
            />
            {/*  <Input
              inputValue={addQualification.newQualification.badge.url ?? ""}
              isVisible
              required
              onChangeInput={(value) =>
                setAddQualification((prev) => ({
                  ...prev,
                  newQualification: {
                    ...prev.newQualification,
                    badge: { ...prev.newQualification.badge, url: value },
                  },
                }))
              }
              lable={strings.badgeUrl}
              border={
                validateURL(addQualification.newQualification.badge.url) ||
                !addQualification.newQualification.badge.url ||
                !addQualification.newQualification.badge.url.length
                  ? "none"
                  : "1px solid red"
              }
              placeholder="https://www.credly.com/org/international-coach-federation/badge/icf-member-badge"
            />
            <div>
              <span className={s.secondLable}>{strings.badgeIcon}</span>
              <div className={s.uploadFilesActionBlock}>
                {addQualification.newQualification.badge.file && (
                  <img
                    src={URL.createObjectURL(
                      addQualification.newQualification.badge.file
                    )}
                    alt=""
                    className={s.badgeIcon}
                  />
                )}
                <label className={s.fileInputBlock}>
                  <input
                    style={{ display: "none" }}
                    type="file"
                   accept={imageExtensions.join(", ")}
                    multiple
                    onChange={handleBadgeIconChange}
                  />
                  {addQualification.newQualification.badge.file ? (
                    <span className={s.updateAvatarBtn} role="button">
                      <img src={EditIcon} alt="" />
                      <span>{strings.replace}</span>
                    </span>
                  ) : (
                    <div className={s.addCertificateBtn}>
                      <PlusIcon className={s.plusIcon} />
                      <span>{strings.addBadge}</span>
                    </div>
                  )}
                </label>
              </div>
            </div> */}
          </div>
        ))}
      <div className={s.inputBlock}>
        <Input
          inputValue={
            addQualification.qualificationToSave.federationProfileUrl ?? ""
          }
          isVisible
          required
          onChangeInput={(value) =>
            setAddQualification((prev) => ({
              ...prev,
              qualificationToSave: {
                ...prev.qualificationToSave,
                federationProfileUrl: value,
              },
            }))
          }
          lable={strings.federationProfileUrl}
          placeholder="https://apps.coachingfederation.org/eweb/CCFDynamicPage.aspx?webcode=ccfcoachprofileview&coachcstkey=A26D427B-86A3-4EE3-873E-D1468F4C48FB"
          border={
            validateURL(
              addQualification.qualificationToSave.federationProfileUrl
            ) ||
            !addQualification.qualificationToSave.federationProfileUrl ||
            !addQualification.qualificationToSave.federationProfileUrl.length
              ? "none"
              : "1px solid red"
          }
        />
      </div>
      <div className={s.dateBlock}>
        <span className={s.secondLable}>
          {strings.qualificationDatesDescription}
        </span>
        <div className={s.dateInputBlock}>
          <div className={s.dateInput}>
            <MuiDatePicker
              value={
                addQualification.qualificationToSave.membershipStart &&
                dayjs(addQualification.qualificationToSave.membershipStart)
              }
              setValue={(value: Dayjs | null) =>
                setAddQualification((prev) => ({
                  ...prev,
                  qualificationToSave: {
                    ...prev.qualificationToSave,
                    membershipStart: value?.toDate(),
                  },
                }))
              }
            />
          </div>
          <div className={s.dateInput}>
            <MuiDatePicker
              value={
                addQualification.qualificationToSave.membershipEnd &&
                dayjs(addQualification.qualificationToSave.membershipEnd)
              }
              setValue={(value: Dayjs | null) =>
                setAddQualification((prev) => ({
                  ...prev,
                  qualificationToSave: {
                    ...prev.qualificationToSave,
                    membershipEnd: value?.toDate(),
                  },
                }))
              }
            />
          </div>
        </div>
      </div>
      <div className={s.uploadCertificateBlock}>
        <span className={s.headLable}>{strings.certificatesTitle}</span>
        <span className={s.secondLable}>{strings.certificateDescr}</span>
        <div className={s.uploadFilesActionBlock}>
          {addQualification.qualificationToSave.file && (
            <div>{addQualification.qualificationToSave.file.name}</div>
          )}
          <label className={s.fileInputBlock}>
            <input
              style={{ display: "none" }}
              type="file"
              accept={[...imageExtensions, ...documentExtensions].join(", ")}
              multiple={false}
              onChange={handleCertificateChange}
            />
            {addQualification.qualificationToSave.file ? (
              <span className={s.updateAvatarBtn} role="button">
                <img src={EditIcon} alt="" />
                <span>{strings.replace}</span>
              </span>
            ) : (
              <div className={s.addCertificateBtn}>
                <PlusIcon />
                <span>{strings.addCertificate}</span>
              </div>
            )}
          </label>
        </div>
      </div>
      <StandartButton
        action={saveFederation}
        buttonTitle={strings.saveCertificateBtn}
        width="100%"
        disabled={!validationNewCertificate()}
      />
    </div>
  );
};

export default QualificationsCertificates;
