import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SpecialistApi from "../../api/specialistApi";
import { ContextProvider } from "../../contextProvider";
import {
  Education,
  EducationCertificatesResponse,
  Federation,
  FederationsCertificatesResponse,
  QualificationCertificatesResponse,
} from "../../types/TypeSpecialist";
import Loader from "../Loader/Loader";
import ModalWindow from "../ModalWindow/ModalWindow";
import s from "./AddCertificatesModal.module.css";
import EducationsCertificates from "./EducationsCertificates/EducationsCertificates";
import FederationsCertificates from "./FederationsCertificates/FederationsCertificates";
import QualificationsCertificates from "./QualificationsCertificates/QualificationsCertificates";

type CertificatesData = {
  education: EducationCertificatesResponse[] | undefined;
  qualification: QualificationCertificatesResponse[] | undefined;
  federation: FederationsCertificatesResponse[] | undefined;
};

type AddCertificatesModal = {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  certificateType: "education" | "qualification" | "federation";
  specialization: string;
  modalTitle: string;
  specialistId: string;
  setNewCertificate: Dispatch<SetStateAction<CertificatesData>>;
  mainSpec: string;
};

const AddCertificatesModal = ({
  modalOpen,
  setModalOpen,
  certificateType,
  specialization,
  modalTitle,
  specialistId,
  setNewCertificate,
  mainSpec,
}: AddCertificatesModal) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [selectData, setSelectData] = useState<{
    education?: Education[];
    federation?: Federation[];
  }>({});

  const [actionLoaders, setActionLoaders] = useState({
    addedCertificate: false,
  });
  const [subSpecData, setSubSpecData] = useState<
    { value: string; lable: string }[]
  >([]);
  const getSubSpecializations = async (mainSpec: string) => {
    if (token && userData) {
      const subSpecResponse = await SpecialistApi.getSubSpecializations(token, [
        mainSpec,
      ]);

      if (subSpecResponse.status && subSpecResponse.subSpecializations) {
        const subSpecData = subSpecResponse.subSpecializations.map((item) => ({
          value: item._id,
          lable: item.labels.find(
            (item) => item.language === userData?.selectedLanguage
          )?.text!,
        }));

        setSubSpecData(subSpecData);
      }
    }
  };
  useEffect(() => {
    getSubSpecializations(mainSpec);
  }, [mainSpec]);

  const getEducations = async () => {
    const response = await SpecialistApi.getEducations(specialization);
    if (response.status && response.educations) {
      setSelectData((prev) => ({ ...prev, education: response.educations }));
    }
  };

  const getFederations = async () => {
    const response = await SpecialistApi.getFederations(specialization);

    if (response.status && response.federations) {
      setSelectData((prev) => ({ ...prev, federation: response.federations }));
    }
  };

  useEffect(() => {
    getEducations();
    getFederations();
  }, []);

  return (
    <ModalWindow
      isOpen={modalOpen}
      setIsOpen={setModalOpen}
      width="fit-content"
      headTitle={modalTitle}
    >
      {actionLoaders.addedCertificate ? (
        <div className={s.loaderContainer}>
          <Loader size={100} />
        </div>
      ) : certificateType === "federation" ? (
        <FederationsCertificates
          selectData={selectData}
          setActionLoaders={setActionLoaders}
          setModalOpen={setModalOpen}
          specialistId={specialistId}
          specialization={specialization}
          setNewCertificate={setNewCertificate}
        />
      ) : certificateType === "qualification" ? (
        <QualificationsCertificates
          selectData={selectData}
          setActionLoaders={setActionLoaders}
          setModalOpen={setModalOpen}
          specialistId={specialistId}
          specialization={specialization}
          setNewCertificate={setNewCertificate}
        />
      ) : (
        <EducationsCertificates
          selectData={selectData}
          setActionLoaders={setActionLoaders}
          setModalOpen={setModalOpen}
          specialistId={specialistId}
          specialization={specialization}
          setNewCertificate={setNewCertificate}
          subSpecData={subSpecData}
        />
      )}
    </ModalWindow>
  );
};

export default AddCertificatesModal;
