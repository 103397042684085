import { ThemeProvider } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { DaySchedule } from "../../../utils/shedule";
import { theme } from "../../../utils/theme";
import StandartButton from "../../StandartButton/StandartButton";
import s from "./CopyScheduleMenu.module.css";

type CopyScheduleMenuProps = {
  allDaysState: DaySchedule[];
  setDaysState: Dispatch<SetStateAction<DaySchedule[]>>;
  selectedDay: string;
  setCopyMenuIsOpen: Dispatch<SetStateAction<number | boolean>>;
  buttonRef: HTMLDivElement | null;
};

const CopyScheduleMenu = ({
  allDaysState,
  setDaysState,
  selectedDay,
  setCopyMenuIsOpen,
  buttonRef,
}: CopyScheduleMenuProps) => {
  const [daysForCopy, setDaysForCopy] = useState<string[]>([selectedDay]);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const handleClickOutside = (event: any) => {
    if (
      menuRef &&
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !event.target.classList.contains(s.menuIcon)
    ) {
      setCopyMenuIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const updatePosition = () => {
    if (buttonRef && menuRef.current) {
      const buttonRect = buttonRef.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect();

      // Начальное позиционирование слева от кнопки и центрирование по вертикали
      let left = buttonRect.left - menuRect.width - 10;
      let top =
        buttonRect.top +
        window.scrollY +
        (buttonRect.height - menuRect.height) / 2;

      // Корректируем положение, если меню выходит за левую границу экрана
      if (left < 0) {
        left = buttonRect.right + 10; // Сместить меню вправо от кнопки
      }

      // Корректируем положение, если меню выходит за верхнюю/нижнюю границу экрана
      if (top + menuRect.height > window.innerHeight) {
        top = window.innerHeight - menuRect.height - 10; // Сдвиг вниз
      } else if (top < 0) {
        top = 10; // Сдвиг вверх
      }

      setPosition({ top, left });
    }
  };

  useEffect(() => {
    updatePosition();
    window.addEventListener("resize", updatePosition);
    return () => window.removeEventListener("resize", updatePosition);
  }, [buttonRef]);

  const handleCopy = () => {
    const dayToCopy = allDaysState.find((el) => el.dayValue === selectedDay)!;
    const updatedSchedule = allDaysState.map((item) => {
      if (daysForCopy.includes(item.dayValue)) {
        return {
          ...item,
          switch: dayToCopy.switch,
          workTime: dayToCopy.workTime,
        };
      }
      return item;
    });
    setDaysState(updatedSchedule);
    setCopyMenuIsOpen(false);
  };

  return ReactDOM.createPortal(
    <div
      className={s.container}
      ref={menuRef}
      style={{
        top: position.top,
        left: position.left,
        position: "absolute",
        opacity: position.left === 0 ? 0 : 1,
      }}
    >
      <span>Copy times to...</span>
      {allDaysState.map((item, index) => (
        <div key={index} className={s.dayElement}>
          <span>{item.daytitle}</span>
          <ThemeProvider theme={theme}>
            <Checkbox
              checked={daysForCopy.includes(item.dayValue)}
              inputProps={{ "aria-label": "controlled" }}
              color={"primary"}
              onClick={() =>
                setDaysForCopy((prev) =>
                  prev.includes(item.dayValue)
                    ? prev.filter((el) => el !== item.dayValue)
                    : [...prev, item.dayValue]
                )
              }
              disabled={selectedDay === item.dayValue}
            />
          </ThemeProvider>
        </div>
      ))}
      <div className={s.applyBlock}>
        <StandartButton
          action={handleCopy}
          buttonTitle={"Apply"}
          height={"35px"}
          width={"100%"}
        />
      </div>
    </div>,
    document.body
  );
};

export default CopyScheduleMenu;
