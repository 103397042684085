import CircleAvatar from "../CircleAvatar/CircleAvatar";
import NavigateToProfile from "../NavigateToProfile/NavigateToProfile";
import s from "./UserAvatarList.module.css";

type UserAvatarListProps = {
  userIds: string[];
  showLimit: number;
  avatarWidth?: string;
  avatarHeight?: string;
  fontSize?: string;
};

const UserAvatarList = ({
  userIds,
  showLimit,
  avatarWidth,
  avatarHeight,
  fontSize,
}: UserAvatarListProps) => {
  return (
    <div className={s.container}>
      {userIds &&
        userIds.map(
          (item, index) =>
            index < showLimit && (
              <NavigateToProfile userId={item} key={index}>
                <div
                  className={index === 0 ? s.firstAvatarBlock : s.avatarBlock}
                >
                  <CircleAvatar
                    userId={item}
                    height={avatarHeight ? avatarHeight : "50px"}
                    width={avatarWidth ? avatarWidth : "50px"}
                    fontSize={fontSize ? fontSize : "24px"}
                    border="2px solid white"
                  />
                </div>
              </NavigateToProfile>
            )
        )}

      {userIds && userIds.length > showLimit && (
        <div className={s.otherElements}>{userIds.length - showLimit}</div>
      )}
    </div>
  );
};

export default UserAvatarList;
