import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import s from "./RadioButtonsGroup.module.css";

type RadioButtonsGroupProps = {
  data: { value: string; lable: string }[];
  defaultValue: string;
  value: "male" | "female" | string;
  setValue: React.Dispatch<React.SetStateAction<"male" | "female" | string>>;
  width?: string;
  color?: string;
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
};

export default function RadioButtonsGroup({
  data,
  defaultValue,
  value,
  setValue,
  width,
  color,
  flexDirection,
}: RadioButtonsGroupProps) {
  return (
    <FormControl style={{ width: "100%" }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={defaultValue}
        name="radio-buttons-group"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      >
        <div
          style={{
            display: "flex",
            width: width ? width : "30%",
            justifyContent: "space-between",
            flexWrap: "wrap",
            flexDirection: flexDirection ? flexDirection : "row",
            marginLeft: "1rem",
          }}
        >
          {data.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.value}
              className={s.formControlLabel}
              control={
                <Radio
                  sx={{
                    color: "black",
                    "&.Mui-checked": {
                      color: color ? color : "#3FB083",
                    },
                  }}
                />
              }
              label={item.lable}
            />
          ))}
        </div>
      </RadioGroup>
    </FormControl>
  );
}
