import SpecialistApi from "../api/specialistApi";
import { TypeSpecialistFilter } from "../types/TypeSpecialist";

export default async function getAvailableParams(
  token: string,
  filters: TypeSpecialistFilter
) {
  return await Promise.all([
    SpecialistApi.getAvailableParams(token, {
      param: "gender",
      filters: filters,
    }),
    /*  SpecialistApi.getAvailableParams(token, {
      param: "city",
      filters: filters,
    }),
    SpecialistApi.getAvailableParams(token, {
      param: "country",
      filters: filters,
    }), */
    SpecialistApi.getAvailableParams(token, {
      param: "mainSpecializations",
      filters: filters,
    }),
    SpecialistApi.getAvailableParams(token, {
      param: "subSpecializations",
      filters: filters,
    }),
    SpecialistApi.getAvailableParams(token, {
      param: "preferredLanguages",
      filters: filters,
    }),
    /*  SpecialistApi.getAvailableParams(token, {
      param: "age",
      filters: filters,
    }),
    SpecialistApi.getAvailableParams(token, {
      param: "price",
      filters: filters,
    }), */
  ]);
}
