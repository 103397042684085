import { useEffect, useState } from "react";

interface TimeLeft {
  hours: number;
  minutes: number;
  seconds: number;
}

export function useCustomTimer(
  creationDate: Date,
  timeLimit: number
): TimeLeft {
  const calculateTimeLeft = (): TimeLeft => {
    const currentTime = new Date(
      new Date().toLocaleString("en-US", { timeZone: "Etc/GMT" })
    ).getTime();
    const expirationTime: number = creationDate.getTime() + timeLimit;
    let timeDiff: number = expirationTime - currentTime;

    if (timeDiff <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    const hours: number = Math.floor(timeDiff / (1000 * 60 * 60));
    timeDiff -= hours * 1000 * 60 * 60;
    const minutes: number = Math.floor(timeDiff / (1000 * 60));
    timeDiff -= minutes * 1000 * 60;
    const seconds: number = Math.floor(timeDiff / 1000);

    return { hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return timeLeft;
}
