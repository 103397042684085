// src/store/InitialCalendarSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dayjs } from "dayjs";

interface InitialCalendarState {
  selectedDate: Dayjs | null;
}

const initialState: InitialCalendarState = {
  selectedDate: null,
};

const initialCalendarSlice = createSlice({
  name: "initialCalendar",
  initialState,
  reducers: {
    setSelectedInitialCalendarDate: (
      state,
      action: PayloadAction<Dayjs | null>
    ) => {
      state.selectedDate = action.payload;
    },
  },
});

export const { setSelectedInitialCalendarDate } = initialCalendarSlice.actions;
export default initialCalendarSlice.reducer;
