import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ProjectApi from "../../api/projectApi";
import CalendarIcon from "../../assets/Header/calendar.svg";
import { ReactComponent as ChevronIcon } from "../../assets/SpecialistSetupInfo/arrow-chevron.svg";
import Loader from "../../components/Loader/Loader";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { MyProjectType } from "../../types/Company";
import { ProjectsAvailableSessionTypes } from "../../types/Projects";
import { formateDate } from "../../utils/general";
import MotionDiv from "./MotionDiv/MotionDiv";
import MyProgramCard from "./MyProgramCard/MyProgramCard";
import s from "./SpecialistMyProgram.module.css";

const connectionString = process.env.REACT_APP_HTTP_CONNECTION_STRING;

const SpecialistMyProgram: React.FC = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { userData } = useContext(ContextProvider);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("projectId");
  const mainRef = useRef<HTMLDivElement | null>(null);
  const [selectProgram, setSelectProgram] = useState<MyProjectType | null>(
    null
  );
  const [projectsData, setProjectsData] = useState<MyProjectType[] | null>(
    null
  );
  const [actionLoader, setActionLoader] = useState({
    joinProjectLoader: false,
    loadProjects: false,
  });
  const [cardWidth, setCardWidth] = useState(0);
  const [needSessionPackage, setNeedSessionPackage] = useState(false);

  useEffect(() => {
    if (
      !selectProgram ||
      selectProgram.availableSessionTypes![0].type !==
        ProjectsAvailableSessionTypes.PACKAGE
    ) {
      setNeedSessionPackage(false);
      return;
    }

    (async () => {
      if (!token || !userData || !userData.specialistIds) return;
      const mySpecialistIds = userData.specialistIds.map((item) => item._id);
      const projectSpecialistsIds = selectProgram.specialists;
      const currentSpecialistId = mySpecialistIds.filter((element) =>
        projectSpecialistsIds.includes(element)
      )[0];
      if (!currentSpecialistId) return;

      const response = await ProjectApi.getMyProjectsWithoutPackage(
        token,
        currentSpecialistId
      );

      if (response.status && response.projects) {
        setNeedSessionPackage(
          response.projects.map((item) => item._id).includes(selectProgram._id)
        );
      }
    })();
  }, [selectProgram]);

  useEffect(() => {
    if (!projectId || !projectsData) return;
    const currentSelectedProject = projectsData.filter(
      (el) => el._id === projectId
    );
    if (currentSelectedProject[0]) {
      setSelectProgram(currentSelectedProject[0]);
    }
  }, [projectId, projectsData]);

  useEffect(() => {
    if (!mainRef.current) return;

    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (window.innerWidth <= 768) {
          setCardWidth(entry.contentRect.width);
        } else {
          const currentCardWidth = entry.contentRect.width / 4;
          setCardWidth(currentCardWidth);
        }
      }
    });

    observer.observe(mainRef.current);

    return () => {
      if (!mainRef.current) return;
      observer.unobserve(mainRef.current);
    };
  }, [mainRef, mainRef.current]);

  useEffect(() => {
    if (!token || !userData) return;
    const makeAsync = async () => {
      setActionLoader((prev) => ({ ...prev, loadProjects: true }));
      const response = await ProjectApi.getUserProjects(
        token,
        userData._id,
        500,
        1
      );

      setActionLoader((prev) => ({ ...prev, loadProjects: false }));
      if (response.status && response.projects) {
        setProjectsData(response.projects);
      }
    };
    makeAsync();
  }, [userData, token]);

  const discountData = {
    [ProjectsAvailableSessionTypes.SESSION as string]: selectProgram
      ? selectProgram.sessionDiscount
      : 0,
    [ProjectsAvailableSessionTypes.PACKAGE as string]: selectProgram
      ? selectProgram.packageDiscount
      : 0,
  };

  if (actionLoader.loadProjects) {
    return (
      <div className={s.loaderContainer} ref={mainRef}>
        <Loader size={100} />
      </div>
    );
  }

  return (
    <div className={s.container} ref={mainRef}>
      <div
        className={`${s.programListBlock} ${!selectProgram ? s.rowList : ""} ${
          selectProgram
            ? s.mobileProgramListBlockHidden
            : s.mobileProgramListBlockVisible
        }`}
      >
        <MotionDiv
          flexDirection={
            window.innerWidth <= 768
              ? "column"
              : selectProgram
              ? "column"
              : "row"
          }
        >
          {projectsData && projectsData.length ? (
            projectsData.map((item, index) => (
              <motion.div
                key={index}
                layout
                transition={{
                  type: "tween",
                  duration: window.innerWidth > 768 ? 1 : 0,
                }}
                onClick={() => setSelectProgram(item)}
                style={{ width: `calc(${cardWidth}px - 12px)` }}
                className={`${s.motionBlock} ${
                  !selectProgram ? s.nonSelectMotionBlock : ""
                }`}
              >
                <MyProgramCard
                  clientIds={item.clients}
                  programStartDate={formateDate(new Date(item.creationDate!))}
                  programTitle={item.title}
                  discount={{
                    packageDiscount: item.packageDiscount,
                    sessionDiscount: item.sessionDiscount,
                  }}
                  projectType={item.availableSessionTypes![0].type}
                  isSelected={item._id === selectProgram?._id}
                />
              </motion.div>
            ))
          ) : (
            <div className={s.noProgramsBlock}>
              <h1>{strings.noProgramAvailable}</h1>
            </div>
          )}
        </MotionDiv>
      </div>

      {selectProgram && (
        <div
          className={
            !selectProgram
              ? `${s.programContentBlock} ${s.mobileProgramInfoBlockHidden}`
              : `${s.programContentBlock} ${s.mobileProgramInfoBlockVisible}`
          }
        >
          <div className={s.programHeaderContent}>
            <div
              className={s.backButton}
              onClick={() => setSelectProgram(null)}
            >
              <ChevronIcon className={s.chevronIcon} />
              <span>{strings.myProgramBackBtn}</span>
            </div>
            <div className={s.dateBlock}>
              <img src={CalendarIcon} alt="" />
              {selectProgram && (
                <span>
                  {formateDate(new Date(selectProgram.creationDate!))}
                </span>
              )}
            </div>
          </div>
          {needSessionPackage && (
            <div
              onClick={() =>
                navigate(
                  `/${userData!.latinName}-${
                    userData!.latinSurname
                  }-${userData!._id.slice(-7)}`,
                  {
                    state: {
                      activeProfileTab: "packages",
                    },
                  }
                )
              }
              className={s.createPackageMessage}
            >
              {strings.mustCreatePackage}
            </div>
          )}
          {selectProgram && (
            <div className={s.programInfoTitleBlock}>
              <div className={s.titleBlock}>
                <h3>{selectProgram.title}</h3>
                {discountData[selectProgram.availableSessionTypes![0].type] ? (
                  <div className={s.priceBlock}>
                    <span>{strings.myProgramDiscountTitle}</span>
                    <div className={s.priceValueBlock}>
                      <span>
                        -{" "}
                        {
                          discountData[
                            selectProgram.availableSessionTypes![0].type
                          ]
                        }
                        %{" "}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>

              <p>{selectProgram.description}</p>
              {selectProgram.video && (
                <video
                  src={`${connectionString}projects/getProjectVideoStream/${selectProgram.video}`}
                  className={s.video}
                  controls
                ></video>
              )}
            </div>
          )}

          {/*  <div className={s.programSpecialistListBlock}>
            <div className={s.specalistCardsHeader}>
              <span>{strings.specTableHeadLableName}</span>
              <span>{strings.userTableHeadLableAge}</span>
              <span className={s.genderLable}>
                {strings.userTableHeadLableGender}
              </span>
              <span>{strings.userTableHeadLableLocation}</span>
            </div>
            {selectProgram &&
              selectProgram.clients.map((item, index) => (
                <UsersProgramCard
                  bgColor={index % 2 !== 0 ? "#F5F5F5" : "#FFFFFF"}
                  userId={item}
                  key={index}
                />
              ))}
          </div> */}
        </div>
      )}
    </div>
  );
};

export default SpecialistMyProgram;
