import * as XLSX from "xlsx";

const getTxtFileContent = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const fileType = file.type;
      const fileContent = reader.result as string;

      if (fileType === "text/plain") {
        resolve(fileContent);
      } else if (fileType === "text/csv") {
        const lines = fileContent!.split("\r");
        lines.pop();
        const firstColumn = lines.join("");
        resolve(firstColumn);
      } else if (fileType.match("application/vnd")) {
        //@ts-ignore
        const workbook = XLSX.read(new Uint8Array(fileContent), {
          type: "array",
        });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const range = XLSX.utils.decode_range(worksheet["!ref"]!);
        const firstColumn = [];
        for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
          const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: 0 });
          const cell = worksheet[cellAddress];
          if (cell && cell.t === "s") {
            firstColumn.push(cell.v);
          }
        }
        resolve(firstColumn.join("\n"));
      } else {
        reject(new Error("Unsupported file type."));
      }
    };
    reader.onerror = (error) => reject(error);
    file.type.match("application/vnd")
      ? reader.readAsArrayBuffer(file)
      : reader.readAsText(file);
  });

export default getTxtFileContent;
