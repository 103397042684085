import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { ReactElement } from "react";

type CustomTooltipProps = {
  title: string;
  children: ReactElement;
  hideTooltip?: boolean;
};

const CustomTooltip = ({
  title,
  children,
  hideTooltip = false,
}: CustomTooltipProps) => {
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      arrow
      disableHoverListener={hideTooltip}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 400,
      fontSize: theme.typography.pxToRem(14),
      borderRadius: "10px",
      filter: "drop-shadow(0 0 5px rgba(0, 0, 0, 0.5))",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "white",
    },
  }));

  return <HtmlTooltip title={<div>{title}</div>}>{children}</HtmlTooltip>;
};

export default CustomTooltip;
