import Compressor from "compressorjs";

export const compressFiles = (
  file: File,
  setLoader?: React.Dispatch<React.SetStateAction<boolean>>
): Promise<{ file?: File; error?: string }> => {
  return new Promise((resolve) => {
    setLoader && setLoader(true);

    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const compressedFile = new File([result], file.name, {
          type: result.type,
          lastModified: Date.now(),
        });

        setLoader && setLoader(false);
        resolve({ file: compressedFile });
      },
      error(err) {
        setLoader && setLoader(false);
        resolve({ error: err.message });
      },
    });
  });
};
