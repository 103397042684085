import CalendarIcon from "../../../assets/Header/calendar.svg";
import UserAvatarList from "../../../components/UserAvatarList/UserAvatarList";
import strings from "../../../localization";
import { ProjectsAvailableSessionTypes } from "../../../types/Projects";
import s from "./MyProgramCard.module.css";

type MyProgramCardProps = {
  clientIds: string[];
  programTitle: string;
  programStartDate: string;
  isSelected: boolean;
  discount: {
    sessionDiscount: number;
    packageDiscount: number;
  };
  projectType: ProjectsAvailableSessionTypes;
};

const MyProgramCard = ({
  clientIds,
  programTitle,
  programStartDate,
  discount,
  isSelected,
  projectType,
}: MyProgramCardProps) => {
  const discountData = {
    [ProjectsAvailableSessionTypes.SESSION as string]: discount.sessionDiscount,
    [ProjectsAvailableSessionTypes.PACKAGE as string]: discount.packageDiscount,
  };

  return (
    <div className={`${s.container} ${isSelected ? s.slectedCard : ""}`}>
      <div className={s.userListBlock}>
        <UserAvatarList
          userIds={clientIds}
          showLimit={4}
          avatarHeight="30px"
          avatarWidth="30px"
          fontSize="14px"
        />
      </div>
      <div className={s.programInfoBlock}>
        <span className={s.programTitle}>{programTitle}</span>
        {discountData[projectType] > 0 && (
          <div className={s.priceBlock}>
            <span>{strings.myProgramDiscountTitle}</span>
            <span>-{discountData[projectType]}% </span>
          </div>
        )}
      </div>
      <div className={s.joinBlock}>
        <div className={s.dateBlock}>
          <img src={CalendarIcon} alt="" />
          <span>{programStartDate}</span>
        </div>
      </div>
    </div>
  );
};

export default MyProgramCard;
