import { Skeleton } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import UsersApi from "../../api/usersApi";
import { ContextProvider } from "../../contextProvider";
import { getTranslation } from "../../utils/getTranslation";
import ModalWindow from "../ModalWindow/ModalWindow";
import s from "./CircleAvatar.module.css";

type CircleAvatarProps = {
  userId?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  marginRight?: string;
  name?: string;
  surname?: string;
  avatarProps?: string;
  onClick?: (arg?: any) => void;
  border?: string;
  isModalAvatar?: boolean;
  borderRadius?: string;
  hideNoAvtaarBlock?: boolean;
  isPubcicData?: boolean;
};

const CircleAvatar = ({
  userId,
  width,
  height,
  fontSize,
  name,
  surname,
  marginRight,
  avatarProps,
  onClick,
  border,
  isModalAvatar,
  borderRadius,
  hideNoAvtaarBlock,
  isPubcicData = true,
}: CircleAvatarProps) => {
  const { userData } = useContext(ContextProvider);
  const token = localStorage.getItem("token");
  const [avatar, setAvatar] = useState<string | undefined>();
  const [isLoader, setIsLoader] = useState(true);
  const [nameSurname, setNameSurname] = useState({ name: "", surname: "" });
  const [modalAvatar, setModalAvatar] = useState(false);

  const cache = new Map();

  useEffect(() => {
    const makeAsync = async () => {
      if (!userId) return;

      // Проверяем кэш
      if (cache.has(userId)) {
        const cachedData = cache.get(userId);
        setAvatar(cachedData.avatar);
        setNameSurname(cachedData.nameSurname);
        return;
      }

      try {
        const [responseNotPublic, responsePublic] = await Promise.all([
          token && UsersApi.getUserAvatarAndName(token!, userId),
          UsersApi.getUserPublicAvatarAndName(userId),
        ]);

        const response = isPubcicData ? responsePublic : responseNotPublic;

        if (response && response.name && response.surname) {
          const nameSurnameData = {
            name: getTranslation(response.name, userData?.selectedLanguage),
            surname: getTranslation(
              response.surname,
              userData?.selectedLanguage
            ),
          };
          setNameSurname(nameSurnameData);

          // Сохраняем в кэш
          cache.set(userId, {
            avatar: response.avatar,
            nameSurname: nameSurnameData,
          });
        }
        if (response && !response.avatar) {
          setIsLoader(false);
          setAvatar(undefined);
        }

        if (response && response.avatar) {
          setAvatar(response.avatar);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
      }
    };

    makeAsync();
  }, [userId]);

  return (
    <div
      className={s.container}
      onClick={
        onClick
          ? onClick
          : () => {
              return;
            }
      }
    >
      {avatar || avatarProps ? (
        <img
          onClick={
            isModalAvatar
              ? () => setModalAvatar((prev) => !prev)
              : () => {
                  return;
                }
          }
          src={avatarProps ? avatarProps : avatar}
          onLoad={() => setIsLoader(false)}
          onLoadedMetadata={() => console.log("onLoadedMetadata")}
          onLoadedDataCapture={() => console.log("onLoadedDataCapture")}
          onLoadedMetadataCapture={() => console.log("onLoadedMetadataCapture")}
          alt=""
          className={s.avatar}
          style={{
            width: width ? width : "50px",
            height: height ? height : "50px",
            marginRight: marginRight ? marginRight : "10px",
            border: border ?? "none",
            borderRadius: borderRadius ?? "50%",
            aspectRatio: 1 / 1,
            opacity: isLoader ? 0 : 1,
          }}
        />
      ) : (
        !hideNoAvtaarBlock && (
          <span
            className={s.emptyAvatarBlock}
            style={{
              width: width ? width : "50px",
              height: height ? height : "50px",
              fontSize: fontSize ? fontSize : "24px",
              marginRight: marginRight ? marginRight : "10px",
              border: border ?? "none",
              borderRadius: borderRadius ?? "50%",
              aspectRatio: 1 / 1,
              opacity: isLoader ? 0 : 1,
            }}
          >
            {nameSurname.name ? nameSurname.name[0] : name ? name[0] : ""}
            {nameSurname.surname
              ? nameSurname.surname[0]
              : surname
              ? surname[0]
              : ""}
          </span>
        )
      )}
      {isLoader && !avatarProps && (
        <Skeleton
          height={height ?? "50px"}
          width={width ?? "50px"}
          variant="circular"
          animation="wave"
          style={{
            marginRight: marginRight ? marginRight : "10px",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 999,
          }}
        />
      )}
      <ModalWindow
        isOpen={modalAvatar}
        setIsOpen={setModalAvatar}
        maxWidth="fit-content"
        bgColor="transparent"
        showCloseBtn={false}
      >
        <img
          src={avatarProps ? avatarProps : avatar}
          alt=""
          className={s.modalAvatar}
        />
      </ModalWindow>
    </div>
  );
};

export default CircleAvatar;
