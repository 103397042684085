export type TypeNewTodoList = {
  createdBy: string;
  for: string;
  title: string;
  date?: Date;
};

export type TypeNewTodo = {
  createdBy: string;
  for: string;
  status?: TodoStatus;
  title: string;
  description: string;
  date: Date;
};
export enum TodoStatus {
  NEW = "new",
  IN_PROGRESS = "in progress",
  URGENT = "urgent",
  COMPLETED = "completed",
}

export type TodoList = {
  _id: string;
  createdBy: {
    _id: string;
    name: { text: string; language: string }[];
    surname: { text: string; language: string }[];
  };
  for: {
    _id: string;
    name: { text: string; language: string }[];
    surname: { text: string; language: string }[];
  };
  title: string;
  todos: Todo[];
  date?: string;
};

export type Todo = {
  _id: string;
  createdBy: { name: string; surname: string; avatar: string; _id: string };
  for: string;
  status: { status: TodoStatus; labels: { text: string; language: string } };
  title: string;
  description: string;
  date: Date;
};
