import { useContext } from "react";
import UsersApi from "../../api/usersApi";
import { ContextProvider } from "../../contextProvider";
import s from "./HeaderChangeLangs.module.css";

const HeaderChangeLangs = () => {
  const token = localStorage.getItem("token");
  const { userData, setUserData, setSelectLang } = useContext(ContextProvider);

  const changeLangHandler = async (value: string) => {
    if (!token || !userData) return;
    const resposne = await UsersApi.updateUserData(token, {
      _id: userData._id,
      selectedLanguage: value,
    });

    if (resposne.status) {
      setUserData((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          selectedLanguage: value,
        };
      });
      setSelectLang(value);
    }
  };
  return (
    <div className={s.langSelectBlock}>
      <button
        onClick={() => changeLangHandler("en")}
        className={
          userData?.selectedLanguage === "en" ? s.selectLang : s.unselectLang
        }
      >
        EN
      </button>
      <button
        onClick={() => changeLangHandler("uk")}
        className={
          userData?.selectedLanguage === "uk" ? s.selectLang : s.unselectLang
        }
      >
        УКР
      </button>
    </div>
  );
};

export default HeaderChangeLangs;
