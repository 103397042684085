import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import StandartButton from "../../../components/StandartButton/StandartButton";

import AnimateHeight from "react-animate-height";
import SpecialistApi from "../../../api/specialistApi";
import UsersApi from "../../../api/usersApi";
import CancelIcon from "../../../assets/SpecialistSetupInfo/cancel.svg";
import ExcludeIcon from "../../../assets/SpecialistSetupInfo/Exclude.svg";
import PlusIcon2 from "../../../assets/SpecialistSetupInfo/plus.svg";
import PlusIcon from "../../../assets/SpecialistSetupInfo/plusIcon.svg";
import { ContextProvider } from "../../../contextProvider";
import Notify from "../../../utils/toaster";
import s from "./Price.module.css";
import WorkTimeInput from "./WorkTimeInput/WorkTimeInput";

import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import Input from "../../../components/Input/Input";
import MultipleSelect from "../../../components/MultipleSelect/MultipleSelect";
import strings from "../../../localization";
import { transformSchedule } from "../../../utils/shedule";

const labelStyle = "original";

type TypePticeProps = {
  subStep: { current: number; total: number };
  setSubStep: Dispatch<SetStateAction<{ current: number; total: number }>>;
};

const Price = ({ subStep, setSubStep }: TypePticeProps) => {
  const { userData, setIsSetupProfileInfo } = useContext(ContextProvider);
  const [timezones, setTimezones] = useState(allTimezones);
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  const [timeZone, setTimeZone] = useState<any>(
    options.find(
      (el) =>
        el.offset ===
        parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone).offset
    )?.value
  );
  const [inputValues, setInputValues] = useState({
    price: "",
    discountOne: "",
    discountTwo: "",
    discountThree: "",
  });
  const [daysState, setDaysState] = useState([
    {
      dayValue: "Monday",
      daytitle: strings.scheduleMonday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
    {
      dayValue: "Tuesday",
      daytitle: strings.scheduleTuesday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
    {
      dayValue: "Wednesday",
      daytitle: strings.scheduleWednesday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
    {
      dayValue: "Thursday",
      daytitle: strings.scheduleThursday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
    {
      dayValue: "Friday",
      daytitle: strings.scheduleFriday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
    {
      dayValue: "Saturday",
      daytitle: strings.scheduleSaturday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
    {
      dayValue: "Sunday",
      daytitle: strings.scheduleSunday,
      isOpen: false,
      workTime: [{ from: "", to: "" }],
      switch: true,
    },
  ]);

  const getUserGeoInfo = async () => {
    const response = await UsersApi.getUserGeoInfo();
    if (response.status && response.geo) {
      const userTimeZone = response.geo.timezone;
      if (!allTimezones[userTimeZone]) {
        setTimezones((prevTimezones) => ({
          ...prevTimezones,
          [userTimeZone]: userTimeZone,
        }));
      }
      setTimeZone(response.geo.timezone);
    }
  };

  useEffect(() => {
    getUserGeoInfo();
  }, []);

  const handleInputChange = (
    value: string,
    inputName: string,
    maxValue?: number
  ) => {
    if (/^\d*$/.test(value)) {
      if (maxValue !== undefined) {
        value =
          value !== ""
            ? Math.min(parseInt(value, 10), maxValue).toString()
            : "";
      }

      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [inputName]: value,
      }));
    }
  };

  const isWorkTimeSpecified = () => {
    return daysState.some((day) => {
      return day.workTime.some((time) => time.from !== "" && time.to !== "");
    });
  };

  const nextStep = async () => {
    const token = localStorage.getItem("token");

    if (
      token &&
      userData?.specialistIds &&
      (userData?.specialistIds ||
        (userData?.specialistIds && userData?.specialistIds[0]._id))
    ) {
      const specialistId = userData?.specialistIds[0]._id
        ? userData?.specialistIds[0]._id
        : userData.specialistIds.toString();
      if (subStep.current === 1) {
        if (inputValues.price === "") return Notify(strings.specifyPrice);
        await SpecialistApi.update(
          {
            _id: specialistId,
            price: Number(inputValues.price),
          },
          token
        );
        setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
      } else if (subStep.current === 2) {
        if (!isWorkTimeSpecified()) return Notify(strings.indicateSchedule);

        const scheduleData = transformSchedule(daysState);

        const response = await UsersApi.updateUserData(token, {
          _id: userData?._id,
          timezone: timeZone,
        });
        const scheduleResponse = await SpecialistApi.update(
          {
            _id: specialistId,
            schedule: scheduleData,
          },
          token
        );
        if (
          (!response.status && response.message) ||
          (!scheduleResponse.status && scheduleResponse.message)
        ) {
          Notify(response.message ?? scheduleResponse.message ?? "");
        }
        if (response.status && scheduleResponse.status) {
          const registerResponse = await UsersApi.updateUserData(token, {
            _id: userData._id,
            registerFinished: true,
          });
          if (registerResponse.status) {
            setIsSetupProfileInfo(true);
          }
        }
      }
    }
  };

  const updateWorkTime = (index: number) => {
    setDaysState((prev) => {
      const updatedState = [...prev];
      updatedState[index] = {
        ...prev[index],
        isOpen: !prev[index].isOpen,
      };
      return updatedState;
    });
  };
  const handleCancelIconClick = (dayIndex: number, timeIndex: number) => {
    setDaysState((prev) => {
      const updatedState = [...prev];
      const day = { ...updatedState[dayIndex] };
      day.workTime = day.workTime.filter((_, i) => i !== timeIndex);
      updatedState[dayIndex] = day;

      return updatedState;
    });
  };
  const handleTimeZoneChange = (value: any) => {
    setTimeZone(value);
  };
  return (
    <div className={s.container}>
      {subStep.current === 1 && (
        <div className={s.subStepBlock}>
          <p className={s.stepTitle}>{strings.priceSetupTitle}</p>
          <p className={s.substepText}>
            {strings.priceSetupDescription + " " + strings.sessionCommission}
          </p>
          <div className={s.priceInputBlock}>
            <Input
              inputValue={inputValues.price}
              onChangeInput={(value) => handleInputChange(value, "price")}
              isVisible
              required
            />
            <span className={s.inputDollarUnit}>$</span>
          </div>
        </div>
      )}
      {subStep.current === 2 && (
        <div className={s.subStepBlock}>
          <div className={s.timezoneBlock}>
            <h3>{strings.timezoneSetupTitle}</h3>
            <MultipleSelect
              data={options.map((option) => ({
                value: option.value,
                lable: option.label,
              }))}
              multiplie={false}
              setValue={handleTimeZoneChange}
              value={timeZone}
              placeholder={strings.timeZonePlaceHolder}
            />
          </div>
          <h3>{strings.scheduleSetupTitle}</h3>
          <p>{strings.scheduleDescription}</p>
          {Array.isArray(daysState) &&
            daysState.map((item, index) => (
              <div className={s.timeWorkPickBlock} key={item.daytitle}>
                <div className={s.dayTitleBlock}>
                  <p>{item.daytitle}</p>
                  {!daysState[index].isOpen && (
                    <img
                      src={PlusIcon}
                      alt=""
                      onClick={() =>
                        setDaysState((prev) => {
                          const updatedState = [...prev];
                          updatedState[index] = {
                            ...prev[index],
                            isOpen: !prev[index].isOpen,
                          };
                          return updatedState;
                        })
                      }
                    />
                  )}
                </div>
                <AnimateHeight
                  id="example-panel"
                  duration={500}
                  height={daysState[index].isOpen ? "auto" : 0}
                >
                  <div className={s.worktimeInputsBlock}>
                    <div className={s.inputs}>
                      {daysState[index].workTime.map((_, timeIndex) => (
                        <div className={s.inputElementBlock} key={timeIndex}>
                          <WorkTimeInput
                            inputsValue={daysState}
                            mainIndex={index}
                            timeIndex={timeIndex}
                            setInputsValue={setDaysState}
                            isForProfile
                            height="42px"
                          />
                          <img
                            src={CancelIcon}
                            alt=""
                            className={s.cancelIcon}
                            onClick={() =>
                              handleCancelIconClick(index, timeIndex)
                            }
                          />
                        </div>
                      ))}
                      <div
                        className={s.plusButton}
                        role="button"
                        onClick={() =>
                          setDaysState((prev) => {
                            const updatedState = [...prev];
                            updatedState[index].workTime = [
                              ...updatedState[index].workTime,
                              { from: "", to: "" }, // Изменения здесь
                            ];
                            return updatedState;
                          })
                        }
                      >
                        <img src={PlusIcon2} alt="" />
                      </div>
                    </div>
                    <img
                      src={ExcludeIcon}
                      alt=""
                      onClick={() => updateWorkTime(index)}
                    />
                  </div>
                </AnimateHeight>
              </div>
            ))}
        </div>
      )}
      <div className={s.continueButtonBlock}>
        <StandartButton buttonTitle="Continue" action={nextStep} width="100%" />
      </div>
    </div>
  );
};

export default Price;
