import { Dispatch, SetStateAction } from "react";
import MuiSwitch from "../../../../../components/MuiSwitch/MuiSwitch";
import MultipleSelect from "../../../../../components/MultipleSelect/MultipleSelect";
import strings from "../../../../../localization";
import { TypeUpdatedUserInfo } from "../../../../../types/TypeUsers";
import { NewSpeciality } from "../../../../AddNewSpecialization/AddNewSpecialization";
import s from "./SheduleSettings.module.css";

type SheduleSettingsProps = {
  setSpecialistUpdateData?: Dispatch<SetStateAction<TypeUpdatedUserInfo>>;
  setNewSpecialistData?: Dispatch<SetStateAction<NewSpeciality>>;
  specialistUpdateData: TypeUpdatedUserInfo | NewSpeciality;
  realTimeSessionsChecked?: boolean | null;
  setRealTimeSessionsChecked?: Dispatch<SetStateAction<boolean | null>>;
  scheduleSettingsData: {
    bufferTime?: { before: number | undefined; after: number | undefined };
    minimumBookingsGap?: number;
    bookingTimeStep?: number;
  };
};

const SheduleSettings = ({
  setSpecialistUpdateData,
  specialistUpdateData,
  realTimeSessionsChecked,
  setRealTimeSessionsChecked,
  scheduleSettingsData,
  setNewSpecialistData,
}: SheduleSettingsProps) => {
  const bufferData = [
    {
      value: "0",
      lable: "0 " + strings.scheduleMinutes,
    },
    {
      value: "5",
      lable: "5 " + strings.scheduleMinutes,
    },
    {
      value: "10",
      lable: "10 " + strings.scheduleMinutes,
    },
    {
      value: "15",
      lable: "15 " + strings.scheduleMinutes,
    },
    {
      value: "30",
      lable: "30 " + strings.scheduleMinutes,
    },
  ];
  const bookingGapData = [
    {
      value: "15",
      lable: "15 " + strings.scheduleMinutes,
    },
    {
      value: "30",
      lable: "30 " + strings.scheduleMinutes,
    },
    {
      value: "45",
      lable: "45 " + strings.scheduleMinutes,
    },
    {
      value: "60",
      lable: "60 " + strings.scheduleMinutes,
    },
  ];
  const minimumBookingsGapData = [
    {
      value: "6",
      lable: "6 " + strings.scheduleHours,
    },
    {
      value: "12",
      lable: "12 " + strings.scheduleHours,
    },
    {
      value: "24",
      lable: "24 " + strings.scheduleHours,
    },
    {
      value: "48",
      lable: "2 " + strings.scheduleDays,
    },
    {
      value: "72",
      lable: "3 " + strings.scheduleDays,
    },
  ];

  return (
    <div className={s.container}>
      <div className={s.bufferBlock}>
        <span className={s.blockTitle}>{strings.bufferTimeTitle}</span>
        <span className={s.blockDescription}>
          {strings.bufferTimeDescription}
        </span>
        <div style={{ width: "fit-content" }}>
          <div className={s.bufferinputBlock} style={{ marginBottom: "10px" }}>
            <span>{strings.beforSession}</span>
            <MultipleSelect
              data={bufferData}
              multiplie={false}
              setValue={(value) => {
                if (setNewSpecialistData) {
                  setNewSpecialistData((prev) => ({
                    ...prev,
                    bufferTime: prev.bufferTime
                      ? { ...prev.bufferTime, before: Number(value) }
                      : {
                          after: scheduleSettingsData.bufferTime?.after,
                          before: Number(value),
                        },
                  }));
                } else if (setSpecialistUpdateData) {
                  setSpecialistUpdateData((prev) => ({
                    ...prev,
                    bufferTime: prev.bufferTime
                      ? { ...prev.bufferTime, before: Number(value) }
                      : {
                          after: scheduleSettingsData.bufferTime?.after,
                          before: Number(value),
                        },
                  }));
                }
              }}
              value={
                specialistUpdateData.bufferTime
                  ? specialistUpdateData.bufferTime.before
                  : scheduleSettingsData.bufferTime?.before
              }
              padding="10px 20px"
              width="200px"
            />
          </div>
          <div className={s.bufferinputBlock}>
            <span>{strings.afterSession}</span>
            <MultipleSelect
              data={bufferData}
              multiplie={false}
              setValue={(value) => {
                if (setNewSpecialistData) {
                  setNewSpecialistData((prev) => ({
                    ...prev,
                    bufferTime: prev.bufferTime
                      ? { ...prev.bufferTime, after: Number(value) }
                      : {
                          before: scheduleSettingsData.bufferTime?.before,
                          after: Number(value),
                        },
                  }));
                } else if (setSpecialistUpdateData) {
                  setSpecialistUpdateData((prev) => ({
                    ...prev,
                    bufferTime: prev.bufferTime
                      ? { ...prev.bufferTime, after: Number(value) }
                      : {
                          before: scheduleSettingsData.bufferTime?.before,
                          after: Number(value),
                        },
                  }));
                }
              }}
              value={
                specialistUpdateData.bufferTime
                  ? specialistUpdateData.bufferTime.after
                  : scheduleSettingsData.bufferTime?.after
              }
              padding="10px 20px"
              width="200px"
            />
          </div>
        </div>
      </div>
      <div className={s.bookingStepBlock}>
        <span className={s.blockTitle}>{strings.minimumBookingsGap}</span>
        <span className={s.blockDescription}>
          {strings.minimumBookingsGapDescription}
        </span>
        <MultipleSelect
          data={minimumBookingsGapData}
          multiplie={false}
          setValue={(value) => {
            if (setNewSpecialistData) {
              setNewSpecialistData((prev) => ({
                ...prev,
                minimumBookingsGap: Number(value),
              }));
            } else if (setSpecialistUpdateData) {
              setSpecialistUpdateData((prev) => ({
                ...prev,
                minimumBookingsGap: Number(value),
              }));
            }
          }}
          value={
            specialistUpdateData.minimumBookingsGap ??
            scheduleSettingsData.minimumBookingsGap
          }
          padding="10px 20px"
          width="200px"
        />
      </div>
      <div className={s.bookingLeadTimeBlock}>
        <span className={s.blockTitle}>{strings.timesSlotsIntervalsTitle}</span>
        <span className={s.blockDescription}>
          {strings.timesSlotsIntervalsDescription}
        </span>
        <MultipleSelect
          data={bookingGapData}
          multiplie={false}
          setValue={(value) => {
            if (setNewSpecialistData) {
              setNewSpecialistData((prev) => ({
                ...prev,
                bookingTimeStep: Number(value),
              }));
            } else if (setSpecialistUpdateData) {
              setSpecialistUpdateData((prev) => ({
                ...prev,
                bookingTimeStep: Number(value),
              }));
            }
          }}
          value={
            specialistUpdateData.bookingTimeStep ??
            scheduleSettingsData.bookingTimeStep
          }
          padding="10px 20px"
          width="200px"
        />
      </div>
      {setRealTimeSessionsChecked && (
        <div className={s.switchBlock}>
          <span className={s.blockTitle}>
            {strings.profilerealTimeSessionSwitch}
          </span>
          <span className={s.blockDescription}>
            {strings.sessionNowDescription}
          </span>
          <MuiSwitch
            lable=""
            checked={
              realTimeSessionsChecked !== undefined &&
              realTimeSessionsChecked !== null
                ? realTimeSessionsChecked
                : false
            }
            setChecked={setRealTimeSessionsChecked}
          />
        </div>
      )}
    </div>
  );
};

export default SheduleSettings;
