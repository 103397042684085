import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";

import EmptyStarIcon from "../../assets/VideoSession/empty-star.svg";
import StarIcon from "../../assets/VideoSession/star.svg";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

type CustomRatingProps = {
  value: number;
  onChangeValue: (value: number) => void;
};

export default function CustomRating({
  value,
  onChangeValue,
}: CustomRatingProps) {
  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
      }}
    >
      <StyledRating
        name="customized-color"
        defaultValue={0}
        getLabelText={(value: number) =>
          `${value} Heart${value !== 1 ? "s" : ""}`
        }
        value={value}
        onChange={(_, newValue) => onChangeValue(newValue ? newValue : value)}
        precision={1}
        icon={
          <img
            src={StarIcon}
            alt="star"
            style={{ width: "40px", height: "40px", marginLeft: "15px" }}
          />
        }
        emptyIcon={
          <img
            src={EmptyStarIcon}
            alt="star"
            style={{ width: "40px", height: "40px", marginLeft: "15px" }}
          />
        }
      />
    </Box>
  );
}
