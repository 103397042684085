export const imageExtensions = [
  ".png",
  ".jpg",
  ".jpeg",
  ".bmp",
  ".webp",
  ".tiff",
];
export const videoExtensions = [
  ".webm",
  ".avi",
  ".wmv",
  ".mp4",
  ".mkv",
  ".mov",
];
export const documentExtensions = [".pdf"];
