import { Skeleton } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { ReactComponent as ChevronIcon } from "../../../assets/Social/chevron.svg";
import { ReactComponent as CloseIcon } from "../../../assets/Social/close.svg";
import ModalWindow from "../../ModalWindow/ModalWindow";
import s from "./FileGrid.module.css";

type FileGridProps = {
  images: File[] | string[];
  setCommentsModalVisible?: Dispatch<SetStateAction<boolean>>;
  setSeletedImgIndex?: Dispatch<SetStateAction<number>>;
};

function FileGrid({
  images,
  setCommentsModalVisible,
  setSeletedImgIndex,
}: FileGridProps) {
  const fileCount = images.length;
  const [previewImagesModal, setPreviewImagesModal] = useState(false);
  const [selectPreviewImageIndex, setSelectPreviewImageIndex] = useState(0);
  const [imageLoader, setImageLoader] = useState({
    firstImg: true,
    secondImg: true,
    thirdImg: true,
  });

  const getImageSrc = (image: File | string) => {
    if (!image) return;
    return typeof image === "string" ? image : URL.createObjectURL(image);
  };

  const handleImageClick = (index: number) => {
    if (setSeletedImgIndex) {
      setSeletedImgIndex(index);
    }
    if (setCommentsModalVisible) {
      setCommentsModalVisible(true);
    }
  };

  if (images.length === 1) {
    return (
      <div
        style={{ width: "100%", position: "relative", cursor: "pointer" }}
        onClick={() => handleImageClick(0)}
      >
        <img
          src={getImageSrc(images[0])}
          alt={""}
          className={s.imgFile}
          onLoad={() =>
            setImageLoader({
              firstImg: false,
              secondImg: false,
              thirdImg: false,
            })
          }
        />
        {imageLoader.firstImg && (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={200}
            className={s.skeleton}
          />
        )}
      </div>
    );
  }

  return (
    <div className={s.multiFilesBlock}>
      <div
        style={{ width: "33%", position: "relative", cursor: "pointer" }}
        onClick={() => handleImageClick(0)}
      >
        <img
          src={getImageSrc(images[0])}
          alt={""}
          className={s.imgFile}
          onLoad={() => {
            setImageLoader((prev) => ({ ...prev, firstImg: false }));
          }}
        />
        {imageLoader.firstImg && (
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={200}
            className={s.skeleton}
          />
        )}
      </div>
      <div className={s.columnFilesBlock}>
        <div
          style={{ height: "100px", position: "relative", cursor: "pointer" }}
          onClick={() => handleImageClick(1)}
        >
          <img
            src={getImageSrc(images[1])}
            alt={""}
            className={images[2] ? s.imgFileSmall : s.imgFile}
            onLoad={() =>
              setImageLoader((prev) => ({ ...prev, secondImg: false }))
            }
          />
          {imageLoader.secondImg && (
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={100}
              className={s.skeleton}
            />
          )}
        </div>
        {images[2] && (
          <div className={s.thirdFileBlock} onClick={() => handleImageClick(2)}>
            <img
              src={getImageSrc(images[2])}
              alt={""}
              className={s.imgFileSmall}
              onLoad={() =>
                setImageLoader((prev) => ({ ...prev, thirdImg: false }))
              }
            />

            {fileCount > 3 && (
              <div className={s.blurAllFilesBlock}>+{fileCount - 3}</div>
            )}
            {imageLoader.thirdImg && (
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={100}
                className={s.skeleton}
              />
            )}
          </div>
        )}
      </div>
      <ModalWindow
        isOpen={previewImagesModal}
        setIsOpen={setPreviewImagesModal}
        maxWidth="80vw"
        width="auto"
        height="80vh"
        bgColor="transparent"
      >
        <div className={s.previewModalImageBlock}>
          <div className={s.selectSlideBlock}>
            <ChevronIcon
              className={
                selectPreviewImageIndex === 0
                  ? s.notActiveChevronLeft
                  : s.chevronLeft
              }
              onClick={() =>
                setSelectPreviewImageIndex((prev) =>
                  prev > 0 ? prev - 1 : prev
                )
              }
            />
            {images.map((item, index) => (
              <img
                src={getImageSrc(item)}
                alt=""
                key={index}
                className={
                  selectPreviewImageIndex === index
                    ? s.previewModalImage
                    : s.previewModalImageHidden
                }
              />
            ))}
            <div className={s.rightActionSwiperBlock}>
              <CloseIcon
                className={s.closeModalIcon}
                onClick={() => setPreviewImagesModal(false)}
              />
              <ChevronIcon
                className={
                  selectPreviewImageIndex === images.length - 1
                    ? s.notSelesctedRightCvhevron
                    : s.chevronRight
                }
                onClick={() =>
                  setSelectPreviewImageIndex((prev) =>
                    prev < images.length - 1 ? prev + 1 : prev
                  )
                }
              />
              <div></div>
            </div>
          </div>
          <div className={s.thumbleBlock}>
            {images.map((item, index) => (
              <img
                src={getImageSrc(item)}
                alt=""
                key={index}
                style={{ width: `calc(80vw / ${images.length})` }}
                className={`${s.thumbleImages} ${
                  selectPreviewImageIndex !== index
                    ? s.notSelecthumb
                    : s.selectThumb
                }`}
                onClick={() => setSelectPreviewImageIndex(index)}
              />
            ))}
          </div>
        </div>
      </ModalWindow>
    </div>
  );
}

export default FileGrid;
