import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { useState } from "react";
import "./PhoneNumberInput.css";

type PhoneNumberInputProps = {
  value: string;
  onChangeFunction: (value: string) => void;
};

const PhoneNumberInput = ({
  value,
  onChangeFunction,
}: PhoneNumberInputProps) => {
  const [isValid, setIsValid] = useState(false);

  const handleChange = (newValue: string) => {
    setIsValid(matchIsValidTel(newValue));
    onChangeFunction(newValue);
  };

  return (
    <MuiTelInput
      value={value}
      onChange={handleChange}
      placeholder="+380 11 111 1111"
      style={{
        border: !isValid && value.length > 1 ? "1px solid red" : "none",
      }}
      excludedCountries={["RU"]}
    />
  );
};

export default PhoneNumberInput;
