import { ReactComponent as SpecialistIcon } from "../assets/Company/magnifier.svg";
import { ReactComponent as CompanyIcon } from "../assets/SideBar/company-icon.svg";
import { ReactComponent as HomeIcon } from "../assets/SideBar/home.svg";
import { ReactComponent as MyProgramsIcon } from "../assets/SideBar/my-programs.svg";
import { ReactComponent as NotesIcon } from "../assets/SideBar/notes.svg";
import { ReactComponent as TodoIcon } from "../assets/SideBar/todo.svg";
import strings from "../localization";

export type TypeMenuItem = {
  pathname: string;
  dataTestId: string;
  icon: any;
  title: string;
  submenu: boolean;
  hidden?: boolean;
  strokeColor?: boolean;
  submenuItems?: {
    title: string;
    path: string;
  }[];
};

export const specialistSideBarMenu: TypeMenuItem[] = [
  {
    pathname: "/home",
    dataTestId: "home-link",
    icon: HomeIcon,
    title: strings.homeMenuTitle,
    submenu: false,
  },

  {
    pathname: "/todo",
    dataTestId: "sessions-link",
    icon: TodoIcon,
    title: strings.todoListMenuTitle,
    submenu: false,
  },
  {
    pathname: "/notes",
    dataTestId: "sessions-link",
    icon: NotesIcon,
    title: strings.notesMenuTitle,
    submenu: false,
  },
  {
    pathname: "/",
    dataTestId: "sessions-link",
    icon: CompanyIcon,
    title: strings.companyMenuTitle,
    submenu: true,
    submenuItems: [
      { title: strings.dashboardMenuTitle, path: "/company-dashboard" },
      { title: strings.programsMenuTitle, path: "/company-programs" },
      { title: strings.employersMenuTitle, path: "/company-employers" },
    ],
  },
];

export const sidebarMenu = [
  {
    pathname: "/home",
    dataTestId: "home-link",
    icon: HomeIcon,
    title: strings.homeMenuTitle,
    submenu: false,
  },
  {
    pathname: "/specialist-find",
    dataTestId: "home-link",
    icon: SpecialistIcon,
    title: strings.specialistsMenuTitle,
    submenu: false,
  },
  {
    pathname: "/todo",
    dataTestId: "sessions-link",
    icon: TodoIcon,
    title: strings.todoListMenuTitle,
    submenu: false,
  },
  {
    pathname: "/notes",
    dataTestId: "sessions-link",
    icon: NotesIcon,
    title: strings.notesMenuTitle,
    submenu: false,
  },

  {
    pathname: "/",
    dataTestId: "sessions-link",
    icon: CompanyIcon,
    title: strings.companyMenuTitle,
    submenu: true,
    submenuItems: [
      { title: strings.dashboardMenuTitle, path: "/company-dashboard" },
      { title: strings.programsMenuTitle, path: "/company-programs" },
      { title: strings.employersMenuTitle, path: "/company-employers" },
    ],
  },
];

export const sidebarMenuWithPrograms = [
  {
    pathname: "/home",
    dataTestId: "home-link",
    icon: HomeIcon,
    title: strings.homeMenuTitle,
    submenu: false,
  },
  {
    pathname: "/specialist-find",
    dataTestId: "home-link",
    icon: SpecialistIcon,
    title: strings.specialistsMenuTitle,
    submenu: false,
  },
  {
    pathname: "/todo",
    dataTestId: "sessions-link",
    icon: TodoIcon,
    title: strings.todoListMenuTitle,
    submenu: false,
  },
  {
    pathname: "/notes",
    dataTestId: "sessions-link",
    icon: NotesIcon,
    title: strings.notesMenuTitle,
    submenu: false,
  },
  {
    pathname: "/my-programs",
    dataTestId: "sessions-link",
    icon: MyProgramsIcon,
    title: strings.myProgramsMenuTitle,
    submenu: false,
  },
  {
    pathname: "/",
    dataTestId: "company",
    icon: CompanyIcon,
    title: strings.companyMenuTitle,
    submenu: true,
    submenuItems: [
      { title: strings.dashboardMenuTitle, path: "/company-dashboard" },
      { title: strings.programsMenuTitle, path: "/company-programs" },
      { title: strings.employersMenuTitle, path: "/company-employers" },
    ],
  },
];
