import { Dispatch, SetStateAction } from "react";
import strings from "../../../../localization";
import LangTabs from "../../../Profile/SpecialistProfileContent/EditSpecialistProfile/LangTabs/LangTabs";
import s from "../AboutYourself.module.css";

type AboutSubStep3Props = {
  textareaValue: { text: string; language: string; selected: boolean }[];
  setTextareaValue: Dispatch<
    SetStateAction<
      { text: string; language: string; selected: boolean }[] | undefined
    >
  >;
  maxLenght: number;
};

const AboutSubStep2 = ({
  textareaValue,
  setTextareaValue,
  maxLenght,
}: AboutSubStep3Props) => {
  return (
    <div className={s.subStepBlock}>
      <div className={s.infoTitleBlock}>
        <p className={s.stepTitle}>{strings.howDoesTherapyWorkTitle}</p>
        <p className={s.substepText}>{strings.howDoesTherapyWorkDescription}</p>
        <p className={s.substepText}>{strings.howDoesTherapyWorkListTitle}</p>
        <ul>
          <li>{strings.howDoesTherapyWorkLestElement1}</li>
          <li>{strings.howDoesTherapyWorkLestElement2}</li>
          <li>{strings.howDoesTherapyWorkLestElement3}</li>
          <li>{strings.howDoesTherapyWorkLestElement4}</li>
        </ul>
      </div>
      {textareaValue.length > 1 && (
        <LangTabs
          languagesData={textareaValue.map((item) => ({
            language: item.language,
            selected: item.selected,
          }))}
          onClickFunc={(lang) =>
            setTextareaValue(
              textareaValue.map((element) => {
                if (element.language === lang) {
                  return {
                    ...element,
                    selected: true,
                  };
                } else {
                  return {
                    ...element,
                    selected: false,
                  };
                }
              })
            )
          }
        />
      )}
      <div className={s.textAreaBlock}>
        <textarea
          maxLength={maxLenght}
          value={textareaValue.find((el) => el.selected)?.text!}
          placeholder={strings.setupAboutPlaceHodler}
          className={s.textArea}
          onChange={(e) =>
            setTextareaValue((prev) => {
              return prev
                ? prev.map((item) => {
                    if (item.selected) {
                      return {
                        text: e.target.value,
                        language: item.language,
                        selected: true,
                      };
                    } else return item;
                  })
                : undefined;
            })
          }
        ></textarea>
        <p>
          {textareaValue.find((el) => el.selected)?.text!.length}/{maxLenght}
        </p>
      </div>
    </div>
  );
};

export default AboutSubStep2;
