function getDatesInWeek(date: Date): Date[] {
  const dayOfWeek = date.getDay();
  const startDate = new Date(date.getTime() - dayOfWeek * 86400000); // Get start date of the week
  const endDate = new Date(startDate.getTime() + 6 * 86400000); // Get end date of the week

  const datesInWeek: Date[] = [];
  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    datesInWeek.push(new Date(d));
  }

  return datesInWeek;
}

export function drawDatesInWeek(date: Date): Date[] {
  const datesInWeek = getDatesInWeek(date);

  return datesInWeek;
}
