import { Dispatch, SetStateAction } from "react";
import { ReactComponent as GrayToken } from "../../../assets/Subscriptions/heart-tick.svg";
import { ReactComponent as SelectedSubsciption } from "../../../assets/Subscriptions/selected-subscr.svg";
import strings from "../../../localization";
import { Subscription } from "../../../types/Subscription";
import { calculateDiscountedPrice } from "../../../utils/Numbers";
import s from "./PeriodCard.module.css";

type PeriodCardProps = {
  subscriptionData: Subscription;
  periodData: { days: number; amount: number };
  setDaysAmount: Dispatch<
    SetStateAction<{ days: number; amount: number } | null>
  >;
  daysAmount: { days: number; amount: number } | null;
};
const PeriodCard = ({
  periodData,
  subscriptionData,
  setDaysAmount,
  daysAmount,
}: PeriodCardProps) => {
  return (
    <div className={s.container} onClick={() => setDaysAmount(periodData)}>
      <div className={s.leftBlock}>
        {daysAmount?.days === periodData.days ? (
          <SelectedSubsciption />
        ) : (
          <span className={s.notSelectedSub}></span>
        )}
        <span className={s.periodValue}>
          {(periodData.days / 30).toFixed(0) +
            " " +
            (periodData.days > 30
              ? strings.periodManyMonth
              : strings.periodOneMonth)}
        </span>
        {periodData.amount ? (
          <span className={s.discountBlock}>
            {"-" + periodData.amount + "%"}
          </span>
        ) : null}
      </div>
      <div className={s.rightBlock}>
        {periodData.amount ? (
          <span className={s.oldPrice}>
            {subscriptionData.costPerMonth *
              Number((periodData.days / 30).toFixed(0))}
          </span>
        ) : null}
        <span className={s.currentPrice}>
          {calculateDiscountedPrice(
            subscriptionData.costPerMonth *
              Number((periodData.days / 30).toFixed(0)),
            periodData.amount
          )}
          <GrayToken />
        </span>
      </div>
    </div>
  );
};

export default PeriodCard;
