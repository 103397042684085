import CustomTooltip from "../CustomTooltip/CustomTooltip";
import s from "./StandartButton.module.css";

type TypeStandartButton = {
  buttonTitle: string;
  action: () => void;
  width?: string;
  fontSize?: string;
  height?: string;
  bgColor?: string;
  userButton?: boolean;
  disabled?: boolean;
  tooltipTitle?: string;
};

const StandartButton = ({
  buttonTitle,
  action,
  width,
  fontSize,
  height,
  bgColor,
  userButton,
  disabled,
  tooltipTitle,
}: TypeStandartButton) => {
  return (
    <CustomTooltip
      title={disabled && tooltipTitle ? tooltipTitle : ""}
      hideTooltip={!disabled || !tooltipTitle ? true : false}
    >
      <div style={{ width: width ? width : "auto" }}>
        <button
          onClick={action}
          className={
            disabled
              ? !userButton
                ? s.btnDisabled
                : s.userBtnDisabled
              : !userButton
              ? s.btn
              : s.userBtn
          }
          disabled={disabled ? true : false}
          style={{
            border: "none",
            backgroundColor: bgColor ? bgColor : "var(--primary-color)",
            height: height ? height : "42px",
            width: width ? width : "auto",
            fontSize: fontSize ? fontSize : "16px",
          }}
        >
          {buttonTitle}
        </button>
      </div>
    </CustomTooltip>
  );
};

export default StandartButton;
