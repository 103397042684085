import { Tooltip } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import SessionsApi from "../../../../../api/sessionApi";
import { ReactComponent as Chevron } from "../../../../../assets/HomePage/chevron-up.svg";
import Loader from "../../../../../components/Loader/Loader";
import ModalWindow from "../../../../../components/ModalWindow/ModalWindow";
import MuiCalendar from "../../../../../components/MuiCalendar/MuiCalendar";
import PaymentSession from "../../../../../components/PaymentSession/PaymentSession";
import StandartButton from "../../../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../../../contextProvider";
import strings from "../../../../../localization";
import { PaymentFor, PaymentForType } from "../../../../../types/Balance";
import { formatDateAndTime } from "../../../../../utils/dateActions";
import Notify, { SuccesNotify } from "../../../../../utils/toaster";
import s from "./PackageBooking.module.css";

type PackageBookingProps = {
  overlapTime: { start: Date; end: Date }[];
  sessions: {
    duration: number;
    order: number;
    title: string;
    nextSessionGap: { min: number; max: number };
    description?: string;
  }[];
  packageId: string;
  setBookingModal: Dispatch<SetStateAction<boolean>>;
  packagePrice: number;
  specialistUserId: string;
  specialistId: string;
  projectId?: string;
};

const PackageBooking = ({
  overlapTime,
  sessions,
  packageId,
  setBookingModal,
  packagePrice,
  specialistUserId,
  specialistId,
  projectId,
}: PackageBookingProps) => {
  const token = localStorage.getItem("token");
  const { userData, setUserData } = useContext(ContextProvider);
  const [daySlots, setDaySlots] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const [timeSlots, setTimeSlots] = useState<{ date: string; time: string }[]>(
    []
  );
  const [sessionsBookingInfo, setSessionsBookingInfo] = useState<
    | {
        title: string;
        description?: string;
        datesAndTime: { date: Date | null; duration: number }[];
        order: number;
      }[]
    | null
  >(null);
  const [selectedSessionIndex, setSelectedSessionIndex] = useState(0);
  const [actionLoader, setActionLoader] = useState({ bookingLoader: false });
  const [noBalanceModal, setNoBalanceModal] = useState(false);
  const [paymentFor, setPaymentFor] = useState<PaymentFor | null>(null);

  useEffect(() => {
    if (!sessionsBookingInfo) return;
    const bookingsInfo = sessionsBookingInfo.map((item) => ({
      title: item.title,
      description: item.description,
      datesAndTime: item.datesAndTime as { date: Date; duration: number }[],
      order: item.order,
    }));

    const currentPaymentFor = {
      type: PaymentForType.SESSION_PACKAGE,
      bookingFor: specialistUserId,
      bookingForSpecialist: specialistId,
      packageId: packageId,
      sessionPackageInfo: bookingsInfo,
    };
    setPaymentFor(currentPaymentFor);
  }, [sessionsBookingInfo]);

  useEffect(() => {
    if (!sessions) return;
    const correctSessionsInfo = sessions.map((item) => ({
      title: item.title,
      description: item.description,
      datesAndTime: [{ date: null, duration: item.duration }],
      order: item.order,
    }));
    setSessionsBookingInfo(correctSessionsInfo);
  }, [sessions]);

  const getTimeAndDateSlots = (
    sessions: { start: Date; end: Date }[]
  ): { timeSlots: { date: string; time: string }[]; dateSlots: string[] } => {
    const timeSlots: { date: string; time: string }[] = [];
    const dateSlotsSet: Set<string> = new Set();

    for (const session of sessions) {
      const startDate = new Date(session.start);
      const endDate = new Date(session.end);

      const startTime = startDate.getUTCHours();
      const endTime = endDate.getUTCHours();
      const startDateISO = startDate.toISOString().slice(0, 10);
      const endDateISO = endDate.toISOString().slice(0, 10);

      dateSlotsSet.add(startDateISO);
      if (startDateISO !== endDateISO && endTime !== 0) {
        dateSlotsSet.add(endDateISO);
      }

      if (startDateISO === endDateISO || startTime < endTime) {
        for (let i = startTime; i < endTime; i++) {
          const timeSlot = `${i < 10 ? "0" + i : i}:00`;
          timeSlots.push({ date: startDateISO, time: timeSlot });
        }
      } else {
        for (let i = startTime; i < 24; i++) {
          const timeSlot = `${i < 10 ? "0" + i : i}:00`;
          timeSlots.push({ date: startDateISO, time: timeSlot });
        }
        for (let i = 0; i < endTime; i++) {
          const timeSlot = `${i < 10 ? "0" + i : i}:00`;
          timeSlots.push({ date: endDateISO, time: timeSlot });
        }
      }
    }
    const dateSlots = Array.from(dateSlotsSet).sort();
    return { timeSlots, dateSlots };
  };

  useEffect(() => {
    const freeTimeSlots = getTimeAndDateSlots(overlapTime);
    freeTimeSlots.timeSlots.sort((a, b) => {
      if (a.date === b.date) {
        return a.time.localeCompare(b.time);
      }
      return a.date.localeCompare(b.date);
    });

    let availableDateSlots = freeTimeSlots.dateSlots;
    if (!sessionsBookingInfo) return;
    if (selectedSessionIndex > 0) {
      const previousSessionDate =
        sessionsBookingInfo[selectedSessionIndex - 1].datesAndTime[0].date;

      if (previousSessionDate) {
        const prevDateIndex = availableDateSlots.findIndex(
          (el) => el === previousSessionDate.toISOString().slice(0, 10)
        );
        const minInterval =
          sessions[selectedSessionIndex - 1].nextSessionGap.min; // Минимальный интервал
        const maxInterval =
          sessions[selectedSessionIndex - 1].nextSessionGap.max;

        const newSlots = availableDateSlots.slice(
          prevDateIndex + minInterval,
          prevDateIndex + maxInterval + 1
        );

        availableDateSlots = newSlots;
      }
    }

    setDaySlots(availableDateSlots);

    if (selectedDate) {
      const filteredTimeSlots = freeTimeSlots.timeSlots.filter((slot) =>
        dayjs(slot.date).isSame(selectedDate, "day")
      );
      setTimeSlots(filteredTimeSlots);
    }
  }, [selectedDate, selectedSessionIndex, sessionsBookingInfo, overlapTime]);

  const areDatesEqual = (date: Date): boolean => {
    if (!sessionsBookingInfo) return false;

    const selectedSession = sessionsBookingInfo.find(
      (_, index) => index == selectedSessionIndex
    );

    if (!selectedSession) return false;

    return selectedSession.datesAndTime.some(
      (dt) => dt.date && dt.date.getTime() === date.getTime()
    );
  };

  const combineDateTime = (dateStr: string, timeStr: string): Date => {
    // Парсим дату и время
    const dateParts = dateStr.split("-").map((part) => parseInt(part, 10));
    const timeParts = timeStr.split(":").map((part) => parseInt(part, 10));

    // Создаем новый объект Date
    const year = dateParts[0];
    const month = dateParts[1] - 1; // Месяцы в JavaScript начинаются с 0
    const day = dateParts[2];
    const hour = timeParts[0];
    const minute = timeParts[1];

    return new Date(year, month, day, hour, minute);
  };
  const updateDateForSession = (date: Date) => {
    setSessionsBookingInfo((prev) => {
      if (!prev) return prev;
      return prev.map((item, index) =>
        selectedSessionIndex === index
          ? {
              ...item,
              datesAndTime: item.datesAndTime.map((dt) => ({
                ...dt,
                date: date,
              })),
            }
          : item
      );
    });
  };
  const hasNullDate = (
    sessions: {
      title: string;
      description?: string;
      datesAndTime: { date: Date | null; duration: number }[];
      order: number;
    }[]
  ) => {
    return sessions.some((session) =>
      session.datesAndTime.some((dt) => dt.date === null)
    );
  };
  const bookSessionPackage = async () => {
    if (!sessionsBookingInfo) return;
    if (!token || hasNullDate(sessionsBookingInfo)) return;
    const bookingsInfo = sessionsBookingInfo.map((item) => ({
      title: item.title,
      description: item.description,
      datesAndTime: item.datesAndTime as { date: Date; duration: number }[],
      order: item.order,
    }));
    const payload = {
      packageId: packageId,
      bookingsInfo: bookingsInfo,
      projectId: projectId,
    };
    setActionLoader((prev) => ({ ...prev, bookingLoader: true }));

    const response = await SessionsApi.bookSessionPackage(token, payload);

    if (!response.status && response.notEnoughBalance) {
      setNoBalanceModal(true);
    }
    if (!response.status && response.message && !response.notEnoughBalance) {
      Notify(response.message);
    }
    if (response.status) {
      setUserData((prev) => ({
        ...prev!,
        balance: prev!.balance - packagePrice,
      }));
      SuccesNotify(strings.bookingSucces);
      setBookingModal(false);
    }

    setActionLoader((prev) => ({ ...prev, bookingLoader: false }));
  };
  useEffect(() => {
    if (!daySlots || !daySlots.length) return;

    const dateObject: Dayjs = dayjs(daySlots[0]);

    setSelectedDate(dateObject);
  }, [daySlots.length]);

  return (
    <div className={s.container}>
      <div className={s.headTitleBlock}>
        <h2>{strings.packageBookingModalTitle}</h2>
      </div>
      <span className={s.timeszoneTitleMobile}>
        {strings.timeRepresentedDescription} ({userData?.timezone})
      </span>
      <div className={s.contentBlock}>
        <div className={s.leftBlock}>
          <div className={s.calendarBlock}>
            {selectedDate && (
              <MuiCalendar
                setSelectedDate={setSelectedDate}
                dateSlots={daySlots}
                isSelectedMode={true}
                fontSize="14px"
                selectedDate={selectedDate}
              />
            )}
          </div>
          <span className={s.timeszoneTitle}>
            {strings.timeRepresentedDescription} ({userData?.timezone})
          </span>
          {timeSlots && timeSlots.length ? (
            <div className={s.timeSlotsBlock}>
              {timeSlots.map((item, index) => (
                <div
                  key={index}
                  role="button"
                  className={
                    areDatesEqual(combineDateTime(item.date, item.time))
                      ? s.selectedTime
                      : s.notSelectedTime
                  }
                  onClick={() =>
                    updateDateForSession(combineDateTime(item.date, item.time))
                  }
                >
                  {item.time}
                </div>
              ))}
            </div>
          ) : null}
        </div>
        {sessionsBookingInfo && (
          <div className={s.rightBlock}>
            <div className={s.headRightBlock}>
              <div className={s.leftHeadRightBlock}>
                <span>{strings.sessionsSchedule}</span>
                <span>
                  {sessionsBookingInfo.filter((el) => el.datesAndTime[0].date)
                    .length +
                    " " +
                    strings.outOf +
                    " " +
                    sessionsBookingInfo.length}
                </span>
              </div>
              <div className={s.sessionSelectActionBlock}>
                <div
                  role="button"
                  className={
                    selectedSessionIndex === 0
                      ? s.opacitynextBackBtn
                      : s.nextBackBtn
                  }
                  onClick={
                    selectedSessionIndex === 0
                      ? () => null
                      : () => {
                          setSessionsBookingInfo((prev) => {
                            if (!prev) return prev;
                            return prev.map((item, index) =>
                              selectedSessionIndex === index
                                ? {
                                    ...item,
                                    datesAndTime: item.datesAndTime.map(
                                      (dt) => ({
                                        ...dt,
                                        date: null,
                                      })
                                    ),
                                  }
                                : item
                            );
                          });
                          setSelectedSessionIndex((prev) => prev - 1);
                        }
                  }
                >
                  <Chevron className={s.chevronLeft} />

                  {strings.backPackageBtn}
                </div>
                <Tooltip
                  arrow
                  placement="bottom"
                  title={
                    sessionsBookingInfo &&
                    !sessionsBookingInfo[selectedSessionIndex].datesAndTime[0]
                      .date
                      ? strings.selectDateForThisSession
                      : ""
                  }
                >
                  <div
                    role="button"
                    className={
                      selectedSessionIndex === sessionsBookingInfo.length - 1 ||
                      (sessionsBookingInfo &&
                        !sessionsBookingInfo[selectedSessionIndex]
                          .datesAndTime[0].date)
                        ? s.opacitynextBackBtn
                        : s.nextBackBtn
                    }
                    onClick={
                      selectedSessionIndex === sessionsBookingInfo.length - 1 ||
                      (sessionsBookingInfo &&
                        !sessionsBookingInfo[selectedSessionIndex]
                          .datesAndTime[0].date)
                        ? () => null
                        : () => setSelectedSessionIndex((prev) => prev + 1)
                    }
                  >
                    {strings.nextPackageBtn}
                    <Chevron className={s.chevronRight} />
                  </div>
                </Tooltip>
              </div>
            </div>
            {sessionsBookingInfo && sessionsBookingInfo.length ? (
              <div className={s.sessionsListBlock}>
                {sessionsBookingInfo.map((item, index) => (
                  <div
                    key={index}
                    className={
                      selectedSessionIndex === index
                        ? s.selectedSessionElement
                        : s.notSelectedSessionElement
                    }
                  >
                    <div className={s.leftSessionElementBlock}>
                      <span>{item.title}</span>
                      <span>
                        {item.datesAndTime[0].date
                          ? formatDateAndTime(
                              item.datesAndTime[0].date,
                              userData?.selectedLanguage!
                            ).formattedDate +
                            " " +
                            formatDateAndTime(
                              item.datesAndTime[0].date,
                              userData?.selectedLanguage!
                            ).formattedTime
                          : strings.selectSessionDate}
                      </span>
                    </div>
                    <span
                      className={
                        item.datesAndTime[0].date
                          ? s.succesSessionStatus
                          : s.notCompleteSessionStatus
                      }
                    ></span>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )}
      </div>
      <div className={s.bookingBtnBlock}>
        {sessionsBookingInfo &&
          (actionLoader.bookingLoader ? (
            <Loader size={50} />
          ) : (
            <StandartButton
              buttonTitle={strings.specCardBookingBtn}
              action={bookSessionPackage}
              disabled={hasNullDate(sessionsBookingInfo)}
              width="200px"
            />
          ))}
      </div>
      {paymentFor && (
        <ModalWindow
          isOpen={noBalanceModal}
          setIsOpen={setNoBalanceModal}
          width="fit-content"
        >
          <PaymentSession paymentFor={paymentFor} />
        </ModalWindow>
      )}
    </div>
  );
};

export default PackageBooking;
