import { useContext, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ContextProvider } from "../../contextProvider";
import Authorization from "../../pages/Authorization/Authorization";
import AuthorizationPartner from "../../pages/Authorization/AuthorizationPartner";
import ConfirmRegistration from "../../pages/Authorization/ConfirmRegistration/ConfirmRegistration";
import ConfirmRegistrationPartner from "../../pages/Authorization/ConfirmRegistration/ConfirmRegistrationPartner";
import SelectRegMode from "../../pages/Authorization/SelectRegMode/SelectRegMode";
import UpdatePassword from "../../pages/Authorization/UpdatePassword/UpdatePassword";
import ConnectPhoneNumber from "../../pages/ConnectPhoneNumber/ConnectPhoneNumber";
import JoinCompany from "../../pages/JoinCompany/JoinCompany";
import JoinProgram from "../../pages/JoinProgram/JoinProgram";
import Privacy from "../../pages/Privacy/Privacy";
import PublicProfile from "../../pages/PublicProfile/PublicProfile";
import SpecialistSetupInfo from "../../pages/SpecialistSetupInfo/SpecialistSetupInfo";
import Terms from "../../pages/Terms/Terms";
import UserQuize from "../../pages/UserQuize/UserQuize";
import s from "./Auth.module.css";

const Auth = () => {
  const token = localStorage.getItem("token");
  const isSpecialistStored = localStorage.getItem("isSpecialist");
  const isSpecialist =
    isSpecialistStored !== null ? JSON.parse(isSpecialistStored) : false;
  const navigate = useNavigate();
  const { userData } = useContext(ContextProvider);
  useEffect(() => {
    if (token && !userData?.phoneNumber && !userData?.registerConfirmed) {
      navigate("/connect-phone-number");
      return;
    }
    if (token && isSpecialist) {
      navigate(
        userData?.registerConfirmed
          ? "/setup-professional-info"
          : "/confirm-partner"
      );
    }
    if (token && !isSpecialist) {
      navigate(userData?.registerConfirmed ? "/user-registration" : "/confirm");
    }
  }, []);

  return (
    <div className={s.authContainer}>
      <Routes>
        <Route path="/choice-mode" element={<SelectRegMode />} />
        <Route path="/login" element={<Authorization />} />
        <Route path="/login-partner" element={<AuthorizationPartner />} />
        <Route path="/connect-phone-number" element={<ConnectPhoneNumber />} />
        <Route path="/confirm" element={<ConfirmRegistration />} />
        <Route
          path="/confirm-partner"
          element={<ConfirmRegistrationPartner />}
        />
        <Route path="/reset-password" element={<UpdatePassword />} />
        <Route
          path="/setup-professional-info"
          element={<SpecialistSetupInfo />}
        />
        <Route path="/user-registration" element={<UserQuize />} />
        <Route path="/join-company/*" element={<JoinCompany />} />
        <Route path="/join-project/*" element={<JoinProgram />} />
        <Route path="/profile/*" element={<PublicProfile />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/*" element={<PublicProfile />} />
        {/*  <Route path="/*" element={<Navigate to="/choice-mode" replace />} /> */}
      </Routes>
    </div>
  );
};

export default Auth;
