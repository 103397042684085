import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import AddNewSpecialization from "../../pages/AddNewSpecialization/AddNewSpecialization";
import Dashboard from "../../pages/Dashboard/Dashboard";
import Employers from "../../pages/Employers/Employers";
import EventsCalendar from "../../pages/EventsCalendar/EventsCalendar/EventsCalendar";
import GlobalChat from "../../pages/GlobalChat/GlobalChat";
import HistoryOfSessions from "../../pages/HistoryOfSessions/HistoryOfSessions";
import Initial from "../../pages/Initial/Initial";
import JoinCompany from "../../pages/JoinCompany/JoinCompany";
import JoinProgram from "../../pages/JoinProgram/JoinProgram";
import MyPrograms from "../../pages/MyPrograms/MyPrograms";
import MySessions from "../../pages/MySessions/MySessions";
import Notes from "../../pages/Notes/Notes";
import PackageInfo from "../../pages/PackageInfo/PackageInfo";
import Privacy from "../../pages/Privacy/Privacy";
import Profile from "../../pages/Profile/Profile";
import Programs from "../../pages/Programs/Programs";
import SessionVideoChat from "../../pages/SessionVideoChat/SessionVideoChat";
import SpecialistFindPage from "../../pages/SpecialistFindPage/SpecialistFindPage";
import SpecialistMyProgram from "../../pages/SpecialistMyProgramPage/SpecialistMyProgram";
import PackagesTool from "../../pages/SpecialistTools/PackagesTool/PackagesTool";
import Terms from "../../pages/Terms/Terms";
import ToDo from "../../pages/ToDo/ToDo";
import TreeQuiz from "../../pages/TreeQuiz/TreeQuiz";
import s from "./Main.module.css";

const Main = () => {
  const navigate = useNavigate();
  const isSpecialistStored = localStorage.getItem("isSpecialist");
  const isSpecialist =
    isSpecialistStored !== null ? JSON.parse(isSpecialistStored) : false;
  const userHaveProgramsStored = localStorage.getItem("userHavePrograms");
  const userHavePrograms =
    userHaveProgramsStored !== null
      ? JSON.parse(userHaveProgramsStored)
      : false;
  const isUserCompany = localStorage.getItem("haveCompany");
  const userName = localStorage.getItem("specUsername");
  const [isJoinCompany, setIsJoinCompany] = useState(true);
  const [isJoinProject, setIsJoinProject] = useState(true);

  useEffect(() => {
    if (window.location.pathname === "/") navigate("/home");
    setIsJoinCompany(
      window.location.pathname.includes("join-company") ? true : false
    );
    setIsJoinProject(
      window.location.pathname.includes("join-project") ? true : false
    );
  }, [window.location.pathname]);

  useEffect(() => {
    if (!userName) return;
    navigate(`/profile/${userName}`);
    localStorage.removeItem("specUsername");
  }, [userName]);

  if (
    window.location.pathname === "/terms" ||
    window.location.pathname === "/privacy"
  ) {
    return (
      <Routes>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    );
  }

  return (
    <div
      style={{
        backgroundColor: "#EBEBEB",
        display: "flex",
      }}
    >
      <div className={s.sideBarMenu}>
        <SideBar />
      </div>
      <div style={{ width: "100%" }}>
        <Header />
        <div className={s.mainContent}>
          <Routes>
            <Route path="/home" element={<Initial />} />
            <Route path="/calendar" element={<EventsCalendar />} />
            {!isSpecialist && (
              <Route path="/specialist-find" element={<SpecialistFindPage />} />
            )}
            {isJoinCompany && (
              <Route path="/join-company/*" element={<JoinCompany />} />
            )}
            {isJoinProject && (
              <Route path="/join-project/*" element={<JoinProgram />} />
            )}
            {!isSpecialist && (
              <Route path="/tree-quiz" element={<TreeQuiz />} />
            )}
            <Route path="/my-sessions" element={<MySessions />} />
            <Route path="/session-meet" element={<SessionVideoChat />} />
            <Route path="/*" element={<Profile />} />
            <Route path="/notes" element={<Notes />} />
            <Route path="/todo" element={<ToDo />} />
            <Route path="/sessions-history" element={<HistoryOfSessions />} />
            {userHavePrograms && !isSpecialist && (
              <Route path="/my-programs" element={<MyPrograms />} />
            )}
            {userHavePrograms && isSpecialist && (
              <Route
                path="/my-programs-specialist"
                element={<SpecialistMyProgram />}
              />
            )}
            {isUserCompany && (
              <>
                <Route path="/company-dashboard" element={<Dashboard />} />
                <Route path="/company-programs" element={<Programs />} />
                <Route path="/company-employers" element={<Employers />} />
              </>
            )}
            {isSpecialist && (
              <Route path="/tools/package" element={<PackagesTool />} />
            )}

            <Route path="/new-speciality" element={<AddNewSpecialization />} />

            <Route path="/package-info/*" element={<PackageInfo />} />
            <Route path="/global-chat" element={<GlobalChat />} />
            {/*  <Route path="/*" element={<Initial />} /> */}
          </Routes>
          {/*   <Footer /> */}
        </div>
      </div>
    </div>
  );
};

export default Main;
