import { ReactComponent as CloseIcon } from "../../../../assets/HomePage/close.svg";
import { ReactComponent as ReturnIcon } from "../../../../assets/Social/return.svg";
import { ReactComponent as ScrapIcon } from "../../../../assets/Social/scrap.svg";
import { truncateString } from "../../../../utils/stringsActions";
import s from "./UpdateFileElement.module.css";

type UpdateFileElementProps = {
  filename: string;
  removeFunc: () => void;
  returnFunc?: () => void;
  isFileToRemove?: boolean;
};

const UpdateFileElement = ({
  filename,
  isFileToRemove,
  removeFunc,
  returnFunc,
}: UpdateFileElementProps) => {
  return (
    <div
      className={`${s.updateFileElement} ${
        isFileToRemove ? s.fileToRemoveElement : ""
      }`}
    >
      <ScrapIcon
        className={`${isFileToRemove ? s.removeScrapIcon : s.scrapIcon}`}
      />
      {truncateString(filename, 15)}
      {isFileToRemove ? (
        <ReturnIcon className={s.returnBtn} onClick={returnFunc} />
      ) : (
        <CloseIcon className={s.deleteFileIcon} onClick={removeFunc} />
      )}
    </div>
  );
};

export default UpdateFileElement;
