import { ThemeProvider } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import { ReactComponent as PlusIcon } from "../../assets/Profile/specialist/add.svg";
import { ReactComponent as CloseIcon } from "../../assets/Profile/specialist/close.svg";
import { ReactComponent as CopyIcon } from "../../assets/Profile/specialist/copy.svg";
import strings from "../../localization";
import { NewSpeciality } from "../../pages/AddNewSpecialization/AddNewSpecialization";
import WorkTimeInput from "../../pages/SpecialistSetupInfo/Price/WorkTimeInput/WorkTimeInput";
import { TypeUpdatedUserInfo, TypeUserData } from "../../types/TypeUsers";
import { DaySchedule } from "../../utils/shedule";
import { theme } from "../../utils/theme";
import MultipleSelect from "../MultipleSelect/MultipleSelect";
import CopyScheduleMenu from "./CopyScheduleMenu/CopyScheduleMenu";
import s from "./NewShedule.module.css";

const labelStyle = "original";
const timezones = {
  ...allTimezones,
};

type NewSheduleProps = {
  allDaysState: DaySchedule[];
  setDaysState: Dispatch<SetStateAction<DaySchedule[]>>;
  profileUserData: TypeUserData | undefined | null;
  userUpdateData?: TypeUpdatedUserInfo;
  onChangeUserDataText: (
    value: any,
    objKey: keyof TypeUpdatedUserInfo | keyof NewSpeciality,
    maxLength?: number
  ) => void;
};

const NewShedule = ({
  onChangeUserDataText,
  setDaysState,
  allDaysState,
  profileUserData,
  userUpdateData,
}: NewSheduleProps) => {
  /*   const { userData } = useContext(ContextProvider); */
  const [copyTimeMenuIsOpen, setCopyTimeMenuIsOpen] = useState<
    number | boolean
  >(false);
  const buttonRefs = useRef<(HTMLDivElement | null)[]>([]);
  const { options } = useTimezoneSelect({
    labelStyle,
    timezones,
  });
  /* 
  function getTimezoneOffset(timezone: string): string {
    const offsetMinutes = moment.tz(timezone).utcOffset();
    const result =
      offsetMinutes > 0 ? `+${offsetMinutes / 60}` : `${offsetMinutes / 60}`;
    return result; // конвертируем минуты в часы
  } */

  const handleCancelIconClick = (timeIndex: number, dayValue: string) => {
    const dayIndex = allDaysState.findIndex((el) => el.dayValue === dayValue);

    setDaysState((prev) => {
      const updatedState = [...prev];
      const day = { ...updatedState[dayIndex] };
      day.workTime = day.workTime.filter((_, i) => i !== timeIndex);
      updatedState[dayIndex] = day;

      return updatedState;
    });
  };

  const addGapHandler = (dayValue: string) => {
    const dayIndex = allDaysState.findIndex((el) => el.dayValue === dayValue);

    setDaysState((prev) => {
      const updatedState = [...prev];
      updatedState[dayIndex].workTime = [
        ...updatedState[dayIndex].workTime,
        { from: "", to: "" }, // Изменения здесь
      ];
      return updatedState;
    });
  };

  return (
    <div className={s.container}>
      {userUpdateData && (
        <div className={s.timeZoneBlock}>
          <span>{strings.timeZoneShedule}</span>
          <MultipleSelect
            data={options.map((option) => ({
              value: option.value,
              lable: option.label,
            }))}
            multiplie={false}
            setValue={(value) => onChangeUserDataText(value, "timezone")}
            value={
              userUpdateData.timezone
                ? userUpdateData.timezone
                : profileUserData?.timezone
            }
            padding="10px 20px"
            width="100%"
          />
        </div>
      )}
      {allDaysState.map((item, index) => (
        <div className={s.dayBlock} key={index}>
          <div className={s.dayTitleBlock}>
            <ThemeProvider theme={theme}>
              <Checkbox
                checked={item.switch}
                onClick={() => {
                  const updatedSchedule = allDaysState.map((day) =>
                    day.dayValue === item.dayValue
                      ? {
                          ...day,
                          switch: !day.switch,
                          workTime:
                            !day.switch && !day.workTime.length
                              ? [{ from: "", to: "" }]
                              : day.workTime,
                        }
                      : day
                  );
                  setDaysState(updatedSchedule);
                }}
                inputProps={{ "aria-label": "controlled" }}
                color={"primary"}
              />{" "}
            </ThemeProvider>
            {item.daytitle}{" "}
          </div>
          {item.switch ? (
            <div className={s.daySettingsBlock}>
              <div className={s.daySlotsListBlock}>
                {item.workTime.map((_, indexInterval) => (
                  <div className={s.timeElement} key={indexInterval}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <WorkTimeInput
                        inputsValue={allDaysState}
                        mainIndex={allDaysState.findIndex(
                          (el) => el.dayValue === item.dayValue
                        )}
                        setInputsValue={setDaysState}
                        timeIndex={indexInterval}
                        height="40px"
                        isForProfile
                      />

                      <div
                        role="button"
                        onClick={() => {
                          {
                            const updatedSchedule = allDaysState.map((day) =>
                              day.dayValue === item.dayValue
                                ? {
                                    ...day,
                                    switch: !day.switch,
                                  }
                                : day
                            );
                            item.workTime.length === 1 &&
                              setDaysState(updatedSchedule);
                            handleCancelIconClick(indexInterval, item.dayValue);
                          }
                        }}
                        className={s.iconButton}
                      >
                        <CloseIcon width={20} height={20} />
                      </div>
                    </div>
                    {indexInterval === 0 && (
                      <div className={s.actionBlock}>
                        <div
                          role="button"
                          onClick={() => addGapHandler(item.dayValue)}
                          className={s.iconButton}
                        >
                          <PlusIcon />
                        </div>
                        <div
                          ref={(el) => (buttonRefs.current[index] = el)}
                          role="button"
                          onClick={() => setCopyTimeMenuIsOpen(index)}
                          className={s.iconButton}
                        >
                          <CopyIcon width={20} height={20} />
                        </div>
                        {copyTimeMenuIsOpen === index && (
                          <CopyScheduleMenu
                            allDaysState={allDaysState}
                            selectedDay={item.dayValue}
                            setDaysState={setDaysState}
                            setCopyMenuIsOpen={setCopyTimeMenuIsOpen}
                            buttonRef={buttonRefs.current[index]}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <span className={s.dayOffTitle}>{strings.dayOff}</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default NewShedule;
