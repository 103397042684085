export const popularLanguages = [
  "English",
  "Ukrainian",
  "Russian",
  "Polish",
  "Germany",
  "French",
  "Spanish",
  "Italian",
];
export const ISO639Lang = {
  af: "Afrikaans",
  am: "Amharic",
  ar: "Arabic",
  az: "Azerbaijani",
  be: "Belarusian",
  bg: "Bulgarian",
  bn: "Bengali",
  bs: "Bosnian",
  ca: "Catalan",
  cs: "Czech",
  cy: "Welsh",
  da: "Danish",
  de: "Germany",
  el: "Greek",
  en: "English",
  eo: "Esperanto",
  es: "Spanish",
  et: "Estonian",
  eu: "Basque",
  fa: "Persian",
  fi: "Finnish",
  fj: "Fijian",
  fo: "Faroese",
  fr: "French",
  ga: "Irish",
  gl: "Galician",
  gu: "Gujarati",
  he: "Hebrew",
  hi: "Hindi",
  hr: "Croatian",
  hu: "Hungarian",
  hy: "Armenian",
  id: "Indonesian",
  is: "Icelandic",
  it: "Italian",
  ja: "Japanese",
  jv: "Javanese",
  ka: "Georgian",
  kk: "Kazakh",
  km: "Khmer",
  kn: "Kannada",
  ko: "Korean",
  ku: "Kurdish",
  ky: "Kyrgyz",
  lb: "Luxembourgish",
  lo: "Lao",
  lt: "Lithuanian",
  lv: "Latvian",
  mg: "Malagasy",
  mi: "Maori",
  mk: "Macedonian",
  ml: "Malayalam",
  mn: "Mongolian",
  mr: "Marathi",
  ms: "Malay",
  mt: "Maltese",
  my: "Burmese",
  ne: "Nepali",
  nl: "Dutch",
  no: "Norwegian",
  or: "Oriya",
  pa: "Punjabi",
  pl: "Polish",
  ps: "Pashto",
  pt: "Portuguese",
  ro: "Romanian",
  ru: "Russian",
  rw: "Kinyarwanda",
  si: "Sinhala",
  sk: "Slovak",
  sl: "Slovenian",
  sm: "Samoan",
  sq: "Albanian",
  sr: "Serbian",
  sv: "Swedish",
  sw: "Swahili",
  ta: "Tamil",
  te: "Telugu",
  th: "Thai",
  tl: "Tagalog",
  tr: "Turkish",
  uk: "Ukrainian",
  ur: "Urdu",
  uz: "Uzbek",
  vi: "Vietnamese",
  xh: "Xhosa",
  yi: "Yiddish",
  yo: "Yoruba",
  zh: "Chinese",
  zu: "Zulu",
};
export const ISO639LangReversed: { [key: string]: string } = {
  Afrikaans: "af",
  Amharic: "am",
  Arabic: "ar",
  Azerbaijani: "az",
  Belarusian: "be",
  Bulgarian: "bg",
  Bengali: "bn",
  Bosnian: "bs",
  Catalan: "ca",
  Czech: "cs",
  Welsh: "cy",
  Danish: "da",
  Germany: "de",
  Greek: "el",
  English: "en",
  Esperanto: "eo",
  Spanish: "es",
  Estonian: "et",
  Basque: "eu",
  Persian: "fa",
  Finnish: "fi",
  Fijian: "fj",
  Faroese: "fo",
  French: "fr",
  Irish: "ga",
  Galician: "gl",
  Gujarati: "gu",
  Hebrew: "he",
  Hindi: "hi",
  Croatian: "hr",
  Hungarian: "hu",
  Armenian: "hy",
  Indonesian: "id",
  Icelandic: "is",
  Italian: "it",
  Japanese: "ja",
  Javanese: "jv",
  Georgian: "ka",
  Kazakh: "kk",
  Khmer: "km",
  Kannada: "kn",
  Korean: "ko",
  Kurdish: "ku",
  Kyrgyz: "ky",
  Luxembourgish: "lb",
  Lao: "lo",
  Lithuanian: "lt",
  Latvian: "lv",
  Malagasy: "mg",
  Maori: "mi",
  Macedonian: "mk",
  Malayalam: "ml",
  Mongolian: "mn",
  Marathi: "mr",
  Malay: "ms",
  Maltese: "mt",
  Burmese: "my",
  Nepali: "ne",
  Dutch: "nl",
  Norwegian: "no",
  Oriya: "or",
  Punjabi: "pa",
  Polish: "pl",
  Pashto: "ps",
  Portuguese: "pt",
  Romanian: "ro",
  Russian: "ru",
  Kinyarwanda: "rw",
  Sinhala: "si",
  Slovak: "sk",
  Slovenian: "sl",
  Samoan: "sm",
  Albanian: "sq",
  Serbian: "sr",
  Swedish: "sv",
  Swahili: "sw",
  Tamil: "ta",
  Telugu: "te",
  Thai: "th",
  Tagalog: "tl",
  Turkish: "tr",
  Ukrainian: "uk",
  Urdu: "ur",
  Uzbek: "uz",
  Vietnamese: "vi",
  Xhosa: "xh",
  Yiddish: "yi",
  Yoruba: "yo",
  Chinese: "zh",
  Zulu: "zu",
};
export const translations = {
  en: { hours: "h", minutes: "m", seconds: "s" },
  ru: { hours: "ч", minutes: "м", seconds: "с" },
  uk: { hours: "год", minutes: "хв", seconds: "сек" },
};
