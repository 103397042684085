import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import AnimateHeight from "react-animate-height";
import TodosAPI from "../../../../api/todoApi";
import UsersApi from "../../../../api/usersApi";
import { ReactComponent as ChevronIcon } from "../../../../assets/HomePage/chevron-up.svg";
import CheckIcon from "../../../../assets/Notes/check.svg";
import TripleDotsIcon from "../../../../assets/Notes/triple-dots.svg";
import { ContextProvider } from "../../../../contextProvider";
import strings from "../../../../localization";
import { Todo, TodoList, TodoStatus } from "../../../../types/TypeToDo";
import { getTranslation } from "../../../../utils/getTranslation";
import s from "./TodoTaskVideoChat.module.css";

type TaskCardProps = {
  taskId: string;
  title: string;
  description: string;
  status: string;
  onChangeTodo: (value: string, index: number, keyObj: keyof Todo) => void;
  index: number;
  updateTodo: (index: number) => void;
  setSelectedTodoList: Dispatch<SetStateAction<TodoList | null>>;
  selectedTodoList: TodoList | null;
};

type TaskAuthorInfo = {
  name: string;
  surname: string;
};

const TodoTaskVideoChat = ({
  title,
  description,
  status,
  onChangeTodo,
  index,
  updateTodo,
  taskId,
  setSelectedTodoList,
  selectedTodoList,
}: TaskCardProps) => {
  const { userData } = useContext(ContextProvider);
  const token = localStorage.getItem("token");
  const [isEdit, setIsEdit] = useState(false);
  const [statusMenuIsCollapsed, setStatusMenuIsCollapsed] = useState(true);
  const [taskMenuIsOpen, setTaskMenuIsOpen] = useState(false);
  const [taskAuthorInfo, setTaskAuthorInfo] = useState<TaskAuthorInfo | null>(
    null
  );

  const formateDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  };

  const statusArray = [
    { label: strings.newStatusTask, value: "new" },
    { label: strings.inProgressStatusTask, value: "in progress" },
    { label: strings.urgentStatusTask, value: "urgent" },
    { label: strings.completeStatusTask, value: "completed" },
  ];
  const deleteTask = async () => {
    if (token && selectedTodoList) {
      const response = await TodosAPI.deleteTodo(
        token,
        taskId,
        selectedTodoList._id
      );
      if (response.status) {
        setSelectedTodoList((prevTodoList) => {
          if (prevTodoList) {
            const updatedTodos = prevTodoList.todos.filter(
              (todo) => todo._id !== taskId
            );

            return {
              ...prevTodoList,
              todos: updatedTodos,
            };
          }
          return prevTodoList;
        });
      }
    }
  };

  const changeStatusHandler = async (status: string) => {
    if (token && selectedTodoList) {
      const response = await TodosAPI.updateTodo(
        token,
        {
          _id: taskId,
          status: status,
        },
        selectedTodoList._id
      );

      if (response.status)
        setSelectedTodoList((prevTodoList) => {
          const newstatus =
            status === "new"
              ? TodoStatus.NEW
              : status === "in progress"
              ? TodoStatus.IN_PROGRESS
              : status === "urgent"
              ? TodoStatus.URGENT
              : TodoStatus.COMPLETED;
          if (prevTodoList && prevTodoList.todos[index]) {
            const updatedTodos = [...prevTodoList.todos];
            updatedTodos[index] = {
              ...updatedTodos[index],
              status: { ...updatedTodos[index].status, status: newstatus },
            };

            return {
              ...prevTodoList,
              todos: updatedTodos,
            };
          }
          return prevTodoList;
        });
      setTaskMenuIsOpen(false);
    }
  };
  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !selectedTodoList) return;
      const response = await UsersApi.getUserPublicAvatarAndName(
        selectedTodoList.createdBy._id
      );

      if (response.name && response.surname) {
        setTaskAuthorInfo({
          name: getTranslation(response.name, userData?.selectedLanguage),
          surname: getTranslation(response.surname, userData?.selectedLanguage),
        });
      }
    };
    makeAsync();
  }, [selectedTodoList]);

  return (
    <div className={s.container}>
      <div className={s.noteDataBlock}>
        <div className={s.headNoteData}>
          <div className={s.headTaskBlockInfo}>
            {isEdit ? (
              <input
                className={s.updateTitleInput}
                type="text"
                value={title}
                onChange={(event) =>
                  onChangeTodo(event.target.value, index, "title")
                }
              />
            ) : (
              <span className={s.taskTitle}>{title}</span>
            )}
            <span>{formateDate(new Date())}</span>
          </div>
          <div className={s.editButtonBlock}>
            <span
              className={
                status === "in progress"
                  ? s.progressStatus
                  : status === "new"
                  ? s.newStatus
                  : status === "completed"
                  ? s.completeStatus
                  : s.urgentStatus
              }
            ></span>
            <span
              onClick={
                isEdit
                  ? () => {
                      updateTodo(index);
                      setIsEdit(false);
                    }
                  : () => setTaskMenuIsOpen(!taskMenuIsOpen)
              }
              className={s.tripleDotIcon}
            >
              {isEdit ? (
                <img src={CheckIcon} alt="" className={s.checkIcon} />
              ) : (
                <img src={TripleDotsIcon} alt="" />
              )}
            </span>
            <div
              className={s.actionMenuBlock}
              style={{ display: taskMenuIsOpen ? "flex" : "none" }}
            >
              <span
                className={s.menuElement}
                onClick={() => {
                  setIsEdit(!isEdit);
                  setTaskMenuIsOpen(false);
                }}
              >
                {strings.editTaskBtn}
              </span>
              <span className={s.menuElement} onClick={deleteTask}>
                {strings.deletetaskBtn}
              </span>
              <span
                className={s.menuElement}
                onClick={() => setStatusMenuIsCollapsed(!statusMenuIsCollapsed)}
              >
                {strings.changeStatusBtn}{" "}
                <ChevronIcon className={s.chevronIcon} />
              </span>

              <AnimateHeight
                height={statusMenuIsCollapsed ? 0 : "auto"}
                className={s.changeStatusBlock}
              >
                {statusArray.map((item, index) => (
                  <span
                    key={index}
                    style={{ display: "inline" }}
                    className={s.statusElement}
                    onClick={() => changeStatusHandler(item.value)}
                  >
                    {item.label}
                  </span>
                ))}
              </AnimateHeight>
            </div>
          </div>
        </div>
        <div className={s.createdByTitle}>
          {strings.createdByTodo}{" "}
          {taskAuthorInfo?.name + " " + taskAuthorInfo?.surname}
        </div>
        <div className={s.noteTextData}>
          <textarea
            readOnly={!isEdit}
            name=""
            id=""
            cols={30}
            rows={7}
            value={description}
            className={isEdit ? s.textAreaNewNote : s.textArea}
            onChange={(event) =>
              onChangeTodo(event.target.value, index, "description")
            }
          ></textarea>
          {/*  {isEdite && <ScrapIcon className={s.scrapIcon} />} */}
        </div>
      </div>
    </div>
  );
};

export default TodoTaskVideoChat;
