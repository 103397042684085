export const truncateString = (str: string, num: number) => {
  if (str.length <= num) {
    return str;
  }

  const half = Math.floor(num / 2);
  const start = str.slice(0, half);
  const end = str.slice(str.length - half);

  return `${start}...${end}`;
};
