import Compressor from "compressorjs";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import UsersApi from "../../../../api/usersApi";
import EditIcon from "../../../../assets/SpecialistSetupInfo/edit.svg";
import MovieIcon from "../../../../assets/SpecialistSetupInfo/movie.svg";
import ImageIcon from "../../../../assets/SpecialistSetupInfo/photo.svg";
import CropAvatarModal from "../../../../components/CropAvatarModal/CropAvatarModal";
import Loader from "../../../../components/Loader/Loader";
import {
  imageExtensions,
  videoExtensions,
} from "../../../../constans/filesExtensions";
import { ISO639LangReversed } from "../../../../constans/languagesList";
import { ContextProvider } from "../../../../contextProvider";
import strings from "../../../../localization";
import { SpecialistData } from "../../../../types/TypeSpecialist";
import { getTranslation } from "../../../../utils/getTranslation";
import Notify from "../../../../utils/toaster";
import s from "../CreatingProfile.module.css";

type TypeSubSteps2 = {
  avatar: File | null;
  setAvatar: Dispatch<SetStateAction<File | null>>;
  video: { language: string; file: File }[] | null;
  setVideo: Dispatch<SetStateAction<{ language: string; file: File }[] | null>>;
  specialistInfo: SpecialistData | null;
};

const SubSteps2 = ({
  avatar,
  setAvatar,
  video,
  setVideo,
  specialistInfo,
}: TypeSubSteps2) => {
  const { userData, selectLang } = useContext(ContextProvider);
  const [actionLoaders, setActionLoaders] = useState({
    sendDataLoader: false,
    pickAvatarLoader: false,
  });
  const [settingsAvatarModal, setSettingsAvatarModal] = useState(false);
  const [selectLangForVideo, setSelectLangForVideo] = useState(
    userData &&
      userData.preferredLanguages &&
      userData.preferredLanguages.length
      ? ISO639LangReversed[userData.preferredLanguages[0]]
      : "uk"
  );

  const handleVideoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const videoElement = document.createElement("video");
      const fileReader = new FileReader();

      fileReader.onload = () => {
        videoElement.src = fileReader.result as string;
        videoElement.onloadedmetadata = () => {
          const aspectRatio =
            videoElement.videoHeight / videoElement.videoWidth;
          if (aspectRatio < 1) {
            Notify(strings.onlyVerticalVideo);
            setVideo(null);
          } else {
            setVideo((prevVideos) => {
              if (!prevVideos) {
                // Если стейт пустой, добавляем новый объект
                return [{ language: selectLangForVideo, file: file }];
              }

              // Проверяем, есть ли объект с выбранным языком
              const videoIndex = prevVideos.findIndex(
                (video) => video.language === selectLangForVideo
              );

              if (videoIndex !== -1) {
                // Если видео с таким языком найдено, обновляем его
                const updatedVideos = [...prevVideos];
                updatedVideos[videoIndex].file = file;
                return updatedVideos;
              } else {
                // Если нет, добавляем новое видео в массив
                return [
                  ...prevVideos,
                  { language: selectLangForVideo, file: file },
                ];
              }
            });
          }
        };
      };

      fileReader.readAsDataURL(file);
    } else {
      setVideo(null);
    }
  };
  const [fullName, setFullName] = useState("");
  const [specializations, setSpecializations] = useState<
    string[] | undefined
  >();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const makeAsync = async () => {
      if (token) {
        const response = await UsersApi.getUserByToken(token);
        if (
          response &&
          response.specialistIds &&
          response.specialistIds[0].subSpecializations
        ) {
          const subSpecializationsTitle =
            response.specialistIds[0].subSpecializations.map(
              (item) =>
                item.labels.find(
                  (item) => item.language === userData?.selectedLanguage
                )?.text!
            );
          setSpecializations(subSpecializationsTitle);
        }
        if (response && response.name && response.surname)
          setFullName(
            getTranslation(response.name, selectLang ?? "en") +
              " " +
              getTranslation(response.surname, selectLang ?? "en")
          );
      }
    };
    makeAsync();
  }, [userData]);

  const compressFiles = (
    file: File,
    setCompressedFiles: React.Dispatch<React.SetStateAction<File | null>>
  ): void => {
    setActionLoaders((prev) => ({ ...prev, pickAvatarLoader: true }));
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        const compressedFile = new File([result], file.name, {
          type: result.type,
          lastModified: Date.now(),
        });

        if (compressedFile) {
          setCompressedFiles(compressedFile);
          setActionLoaders((prev) => ({ ...prev, pickAvatarLoader: false }));
          setSettingsAvatarModal(true);
        }
      },
      error(err) {
        console.error(err.message);
        setActionLoaders((prev) => ({ ...prev, pickAvatarLoader: false }));
      },
    });
  };

  const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      if (file) {
        compressFiles(file, setAvatar);
      }
    }
  };

  return (
    <div className={s.subStepBlock}>
      <p className={s.stepTitle}>{strings.setupAvatarAndVideoTitle}</p>
      <div className={s.avatarBlock}>
        <div className={s.avatar}>
          {actionLoaders.pickAvatarLoader ? (
            <div className={s.imageLoaderBlock}>
              <Loader size={50} />
            </div>
          ) : avatar ? (
            <div style={{ position: "relative" }}>
              <img
                src={URL.createObjectURL(avatar)}
                alt="Avatar"
                className={s.avatarImage}
              />
              {avatar && (
                <label className={s.avatarInputBlock}>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    accept={imageExtensions.join(", ")}
                    onChange={(e) => handleAvatarChange(e)}
                  />
                  <div className={s.editAvatarIcon} role="button">
                    <img src={EditIcon} alt="" />
                  </div>
                </label>
              )}
            </div>
          ) : (
            <label className={s.avatarInputBlock}>
              <input
                style={{ display: "none" }}
                type="file"
                accept={imageExtensions.join(", ")}
                onChange={(e) => handleAvatarChange(e)}
              />
              <div className={s.avatarLable}>
                <img src={ImageIcon} alt="" />
              </div>
            </label>
          )}
        </div>
        <div className={s.personInfoBlock}>
          <div className={s.nameTitle}>
            <p>{fullName}</p>
          </div>
          <div className={s.experienceTitle}>
            <p>
              {strings.setupAvatarPracticalHours}{" "}
              {specialistInfo?.practiceHours} {strings.setupHours}
            </p>
            <p>
              {specialistInfo?.yearsOfExperience} {strings.setupAvatarYearsExp}
            </p>
          </div>
          <div className={s.specializationTitle}>
            {specializations &&
              specializations.map((item, index) => (
                <span key={index}>{item}</span>
              ))}
          </div>
        </div>
      </div>
      <div className={s.langTabBlock}>
        {userData &&
          userData!.preferredLanguages &&
          userData!.preferredLanguages.length > 1 &&
          userData!.preferredLanguages.map((item, index) => (
            <div
              key={index}
              className={
                selectLangForVideo === ISO639LangReversed[item]
                  ? s.activeLangTab
                  : s.notActiveLangTab
              }
              onClick={() => setSelectLangForVideo(ISO639LangReversed[item])}
            >
              {
                //@ts-expect-error
                strings[item]
              }
              {selectLangForVideo === ISO639LangReversed[item] && (
                <span className={s.langTabIndicator}></span>
              )}
            </div>
          ))}
      </div>
      <div className={s.videoContainer}>
        <div className={s.videoBlcok}>
          {video && video.find((el) => el.language === selectLangForVideo) ? (
            <div style={{ position: "relative" }}>
              <video
                controls
                className={s.video}
                key={video[0].file.name}
                src={URL.createObjectURL(
                  video.find((el) => el.language === selectLangForVideo)?.file!
                )}
              ></video>
              <label className={s.fileInputBlock}>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept={videoExtensions.join(", ")}
                  onChange={(e) => handleVideoChange(e)}
                />
                <div className={s.editMovieIcon} role="button">
                  <img src={EditIcon} alt="" />
                </div>
              </label>
            </div>
          ) : (
            <label className={s.fileInputBlock}>
              <input
                style={{ display: "none" }}
                type="file"
                accept={videoExtensions.join(", ")}
                onChange={(e) => handleVideoChange(e)}
              />
              <div className={s.videoInputPlaceholder}>
                <img src={MovieIcon} alt="" />
              </div>
            </label>
          )}
        </div>
        <div className={s.aboutVideoTitleBlock}>
          {strings.setupUploadVideoDescription}
        </div>
      </div>
      {avatar && (
        <CropAvatarModal
          avatar={avatar}
          modalVisible={settingsAvatarModal}
          setAvatar={setAvatar}
          setModalVisible={setSettingsAvatarModal}
        />
      )}
    </div>
  );
};

export default SubSteps2;
