import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import ProjectApi from "../../../api/projectApi";
import SessionsApi from "../../../api/sessionApi";
import SpecialistApi from "../../../api/specialistApi";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import NavigateToProfile from "../../../components/NavigateToProfile/NavigateToProfile";
import RealTimeSessionModalContent from "../../../components/RealTimeSessionModalContent/RealTimeSessionModalContent";
import SelectSessionDate from "../../../components/SelectSessionDate/SelectSessionDate";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { ProjectsAvailableSessionTypes } from "../../../types/Projects";
import { TypeUserData } from "../../../types/TypeUsers";
import { createChat } from "../../../utils/general";
import { getTranslation } from "../../../utils/getTranslation";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import PackageInfo from "../../PackageInfo/PackageInfo";
import s from "./ProgramSpecialistCard.module.css";

type ProgramSpecialistCardProps = {
  bgColor: string;
  projectId: string;
  discount: number;
  specialistUserData: TypeUserData;
  projectType: ProjectsAvailableSessionTypes;
  allSpecialistIds: string[];
  bookingsAmount: number;
};

const ProgramSpecialistCard = ({
  specialistUserData,
  bgColor,
  projectId,
  discount,
  projectType,
  allSpecialistIds,
  bookingsAmount,
}: ProgramSpecialistCardProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const navigate = useNavigate();
  const [bookingModalIsOpen, setBookingModalIsOpen] = useState(false);
  const [overlapTime, setOverlapTime] = useState<{
    [id: string]: { start: Date; end: Date }[];
  }>();

  const [packageId, setPackageId] = useState<string | undefined>();
  const [previewPackageModal, setPreviewPackageModal] = useState(false);
  const [realTimeSessionModal, setRealTimeSessionModal] = useState(false);
  const [sessionPrice, setSessionPrice] = useState<{
    priceWithDiscount: string;
    generalPrice: string;
  } | null>(null);
  const [realTimeError, setRealTimeError] = useState("");
  const currentSpecialistsIds = specialistUserData.specialistIds!.map(
    (item) => item._id
  );
  const thisSpecialistId = allSpecialistIds.filter((el) =>
    currentSpecialistsIds.includes(el)
  )[0];

  useEffect(() => {
    if (projectType !== ProjectsAvailableSessionTypes.PACKAGE) return;
    (async () => {
      if (!token || !thisSpecialistId) return;
      const response = await ProjectApi.getProjectPackageOfSpecialist(
        token,
        projectId,
        thisSpecialistId
      );

      if (response.status && response.package) {
        const fullPrice = response.package.packageCost / 100;

        const discountPrice =
          discount === 100
            ? 0
            : discount === 0
            ? fullPrice
            : (fullPrice - fullPrice * (discount / 100)).toFixed(0);
        setSessionPrice({
          priceWithDiscount: discountPrice.toString(),
          generalPrice: fullPrice.toString(),
        });
        setPackageId(response.package._id);
      }
    })();
  }, [projectId, allSpecialistIds]);

  useEffect(() => {
    if (
      !specialistUserData ||
      !specialistUserData.specialistIds ||
      !specialistUserData.specialistIds[0].price ||
      projectType === ProjectsAvailableSessionTypes.PACKAGE
    )
      return;
    const price = specialistUserData.specialistIds[0].price;

    const newPrice =
      discount === 100
        ? 0
        : discount === 0
        ? price
        : (price - price * (discount / 100)).toFixed(0);

    setSessionPrice({
      priceWithDiscount: newPrice.toString(),
      generalPrice: price.toString(),
    });
  }, [discount, specialistUserData]);

  useEffect(() => {
    const makeAsync = async () => {
      if (
        !token ||
        !specialistUserData ||
        !specialistUserData._id ||
        !specialistUserData.specialistIds
      )
        return;
      const response = await SpecialistApi.getSpecialistFreeTime(
        token,
        specialistUserData._id,
        specialistUserData.specialistIds[0]!._id
      );

      if (response.status) {
        const allOverlaps: { start: Date; end: Date }[] =
          response.overlapDays.flatMap((overlap) =>
            overlap.overlaps.flatMap((dayOverlap) => dayOverlap.overlapTime)
          );

        setOverlapTime({
          [specialistUserData.specialistIds[0]!._id]: allOverlaps,
        });
      }
    };
    makeAsync();
  }, [specialistUserData]);

  const bookingHandler = async (
    sessionBookingData: { date: Date | string; duration: number }[]
  ) => {
    if (
      token &&
      userData &&
      userData._id &&
      specialistUserData &&
      sessionBookingData.length
    ) {
      const response = await SessionsApi.bookSession(
        {
          datesAndTime: sessionBookingData,
          specialistUserId: specialistUserData._id,
          userId: userData._id,
          specialistId: thisSpecialistId,
          projectId: projectId,
        },
        token
      );

      if (!response.status && !response.notEnoughBalance) {
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
      if (!response.status && response.notEnoughBalance && response.message) {
        Notify(response.message);
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
      if (response.status) {
        createChat(userData, specialistUserData?._id);
        setBookingModalIsOpen(false);
        SuccesNotify(strings.bookingSucces);
        return {
          status: response.status,
          notEnoughBalance: response.notEnoughBalance,
        };
      }
    }
    return { status: false, notEnoughBalance: false };
  };

  const sendRealTimeSessionRequest = async (
    sessionSubject: string,
    sessionTags: string[],
    setSubjectValue: Dispatch<SetStateAction<string>>,
    specialistId: string
  ) => {
    if (!token || !userData || !specialistUserData) return { status: false };
    const payload = {
      specialistUserId: specialistUserData._id,
      specialistId: specialistId,
      userId: userData?._id,
      subject: sessionSubject,
      projectId: projectId,
      tags: sessionTags,
    };
    const response = await SpecialistApi.sendRealTimeSessionRequest(
      token,
      payload
    );

    if (!response.status && !response.notEnoughBalance) {
      setRealTimeError(response.message!);
      return {
        status: response.status,
        notEnoughBalance: response.notEnoughBalance,
      };
    }
    if (!response.status && response.notEnoughBalance && response.message) {
      return {
        status: response.status,
        notEnoughBalance: response.notEnoughBalance,
      };
    }

    if (response.status) {
      navigate("/global-chat", {
        state: {
          userId: specialistUserData._id,
        },
      });
      setSubjectValue("");
      setRealTimeError("");
      setRealTimeSessionModal(false);
      return {
        status: response.status,
        notEnoughBalance: response.notEnoughBalance,
      };
    }
    return {
      status: response.status,
    };
  };

  return (
    <div className={s.container} style={{ backgroundColor: bgColor }}>
      <div className={s.mainSpecInfoBlock}>
        <NavigateToProfile
          userId={specialistUserData._id}
          state={{ projectId: projectId }}
        >
          <CircleAvatar
            userId={specialistUserData._id}
            width={window.innerWidth <= 768 ? "40px" : "70px"}
            height={window.innerWidth <= 768 ? "40px" : "70px"}
          />
        </NavigateToProfile>
        <NavigateToProfile
          userId={specialistUserData._id}
          state={{ projectId: projectId }}
        >
          <div className={s.mainSpecTitleInfo}>
            <span>
              {getTranslation(
                specialistUserData.name,
                userData?.selectedLanguage
              ) +
                " " +
                getTranslation(
                  specialistUserData.surname,
                  userData?.selectedLanguage
                )}
            </span>
          </div>{" "}
        </NavigateToProfile>
      </div>
      <div className={s.specalizationTitleBlock}>
        <span>
          {specialistUserData
            .specialistIds!.find((el) => el._id === thisSpecialistId)
            ?.mainSpecializations[0].labels.find(
              (el) => el.language === userData?.selectedLanguage
            )?.text ?? ""}
        </span>
      </div>
      <div className={s.languagesBlock}>
        {specialistUserData &&
          specialistUserData.preferredLanguages?.map((item, index) => (
            <span key={index}>
              {item +
                (index === specialistUserData.preferredLanguages!.length - 1
                  ? ""
                  : ", ")}
            </span>
          ))}
      </div>
      {sessionPrice && (
        <div className={s.prcieBlock}>
          <span>{sessionPrice.priceWithDiscount} $</span>
          {discount > 0 && <span>{sessionPrice.generalPrice}</span>}
        </div>
      )}
      <div className={s.bookingBlock}>
        {projectType !== ProjectsAvailableSessionTypes.PACKAGE ? (
          <div className={s.sessionBookingBlock}>
            {bookingsAmount ? (
              <StandartButton
                action={() => setBookingModalIsOpen(true)}
                buttonTitle={strings.specCardBookingBtn}
                width="130px"
              />
            ) : null}
            {specialistUserData.specialistIds!.find(
              (el) => el._id === thisSpecialistId
            )?.receiveRealTimeOffers && bookingsAmount ? (
              <StandartButton
                action={() => setRealTimeSessionModal(true)}
                buttonTitle={strings.specCardStratNowBtn}
                width="130px"
              />
            ) : null}
          </div>
        ) : projectType === ProjectsAvailableSessionTypes.PACKAGE &&
          packageId ? (
          bookingsAmount ? (
            <StandartButton
              action={() => setPreviewPackageModal(true)}
              buttonTitle={strings.showPackageBtn}
              width="fit-content"
            />
          ) : null
        ) : null}
      </div>
      <ModalWindow
        isOpen={previewPackageModal}
        setIsOpen={setPreviewPackageModal}
        bgColor="var(--gray-bg-color)"
        width="90vw"
      >
        <PackageInfo
          propsPackageId={packageId}
          discountPrice={sessionPrice?.priceWithDiscount}
          projectId={projectId}
        />
      </ModalWindow>
      {sessionPrice && (
        <ModalWindow
          isOpen={realTimeSessionModal}
          setIsOpen={setRealTimeSessionModal}
          width="fit-content"
        >
          <RealTimeSessionModalContent
            allSpecializations={specialistUserData.specialistIds!.map(
              (item) => ({
                specialistId: item._id,
                price: Number(sessionPrice.priceWithDiscount),
                specialization: item.mainSpecializations[0].specialization,
                lable:
                  item.mainSpecializations![0].labels.find(
                    (el) => el.language === userData?.selectedLanguage
                  )?.text ?? item.mainSpecializations[0].specialization,
                tags: item.tags,
              })
            )}
            initialSpecialization={thisSpecialistId}
            fullName={
              specialistUserData.name + " " + specialistUserData.surname
            }
            realTimeError={realTimeError}
            sendRequestHandler={sendRealTimeSessionRequest}
            specialistUserId={specialistUserData._id}
            isShowingSpecializationsTab={false}
          />
        </ModalWindow>
      )}
      {sessionPrice && overlapTime && (
        <ModalWindow
          isOpen={bookingModalIsOpen}
          setIsOpen={setBookingModalIsOpen}
          width="fit-content"
        >
          <SelectSessionDate
            allSpecializations={specialistUserData.specialistIds!.map(
              (item) => ({
                specialistId: item._id,
                price: Number(sessionPrice.priceWithDiscount),
                bookingTimeStep: item.bookingTimeStep,
                specialization: item.mainSpecializations[0].specialization,
                lable:
                  item.mainSpecializations![0].labels.find(
                    (el) => el.language === userData?.selectedLanguage
                  )?.text ?? item.mainSpecializations[0].specialization,
                tags: item.tags,
              })
            )}
            showDateInputs={false}
            bookingHandler={bookingHandler}
            overlapTime={overlapTime}
            userId={specialistUserData._id}
            fullname={
              getTranslation(
                specialistUserData.name,
                userData?.selectedLanguage
              ) +
              " " +
              getTranslation(
                specialistUserData.surname,
                userData?.selectedLanguage
              )
            }
            projectId={projectId}
            isShowingSpecializationsTab={false}
          />
        </ModalWindow>
      )}
    </div>
  );
};

export default ProgramSpecialistCard;
