import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import NotificationsApi from "../../api/notifications";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import CircleAvatar from "../CircleAvatar/CircleAvatar";
import s from "./NotifieElement.module.css";

type NotifiesListProps = {
  notifyId: string[];
  userId: string | undefined;
  title: string;
  fullName: string | undefined;
  date: Date;
  projectEntryCode?: string;
  companyEntryCode?: string;
  messageFrom?: string;
};

const NotifieElement = ({
  userId,
  title,
  fullName,
  date,
  notifyId,
  companyEntryCode,
  projectEntryCode,
  messageFrom,
}: NotifiesListProps) => {
  const { userData, setNotifiesData, notifiesData } =
    useContext(ContextProvider);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const markedReadNotify = async () => {
    if (token) {
      const payload = {
        notifiesIds: notifyId,
      };
      const response = await NotificationsApi.markNotifyAsRead(token, payload);
      if (response.status && messageFrom) {
        const updatedNotifiesData = notifiesData.filter(
          (notification) => !notification._id.includes("Message from")
        );
        setNotifiesData(updatedNotifiesData);
        navigate("/global-chat", {
          state: {
            userId: messageFrom,
          },
        });
        return;
      }

      if (response.status && !messageFrom) {
        const dataIndex = notifiesData.findIndex((obj) => obj._id === "Other");
        const updateData = notifiesData[dataIndex].notifies.filter(
          (notification) => notification._id !== notifyId[0]
        );
        setNotifiesData((prevData) => {
          const newData = [...prevData];
          newData[dataIndex] = { ...newData[dataIndex], notifies: updateData };
          return newData;
        });
      }
    }
  };

  const joinProgram = (code: string) => {
    navigate(`join-project/${code}`);
    markedReadNotify();
  };
  const joinCompany = (code: string) => {
    navigate(`join-company/${code}`);
    markedReadNotify();
  };

  return (
    <div className={s.conatiner} onClick={markedReadNotify}>
      <div className={s.headBlock}>
        {fullName ? (
          <div className={s.userInfoBlock}>
            <CircleAvatar
              userId={userId ? userId : userData?._id!}
              fontSize="20px"
            />
            {fullName && !fullName.includes("undefiend") && (
              <span>{fullName}</span>
            )}
          </div>
        ) : (
          <div></div>
        )}
        <span className={s.timeBlock}>
          {new Date(date).getHours().toString().padStart(2, "0")}:
          {new Date(date).getMinutes().toString().padStart(2, "0")}
        </span>
      </div>
      <div className={s.notifyContentBlock}>
        <span className={s.notifyTitle}>{title}</span>
        <div className={s.notifyIndicator}>
          <span></span>
        </div>
      </div>
      {(projectEntryCode || companyEntryCode) && (
        <div className={s.aseeptInviteBlock}>
          <button
            className={s.acceptButton}
            onClick={(e) => {
              e.stopPropagation();
              projectEntryCode
                ? joinProgram(projectEntryCode)
                : companyEntryCode
                ? joinCompany(companyEntryCode)
                : console.log();
            }}
          >
            {strings.notifiesAcceptBtn}
          </button>
          <button
            className={s.declineButton}
            onClick={() => markedReadNotify()}
          >
            {strings.notifiesDeclimeBtn}
          </button>
        </div>
      )}
    </div>
  );
};

export default NotifieElement;
