import Hamburger from "hamburger-react";
import { useContext, useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DialogChatAPI from "../../api/dialogChatApi";
import NotificationsApi from "../../api/notifications";
import { ReactComponent as ArrowDown } from "../../assets/Header/Arrow-Down.svg";
import { ReactComponent as ProfileIcon } from "../../assets/Header/profile.svg";
import { ReactComponent as SwitchIcon } from "../../assets/Header/switch.svg";
import Logo from "../../assets/logo.png";
import { ReactComponent as SubscriptionsIcon } from "../../assets/Subscriptions/subscriptions-menu.svg";
import { leftHeaderMenu } from "../../constans/header-menu";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import Subscriptions from "../../pages/Subscriptions/Subscriptions";
import { RootState } from "../../redux-toolkit/store";
import { NotificationsData } from "../../types/Notifications";
import { Roles } from "../../types/TypeUsers";
import { getTranslation } from "../../utils/getTranslation";
import BalanceHistoryList from "../BalanceHistoryCard/BalanceHistoryList";
import CircleAvatar from "../CircleAvatar/CircleAvatar";
import MobileNavMenu from "../MobileNavMenu/MobileNavMenu";
import ModalWindow from "../ModalWindow/ModalWindow";
import NavigateToProfile from "../NavigateToProfile/NavigateToProfile";
import NotifieElement from "../NotifiesList/NotifieElement";
import Replenish from "../Replenish/Replenish";
import StripeProvider from "../StripeProvider/StripeProvider";
import s from "./Header.module.css";

const Header = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const isSpecialistStored = localStorage.getItem("isSpecialist");
  const isSpecialist =
    isSpecialistStored !== null ? JSON.parse(isSpecialistStored) : false;
  const avatar = useSelector((state: RootState) => state.user.avatar);
  const location = useLocation();
  const { userData, notifiesData, setNotifiesData } =
    useContext(ContextProvider);
  const [notifiesIsOpen, setNotifiesIsOpen] = useState(false);
  const notifiesRef = useRef<any>(null);
  const mobileMenu = useRef<any>(null);
  const profileMenuRef = useRef<any>(null);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1300);
  const [unreadMesssageAmount, setUnreadMesssageAmount] = useState(0);
  const [balanceModalOpen, setBalanceModalOpen] = useState(false);
  const [balanceModalActiveTab, setBalanceModalActiveTab] = useState<
    "history" | "replenish"
  >("history");
  const [profileMenuCollapsed, setProfileMenuCollapsed] = useState(true);
  const [subscriptionsModal, setSubscriptionsModal] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsScreenSmall(window.innerWidth < 1300 && window.innerWidth > 768);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClickOutside = (event: any) => {
    if (
      !notifiesRef.current.contains(event.target) &&
      !event.target.classList.contains(s.menuIcon)
    ) {
      setNotifiesIsOpen(false);
    }
  };

  const handleClickProfileMenuOutside = (event: any) => {
    const target = event?.target;
    if (
      target &&
      profileMenuRef.current &&
      !profileMenuRef.current.contains(target)
    ) {
      if (!target.classList || !target.classList.contains(s.profileMenuBtn)) {
        setProfileMenuCollapsed(true);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickProfileMenuOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickProfileMenuOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const markedReadNotify = async () => {
    if (token) {
      const notifiesIdArr = notifiesData
        .map((item) => item.notifies.map((item) => item._id))
        .flat();
      const payload = {
        notifiesIds: notifiesIdArr,
      };
      const response = await NotificationsApi.markNotifyAsRead(token, payload);

      if (response.status) setNotifiesData([]);
    }
  };

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !userData) return;
      const unreadMessagesResponse =
        await DialogChatAPI.getUnreadDialogMessagesLength(token, userData._id);

      if (unreadMessagesResponse.status) {
        setUnreadMesssageAmount(unreadMessagesResponse.amount);
      }
    };
    const intervalId = setInterval(makeAsync, 3000);
    return () => clearInterval(intervalId);
  }, [token, userData]);

  const countNotifications = (data: NotificationsData[]): number => {
    let count = 0;
    data.forEach((item) => {
      if (item._id.includes("Other")) {
        count += item.notifies.length;
      }

      if (item._id.includes("Message from")) {
        count++;
      }
    });

    return count;
  };

  const switchProfileMode = (isSpec: boolean) => {
    localStorage.setItem("isSpecialist", (!isSpec).toString());
    navigate("/home");
    window.location.reload();
  };

  const getBalanceOfUser = () => {
    if (!userData || userData.balance === undefined) return "";
    if (userData.balance === 0) return 0;
    return Math.floor(userData.balance / 100);
  };
  return (
    <div
      className={
        location.pathname.includes("session-meet") && isScreenSmall
          ? s.sessionTabletHeader
          : s.container
      }
    >
      <div className={s.menuBlock}>
        <div className={s.logoBlock}>
          <Link to={"/home"}>
            <img src={Logo} alt="" />
          </Link>
        </div>

        <div className={s.leftMenuBlock}>
          {leftHeaderMenu.map((item, index) => (
            <div className={s.leftMenuElement} key={index}>
              <span
                className={
                  (item.path && location.pathname.includes(item.path)) ||
                  (item.title === "notifies" && notifiesIsOpen)
                    ? s.activeMenuIcon
                    : s.menuIconBlock
                }
                onClick={
                  item.title === "notifies"
                    ? () => setNotifiesIsOpen((prevState) => !prevState)
                    : () => {
                        return;
                      }
                }
              >
                {item.path ? (
                  <Link to={item.path} key={index}>
                    <item.icon
                      className={
                        location.pathname.includes(item.path)
                          ? `${s.menuIcon} ${s.activeMenuItem}`
                          : `${s.menuIcon}`
                      }
                    />
                  </Link>
                ) : (
                  <item.icon className={s.menuIcon} />
                )}
              </span>
              {countNotifications(notifiesData) &&
              notifiesData.length &&
              item.title === "notifies" ? (
                <span className={s.newNotifiesIndicator}>
                  {countNotifications(notifiesData)}
                </span>
              ) : null}
              {unreadMesssageAmount && item.title === "global-chat" ? (
                <span className={s.newNotifiesIndicator}>
                  {unreadMesssageAmount}
                </span>
              ) : null}
            </div>
          ))}

          <div className={s.burgerBlock}>
            <Hamburger
              toggled={mobileMenuIsOpen}
              toggle={setMobileMenuIsOpen}
              color="black"
              size={32}
            />
          </div>
          <AnimateHeight
            height={notifiesIsOpen ? "auto" : 0}
            className={s.notifiesListContainer}
            ref={notifiesRef}
          >
            <div className={s.notifiesContent}>
              <div className={s.notifiesHeader}>
                <h3>{strings.notifiesTitle}</h3>
                <span
                  className={s.markAllButton}
                  role="button"
                  onClick={markedReadNotify}
                >
                  {strings.allRead}
                </span>
              </div>

              {notifiesData.length && userData ? (
                notifiesData.map((item, index) =>
                  item._id.includes("Message from") ? (
                    <NotifieElement
                      key={index}
                      notifyId={item.notifies.map((item) => item._id)}
                      date={
                        item.notifies.sort(
                          (a, b) =>
                            new Date(b.date).getTime() -
                            new Date(a.date).getTime()
                        )[0].date
                      }
                      title={
                        item.notifies[0].labels.find(
                          (item) => item.language === userData?.selectedLanguage
                        )?.text!
                      }
                      userId={item.notifies[0].from?._id}
                      companyEntryCode={item.notifies[0].companyEntryCode}
                      projectEntryCode={item.notifies[0].projectEntryCode}
                      fullName={
                        item.notifies[0].from
                          ? getTranslation(
                              item.notifies[0].from?.name,
                              userData.selectedLanguage
                            ) +
                            " " +
                            getTranslation(
                              item.notifies[0].from?.surname,
                              userData.selectedLanguage
                            )
                          : undefined
                      }
                      messageFrom={item.notifies[0].messageFrom}
                    />
                  ) : (
                    item.notifies.map((item) => (
                      <NotifieElement
                        key={item._id}
                        notifyId={[item._id]}
                        date={item.date}
                        title={
                          item.labels.find(
                            (item) =>
                              item.language === userData?.selectedLanguage
                          )?.text!
                        }
                        userId={item.from?._id}
                        companyEntryCode={item.companyEntryCode}
                        projectEntryCode={item.projectEntryCode}
                        messageFrom={item.messageFrom}
                        fullName={
                          item.from && item.from.name && item.from.surname
                            ? getTranslation(
                                item.from?.name,
                                userData.selectedLanguage
                              ) +
                              " " +
                              getTranslation(
                                item.from?.surname,
                                userData.selectedLanguage
                              )
                            : undefined
                        }
                      />
                    ))
                  )
                )
              ) : (
                <h2 className={s.noNotifiesTitle}>{strings.noNotifies}</h2>
              )}
            </div>
          </AnimateHeight>
        </div>
        <div className={s.rightMenuBlock}>
          <div
            className={s.balanceBlock}
            role="button"
            onClick={() => setBalanceModalOpen(true)}
          >
            <span>{getBalanceOfUser()} $</span>
          </div>

          {/*           {userData && userData.roles!.includes(Roles.SPECIALIST) ? ( */}
          <div
            className={s.profileMenuBlock}
            role="button"
            onClick={() => setProfileMenuCollapsed((prev) => !prev)}
            ref={profileMenuRef}
          >
            <CircleAvatar
              avatarProps={avatar === "" ? userData?.avatar : avatar}
              userId={userData?._id}
              height="40px"
              width="40px"
              fontSize="18px"
            />
            <div className={s.nameBlock}>
              <span>
                {getTranslation(userData?.name, userData?.selectedLanguage)}
              </span>
              <span>
                {isSpecialist
                  ? strings.specialistHeaderTitle
                  : strings.clientHeaderTitle}
              </span>
            </div>
            <ArrowDown />
            <AnimateHeight
              height={profileMenuCollapsed ? 0 : "auto"}
              className={profileMenuCollapsed ? s.hiddenMenu : s.profileMenu}
            >
              <NavigateToProfile
                userId={userData!._id}
                width="calc(100% - 20px)"
              >
                <div
                  className={s.profileMenuBtn}
                  style={{
                    borderRadius: "20px 20px 0 0",
                    width: "calc(100% - 20px)",
                  }}
                >
                  <ProfileIcon
                    className={`${s.profileMenuIcon} ${s.hoverIcon}`}
                  />

                  {userData &&
                  userData.specialistIds &&
                  userData.specialistIds.length
                    ? isSpecialist
                      ? strings.specialistHeaderTitle2
                      : strings.clientHeaderTitle2
                    : strings.myProfileLink}
                </div>
              </NavigateToProfile>
              {userData && userData.roles!.includes(Roles.SPECIALIST) && (
                <div
                  role="button"
                  onClick={() => switchProfileMode(isSpecialist)}
                  className={s.profileMenuBtn}
                >
                  <SwitchIcon
                    className={`${s.profileMenuIcon} ${s.hoverIcon}`}
                  />
                  {isSpecialist
                    ? strings.switchToClient
                    : strings.switchToSpecProfile}
                </div>
              )}
              <div
                role="button"
                onClick={() => setSubscriptionsModal(true)}
                className={s.profileMenuBtn}
                style={{ borderRadius: "0 0 20px 20px" }}
              >
                <SubscriptionsIcon className={s.profileMenuIcon} />
                {strings.subscriptions}
              </div>
            </AnimateHeight>
          </div>
        </div>
      </div>
      <div className={s.mobileMenu}>
        <AnimateHeight height={mobileMenuIsOpen ? "auto" : 0} ref={mobileMenu}>
          <MobileNavMenu
            setMobileMenuIsOpen={setMobileMenuIsOpen}
            setSubscriptionsModal={setSubscriptionsModal}
            setBalanceModalOpen={setBalanceModalOpen}
          />
        </AnimateHeight>
      </div>
      <ModalWindow
        isOpen={balanceModalOpen}
        setIsOpen={setBalanceModalOpen}
        padding="10px 10px 0 0"
        width="fit-content"
        maxWidth="fit-content"
      >
        <div className={s.balanceNodalContainer}>
          <div className={s.balanceTabsBlock}>
            <div className={s.tabsContainer}>
              <div
                className={
                  balanceModalActiveTab === "history"
                    ? `${s.balanceTab} ${s.activeBalanceTab}`
                    : `${s.balanceTab}`
                }
                role="button"
                onClick={() => setBalanceModalActiveTab("history")}
              >
                {strings.history}
              </div>
              <div
                className={
                  balanceModalActiveTab === "replenish"
                    ? `${s.balanceTab} ${s.activeBalanceTab}`
                    : `${s.balanceTab}`
                }
                role="button"
                onClick={() => setBalanceModalActiveTab("replenish")}
              >
                {strings.replenish}
              </div>
            </div>
            <span className={s.balanceUnderline}></span>
          </div>
          {balanceModalActiveTab === "history" ? (
            <div className={s.historyBlock}>
              <div className={s.balanceValueBlock}>
                <span>{strings.balance}</span>
                <div className={s.balanceValueContainer}>
                  <span>{getBalanceOfUser()} $</span>
                </div>
              </div>
              <div>
                <BalanceHistoryList />
              </div>
            </div>
          ) : (
            <div className={s.replenishBlock}>
              <StripeProvider>
                <Replenish />
              </StripeProvider>
            </div>
          )}
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={subscriptionsModal}
        setIsOpen={setSubscriptionsModal}
        width="fit-content"
        padding="20px"
        bgColor="white"
        showCloseBtn={false}
      >
        <Subscriptions setModalVisible={setSubscriptionsModal} />
      </ModalWindow>
    </div>
  );
};

export default Header;
