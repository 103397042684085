import React, { PropsWithChildren } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";

const stripeToken = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const stripePromise: Promise<Stripe | null> = loadStripe(stripeToken!);

const StripeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;
