import { ReactNode, useEffect, useState } from "react";
import UsersApi from "../../api/usersApi";

type NavigateToProfileProps = {
  children: ReactNode;
  userId: string;
  height?: string;
  width?: string;
  state?: Record<string, any>;
};

const NavigateToProfile = ({
  children,
  userId,
  height,
  width,
  state,
}: NavigateToProfileProps) => {
  const token = localStorage.getItem("token");
  const [latinFullname, setLatinFullname] = useState<{
    latinName: string;
    latinSurname: string;
  } | null>(null);

  useEffect(() => {
    if (!token) return;
    const makeAsync = async () => {
      setLatinFullname(null);
      const userDataResponse = await UsersApi.getUserPublicAvatarAndName(
        userId
      );

      if (userDataResponse.latinName && userDataResponse.latinSurname) {
        setLatinFullname({
          latinName: userDataResponse.latinName,
          latinSurname: userDataResponse.latinSurname,
        });
      }
    };
    makeAsync();
  }, [token, userId]);

  const navigateToUserPage = () => {
    if (!latinFullname) return;
    const projectIdParams = state?.projectId
      ? `?projectId=${state?.projectId}`
      : "";
    const url = `/${latinFullname.latinName}-${
      latinFullname.latinSurname
    }-${userId.slice(-7)}${projectIdParams}`;
    window.open(url, "_blank");
  };

  return (
    <div
      onClick={navigateToUserPage}
      style={{ height: height ?? "fit-content", width: width ?? "fit-content" }}
    >
      {children}
    </div>
  );
};

export default NavigateToProfile;
