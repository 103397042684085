// src/store/userSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ModalState {
  sessionCommentModal: boolean;
}

const initialState: ModalState = {
  sessionCommentModal: false,
};

const modalsSlice = createSlice({
  name: "modalVisible",
  initialState,
  reducers: {
    setCommentModalVisible: (state, action: PayloadAction<boolean>) => {
      state.sessionCommentModal = action.payload;
    },
  },
});

export const { setCommentModalVisible } = modalsSlice.actions;
export default modalsSlice.reducer;
