import React, { Dispatch, SetStateAction } from "react";
import s from "./RightMenu.module.css";
import { ReactComponent as ChatIcon } from "../../../assets/VideoSession/chat.svg";
import { ReactComponent as TasksIcon } from "../../../assets/VideoSession/todo.svg";
import { ReactComponent as NotesIcon } from "../../../assets/VideoSession/notes.svg";

type TypeRightMenuProps = {
  toolsState: { chat: boolean; tasks: boolean; notations: boolean };
  setToolsState: Dispatch<
    SetStateAction<{ chat: boolean; tasks: boolean; notations: boolean }>
  >;
};
interface MenuElement {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  action: () => void;
  toolName: keyof TypeRightMenuProps["toolsState"];
}
const RightMenu = ({ toolsState, setToolsState }: TypeRightMenuProps) => {
  const menuElements: MenuElement[] = [
    {
      icon: ChatIcon,
      action: () =>
        setToolsState((prev) => ({
          chat: !prev.chat,
          tasks: false,
          notations: false,
        })),
      toolName: "chat",
    },
    {
      icon: TasksIcon,
      action: () =>
        setToolsState({
          chat: false,
          tasks: !toolsState.tasks,
          notations: false,
        }),
      toolName: "tasks",
    },
    {
      icon: NotesIcon,
      action: () =>
        setToolsState({
          chat: false,
          tasks: false,
          notations: !toolsState.notations,
        }),
      toolName: "notations",
    },
  ];
  return (
    <div className={s.container}>
      {menuElements.map((item, index) => (
        <div
          className={`${toolsState[item.toolName] ? s.activeIconBg : s.iconBg}`}
          onClick={item.action}
          key={index}
          role="button"
        >
          <item.icon
            className={`${toolsState[item.toolName] ? s.activeIcon : s.icon}`}
          />
        </div>
      ))}
    </div>
  );
};

export default RightMenu;
