import s from "./Chat.module.css";
import FileIcon from "../../../assets/VideoSession/file.svg";

type FilePreviewProps = {
  file: File;
  onDelete: () => void;
};

const FilePreview = ({ file, onDelete }: FilePreviewProps) => {
  const isImage = file.type.startsWith("image");

  return (
    <div className={s.filePreview}>
      {isImage ? (
        <img src={URL.createObjectURL(file)} alt="Preview" />
      ) : (
        <img src={FileIcon} alt="" />
      )}
      <button onClick={onDelete} className={s.deleteButton}>
        x
      </button>
    </div>
  );
};
export default FilePreview;
