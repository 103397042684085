import { SpecialistLevels } from "./Subscription";
import { Label } from "./TypeQuieze";
import { TypeUserData, UserSchedule } from "./TypeUsers";

export type UploadIntroVideoPayload = {
  file: FormData;
  specialistId: string;
};
export type UploadCertificatesPayload = {
  files: FormData;
  specialistId: string;
};
export type SubSpecializations = {
  _id: string;
  specialization: string;
  relatedMainSpec: MainSpecializations[];
  labels: { text: string; language: string }[];
};

export type MainSpecializations = {
  _id: string;
  specialization: string;
  labels: { text: string; language: string }[];
};

type VideoMetadata = {
  _id: string;
  length: number;
  chunkSize: number;
  uploadDate: string;
  filename: string;
};

export type SpecialistLevel = {
  _id: string;
  title: { text: string; language: string }[];
  description: { text: string; language: string }[];
  mainSpecialization: MainSpecializations;
  level: string;
};

export type SpecialistData = {
  _id: string;
  mainSpecializations: MainSpecializations[];
  subSpecializations: SubSpecializations[];
  receiveRealTimeOffers: boolean;
  practiceHours?: number;
  yearsOfExperience?: number;
  introductionVideo?: { video: VideoMetadata; language: string }[];
  certificates?: string[];
  educationCertificates: string[];
  federationCertificates: string[];
  qualificationCertificates: string[];
  aboutMyself?: { text: string; language: string }[];
  aboutTherapy?: { text: string; language: string }[];
  contributeToProfession?: { text: string; language: string }[];
  price?: number;
  clients?: string[];
  totalRating?: number;
  rating: number;
  isVerified: boolean;
  isVerifiedChanges: boolean;
  discountPrice?: number;
  tags: SpecialistTag[];
  level?: {
    _id: string;
    title: { text: string; language: string }[];
    description: { text: string; language: string }[];
    level: SpecialistLevels;
    mainSpecialization: string;
  };
  showInSubscriptions?: boolean;
  completedCourses?: number;
  federationMemberships?: number;
  qualificationsFromFederations?: number;
  schedule?: UserSchedule;
  bufferTime?: {
    before: number;
    after: number;
  };
  minimumBookingsGap: number;
  bookingTimeStep: number;
};
export type OverLaps = {
  specialist: string;
  overlaps: {
    day: string;
    overlapTime: {
      start: Date;
      end: Date;
    }[];
  }[];
};
export type Certificates = {
  _id: string;
  filename: string;
  size: number;
  type: string;
  link: string;
};

type Range = {
  min: number;
  max: number;
};
export type ParamsAvailable = {
  genders: string[];
  locations: string[];
  totalAmount: number;
  preferredLanguages: string[];
  mainSpecializations: string[];
  subSpecializations: string[];
  priceRange: Range;
  ageRange: Range;
  practiceHoursRange: Range;
  yearsOfExperienceRange: Range;
  tags: { _id: string; direction: string; title: string; labels: Label[] }[];
};

export type TypeSpecialistFilter = {
  gender?: string | string[];
  age?: { from: number; to: number };
  preferredLanguages?: string | string[];
  specialistId?: {
    subSpecializations?: string | string[];
    mainSpecializations?: string | string[];
    practiceHours?: { from: number; to: number };
    yearsOfExperience?: { from: number; to: number };
    price?: { from: number; to: number };
    isVerified: boolean;
  };
  IsFavourite?: boolean;
  receiveRealTimeOffers?: boolean;
  name?: string;
  page?: number;
  limit?: number;
  time?: string;
};

export interface TimeRange {
  start: Date;
  end: Date;
}

export type SpecialistFreeTimeType = {
  specialist: string;
  overlaps: { day: string; overlapTime: TimeRange[] }[];
};

export type RealTimeBooking = {
  from: TypeUserData;
  for: TypeUserData;
  isAccepted: boolean;
};
export type Specializations = {
  specialization: string;
  labels: { text: string; language: string }[];
};

export enum SpecialistTagsDirections {
  COACHING = "Coaching",
  PSYTHERAPHY = "Psychology",
  MENTORING = "Mentoring",
}

export type SpecialistTag = {
  _id: string;
  title: string;
  labels: { text: string; language: string }[];
  parentTag?: string;
  level: number;
  direction: SpecialistTagsDirections;
};

export type Federation = {
  _id: string;
  text: { text: string; language: string }[];
  isShown: boolean;
  specialization: MainSpecializations;
};

export type Education = {
  _id: string;
  text: { language: string; text: string }[];
  isShown: boolean;
  specialization: MainSpecializations;
};
export type EducationCourse = {
  _id: string;
  text: { language: string; text: string }[];
  isShown: boolean;
  education: Education;
};
export type EducationAccreditation = {
  _id: string;
  text: { language: string; text: string }[];
  isShown: boolean;
  educationCourse: EducationCourse;
};
type MultiLangTextObject = { text: string; language: string };
export type Qualification = {
  _id: string;
  text: { text: string; language: string }[];
  isShown: boolean;
  specialization: MainSpecializations;
};

export type Badge = {
  _id: string;
  file: string;
  description?: string;
  url: string;
  isShown: boolean;
  specialization: MainSpecializations;
  link: string;
  title: MultiLangTextObject[];
};

export type FederationsCertificatesResponse = {
  _id: string;
  filename: string;
  federationProfileUrl: string;
  size: number;
  type: string;
  link: string;
  membershipEnd: string;
  membershipStart: string;
  federation: Omit<Federation, "badge"> & {
    badge: Badge & {
      _id: string;
      filename: string;
      size: number;
      type: string;
      link: string;
    };
  };
};

export type EducationCertificatesResponse = {
  _id: string;
  filename: string;
  size: number;
  type: string;
  link: string;
  membershipEnd: string;
  membershipStart: string;
  description: string;
  education: Education;
  studyHours: number;
  subSpecializations: SubSpecializations[];
  educationCourse: {
    _id: string;
    isShown: boolean;
    text: { language: string; text: string }[];
  };
  educationAccreditation: {
    _id: string;
    isShown: boolean;
    text: { language: string; text: string }[];
  };
};

export type QualificationCertificatesResponse = {
  _id: string;
  filename: string;
  federationProfileUrl: string;
  size: number;
  type: string;
  link: string;
  membershipEnd: string;
  membershipStart: string;
  qualification: Omit<Qualification, "badge"> & {
    badge: Badge & {
      _id: string;
      filename: string;
      size: number;
      type: string;
      link: string;
    };
  };
};
