import LocationOnIcon from "@mui/icons-material/LocationOn";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { debounce } from "@mui/material/utils";
import parse from "autosuggest-highlight/parse";
import * as React from "react";

const GOOGLE_MAPS_API_KEY =
  process.env
    .REACT_APP_GOOGLE_API_KEY; /* "AIzaSyB5UmElFCYIF51aZDDw11r-L97GH-88_qk" */

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  types: string[];
  terms: { offset: number; value: string }[];
}
interface CityPickerProps {
  selectedCountry: string | null;
  onCitySelect: (city: PlaceType | null) => void;
  selectedCity: PlaceType | null;
  placeHolder?: string;
  height?: string;
  defaultValue?: string;
}
export default function CityPicker({
  selectedCountry,
  onCitySelect,
  selectedCity,
  placeHolder,
  height,
  defaultValue,
}: CityPickerProps) {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          const autocompleteOptions: any = {
            input: request.input,
            language: "en",
          };

          if (selectedCountry !== "") {
            autocompleteOptions.componentRestrictions = {
              country: selectedCountry,
            };
          }
          autocompleteOptions.types = ["(cities)"];
          (autocompleteService.current as any).getPlacePredictions(
            autocompleteOptions,
            callback
          );
        },
        400
      ),
    [selectedCountry]
  );
  function createPlaceType(countryCode: string, cityName: string) {
    return {
      description: `${cityName}, ${countryCode}`,
      structured_formatting: {
        main_text: cityName,
        secondary_text: countryCode,
      },
      types: ["(cities)"],
      terms: [
        { offset: 0, value: cityName },
        { offset: cityName.length + 2, value: countryCode },
      ],
    };
  }

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService({
        language: "en",
      });
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  React.useEffect(() => {
    if (defaultValue && !value) {
      const [city, country] = defaultValue.split(", ");
      const defaultValueObj = createPlaceType(country, city);

      setValue(defaultValueObj);
      setInputValue(city);
      onCitySelect(defaultValueObj);
    }
  }, [defaultValue, value, onCitySelect]);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: "100%", height: height ? height : "auto", padding: "2px" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={selectedCity ?? value}
      noOptionsText="No locations"
      onChange={(_, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        onCitySelect(newValue);
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          placeholder={placeHolder ? placeHolder : "City"}
          sx={{ padding: "2px" }}
          style={{ padding: "2px" }}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
