import { CircularProgress, ThemeProvider } from "@mui/material";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CompanyApi from "../../api/companyApi";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { theme } from "../../utils/theme";
import { SuccesNotify } from "../../utils/toaster";
import s from "./JoinCompany.module.css";

const JoinCompany = () => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const navigate = useNavigate();

  useEffect(() => {
    const makeAsync = async () => {
      const urlString = window.location.href;
      const code = urlString.split("join-company/")[1];
      if (token && userData && userData._id) {
        const payload = {
          userId: userData._id,
          code: code,
        };
        const response = await CompanyApi.joinCompany(token, payload);
        if (response.status) {
          localStorage.removeItem("code");
          navigate("/home");
          SuccesNotify(strings.haveJoinCompany);
        }
      } else {
        localStorage.setItem("code", code);
        navigate("/login");
      }
    };
    makeAsync();
  }, []);

  return (
    <div className={s.container}>
      <div className="loader-container">
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    </div>
  );
};

export default JoinCompany;
