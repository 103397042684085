import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  microphone: true,
  camera: true,
};

const videoChatSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    toggleMicrophone: (state) => {
      state.microphone = !state.microphone;
    },
    toggleCamera: (state) => {
      state.camera = !state.camera;
    },
  },
});

export const { toggleMicrophone, toggleCamera } = videoChatSlice.actions;
export default videoChatSlice.reducer;
