import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import strings from "../../localization";
import getCroppedImg from "../../utils/cropImage";
import ModalWindow from "../ModalWindow/ModalWindow";
import StandartButton from "../StandartButton/StandartButton";
import s from "./CropAvatarModal.module.css";

type CropAvatarModalProps = {
  avatar: File;
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  setAvatar: Dispatch<SetStateAction<File | null>>;
};

const CropAvatarModal = ({
  avatar,
  modalVisible,
  setModalVisible,
  setAvatar,
}: CropAvatarModalProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [selectedImage, setSelectedImage] = useState(
    URL.createObjectURL(avatar)
  );
  useEffect(() => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setSelectedImage(URL.createObjectURL(avatar));
  }, [avatar]);

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const saveCroppedImage = async () => {
    if (!avatar) return;
    try {
      const croppedImage = await getCroppedImg(
        URL.createObjectURL(avatar),
        croppedAreaPixels,
        0
      );
      setAvatar(croppedImage);
      setModalVisible(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ModalWindow
      isOpen={modalVisible}
      setIsOpen={setModalVisible}
      width="fit-content"
      showCloseBtn={false}
      alternativeCloseFunction={() => null}
    >
      <div>
        <div className={s.avatarModal}>
          <Cropper
            image={selectedImage}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            style={{
              containerStyle: {
                backgroundColor: "white",
              },
              mediaStyle: {
                width: "100%",
                objectFit: "contain",
              },
            }}
          />
        </div>
        <div className={s.saveAvatarBtnBlock}>
          <StandartButton
            action={saveCroppedImage}
            buttonTitle={strings.saveSheduleModal}
            width="50%"
          />
        </div>
      </div>
    </ModalWindow>
  );
};

export default CropAvatarModal;
