import { useContext, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useLocation } from "react-router-dom";
import Peer from "simple-peer";
import MessageChatApi from "../../api/messageChatApi";
import SessionsApi from "../../api/sessionApi";
import CircleAvatar from "../../components/CircleAvatar/CircleAvatar";
import { network } from "../../config";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import TypeDefaultResponse from "../../types/TypeDefaultResponse";
import { TypeSessionChatResponse } from "../../types/TypeMessageChat";
import { Sessions } from "../../types/TypeSession";
import { TodoList } from "../../types/TypeToDo";
import { TypeUserData } from "../../types/TypeUsers";
import socket from "../../utils/socket";
import BottomMenu from "./BottomMenu/BottomMenu";
import Chat from "./Chat/Chat";
import NotesVideoChat from "./NotesVideoChat/NotesVideoChat";
import RightMenu from "./RightMenu/RightMenu";
import s from "./SessionVideoChat.module.css";
import TodoVideoChat from "./TodoVideoChat/TodoVideoChat";

const { sessions } = network;
const width = window.innerWidth;
const height = window.innerHeight;

const Video = (props: any) => {
  const ref = useRef<any>();
  const { userData } = useContext(ContextProvider);
  const [videoVisible, setVideoVisible] = useState(false);

  useEffect(() => {
    //@ts-expect-error
    props.peer.once("stream", (stream) => {
      if (ref.current) ref.current.srcObject = stream;
    });
    //@ts-expect-error
    props.peer.on("data", (data) => {
      if (data.toString() === "video-false") setVideoVisible(false);
      else if (data.toString() === "video-true") setVideoVisible(true);
    });
  }, []);

  useEffect(() => {
    const audioDeviceId =
      props.outputAudioDevices &&
      props.outputAudioDevices.find(
        (device: { isSelect: boolean }) => device.isSelect === true
      )?.deviseId;
    if (ref.current && audioDeviceId) {
      try {
        ref.current.setSinkId(audioDeviceId);
      } catch (error) {}
    }
  }, [props.outputAudioDevices]);

  return (
    <>
      <video
        className={s.mainVideo}
        autoPlay
        ref={ref}
        style={!videoVisible ? { opacity: 0, position: "absolute" } : {}}
      />
      <div style={videoVisible ? { opacity: 0, position: "absolute" } : {}}>
        <CircleAvatar
          userId={
            props.chatData
              ? props.chatData.avatars.filter(
                  (item: { id: string | undefined }) =>
                    item.id !== userData?._id
                )[0].id
              : ""
          }
          height="40svh"
          width="40svh"
          marginRight="0"
          fontSize="124px"
        />
      </div>
    </>
  );
};

export type DevicesList = {
  title: string;
  deviseId: string;
  isSelect: boolean;
};

const SessionVideoChat = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const { sessionId, specialistId } = location.state;
  const { userData } = useContext(ContextProvider);
  const [myStream, setMyStream] = useState<MediaStream>();
  const [todosData, setTodosData] = useState<TodoList[] | null>(null);
  const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: 0 });
  const [peers, setPeers] = useState<{ id: string; peer: Peer.Instance }[]>([]);
  const [camera, setCamera] = useState(false);

  const [chatData, setChatData] = useState<
    TypeDefaultResponse & {
      avatars: { id: string; avatar: string; specialistIds?: string[] }[];
      chat?: TypeSessionChatResponse | null | undefined;
    }
  >();
  const [toolsState, setToolsState] = useState({
    chat: false,
    tasks: false,
    notations: false,
  });
  const [inputAudioDevices, setInputAudioDevices] = useState<
    DevicesList[] | null
  >(null);
  const [outputAudioDevices, setOutputAudioDevices] = useState<
    DevicesList[] | null
  >(null);
  const [inputVideoDevices, setInputVideoDevices] = useState<
    DevicesList[] | null
  >(null);
  const [sessionInfo, setSessionInfo] = useState<
    | (Sessions & {
        clients: TypeUserData[];
        specialistUsers: TypeUserData[];
      })
    | undefined
  >();

  const peersRef = useRef<any>([]);
  const myVideoRef = useRef<HTMLVideoElement>(null);
  const effectHasRun = useRef(false);
  const myStreamRef = useRef<any>();
  const cameraSwitchRef = useRef<boolean>(false);

  const handleDrag = (_: any, ui: any) => {
    const { x, y } = deltaPosition;
    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };

  useEffect(() => {
    (async () => {
      if (!token || !sessionId) return;
      const response = await SessionsApi.getSessionInfoById(token, sessionId);
      if (response.status && response.session) {
        setSessionInfo(response.session);
      }
    })();
  }, [sessionId]);

  useEffect(() => {
    cameraSwitchRef.current = camera;
    if (!myStream) return;
    const videoTrack = myStream
      .getTracks()
      .find((track) => track.kind === "video");
    if (!videoTrack) return;
    peers.forEach((peerObj: { id: string; peer: Peer.Instance }) => {
      if (
        peerObj &&
        peerObj.peer &&
        !peerObj.peer.destroyed && //@ts-expect-error
        peerObj.peer.channelName
      )
        peerObj.peer.send(`video-${camera}`);
    });
  }, [camera]);

  useEffect(() => {
    if (!myStream) return;
    myStreamRef.current = myStream;

    if (!myVideoRef || !myVideoRef.current) return;
    myVideoRef!.current!.srcObject = myStream;
    peersRef.current.forEach((peer: any) => {
      if (peer && peer.peer) {
        peer.peer.replaceTrack(
          peer.peer.streams[0].getVideoTracks()[0],
          myStream.getVideoTracks()[0],
          peer.peer.streams[0]
        );
        peer.peer.replaceTrack(
          peer.peer.streams[0].getAudioTracks()[0],
          myStream.getAudioTracks()[0],
          peer.peer.streams[0]
        );
      }
    });
  }, [myStream]);

  useEffect(() => {
    const videDeviceId =
      inputVideoDevices &&
      inputVideoDevices.find((device) => device.isSelect === true)?.deviseId;
    const audioDeviceId =
      inputAudioDevices &&
      inputAudioDevices.find((device) => device.isSelect === true)?.deviseId;
    navigator.mediaDevices
      .getUserMedia({
        video: { deviceId: videDeviceId || "" },
        audio: { deviceId: audioDeviceId || "" },
      })
      .then((stream) => {
        if (myStream) {
          const prevVideoTrack = myStream.getVideoTracks()[0];
          const currentVideoTrack = stream.getVideoTracks()[0];
          if (!prevVideoTrack || !prevVideoTrack.enabled) {
            currentVideoTrack.enabled = false;
          } else {
            currentVideoTrack.enabled = true;
          }
          const prevAudioTrack = myStream.getAudioTracks()[0];
          const currentAudioTrack = stream.getAudioTracks()[0];
          if (!prevAudioTrack || !prevAudioTrack.enabled) {
            currentAudioTrack.enabled = false;
          } else {
            currentAudioTrack.enabled = true;
          }
        } else
          stream.getTracks().forEach((track) => {
            track.enabled = false;
          });
        setMyStream(stream);
      });
  }, [inputVideoDevices, inputAudioDevices]);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("token");
      if (userData && userData._id && token) {
        MessageChatApi.joinChat(sessionId, userData?._id);
        const chatDataResponse = await MessageChatApi.getSessionChat(
          sessionId,
          token
        );

        if (chatDataResponse.status && chatDataResponse.chat?.messages)
          if (JSON.stringify(chatData) !== JSON.stringify(chatDataResponse))
            setChatData(chatDataResponse);
      }
    })();
  }, [toolsState.chat]);

  useEffect(() => {
    const makeAsync = async () => {
      setPeers([]);
      peersRef.current = [];
      socket.emit(sessions.disconnectFromSession);
      await new Promise<{ status: boolean }>((resolve) => {
        socket.emit(sessions.disconnectFromSession);
        socket.once("onDisconnectFromSession", (response) => {
          resolve(response);
        });
      });
    };
    makeAsync();
  }, []);

  useEffect(() => {
    const loadModelsAsync = async () => {
      if (
        effectHasRun.current ||
        !myVideoRef ||
        !myVideoRef.current ||
        !myStream
      )
        return;
      myVideoRef!.current!.srcObject = myStream;

      socket.on(sessions.cameraDisconnected, (id) => {
        const index = peersRef.current.findIndex(
          (peer: any) => peer.peerID == id
        );

        if (peersRef.current[index]) peersRef.current[index].peer.destroy();
        setPeers((prev) => prev.filter((el) => el.id !== id));
        peersRef.current = peersRef.current.filter(
          (el: any) => el.peerID !== id
        );
      });

      socket.on(sessions.allUsers, (users: any[]) => {
        const peers: any = [];
        peersRef.current = [];
        users.forEach((user) => {
          if (user.id === socket.id) return;
          const peer = createPeer(user.id, socket.id, myStreamRef.current);
          peersRef.current.push({
            peerID: user.id,
            peer,
          });
          peers.push({ peer, id: user.id });
        });

        setPeers(peers);
      });

      socket.on(sessions.incomingCall, (payload) => {
        if (peersRef.current.find((user: any) => user.peerID === payload.from))
          return;
        const peer = addPeer(payload.signal, payload.from, myStreamRef.current);
        peersRef.current.push({
          peerID: payload.from,
          peer,
        });

        setPeers((users) => [...users, { peer, id: payload.from }]);
      });

      socket.on(sessions.callAccepted, (payload) => {
        try {
          const item = peersRef.current.find(
            //@ts-expect-error
            (p) => p.peerID === payload.from
          );
          if (item && item.peer) {
            item.peer.signal(payload.signal);
          }
        } catch (error) {
          console.log(error);
        }
      });
      socket.emit(sessions.joinRoom, sessionId, userData?._id, specialistId);
      effectHasRun.current = true;
    };

    loadModelsAsync();
  }, [myVideoRef, myStream]);

  function createPeer(userToCall: any, from: any, stream: any) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on("error", (err) => {
      console.log("Error occurred:", err);

      if (err.message === "Connection failed.") {
        console.log("Connection failed. Reconnecting...");
        window.location.reload();
      }
    });

    peer.on("connect", () => {
      const orientation = width > height ? "albom" : "portrait";
      peer.send(`orientation-${orientation}`);
      peer.send(`video-${cameraSwitchRef.current}`);
      peer.send(`video-state-request`);
    });

    peer.on("signal", (signalData) => {
      socket.emit(sessions.callUser, { userToCall, from, signalData });
    });
    return peer;
  }

  function addPeer(signal: any, to: any, stream: any) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    });
    peer.on("error", (err) => {
      console.log("Error occurred:", err);
      if (err.message === "Connection failed.") {
        console.log("Connection failed. Reconnecting...");
        window.location.reload();
      }
    });
    peer.on("connect", () => {
      const orientation = width > height ? "albom" : "portrait";
      peer.send(`orientation-${orientation}`);
      peer.send(`video-${cameraSwitchRef.current}`);
      peer.send(`video-state-request`);
    });
    peer.on("signal", (signal) => {
      socket.emit(sessions.acceptCall, { signal, to });
    });

    setTimeout(() => {
      peer.signal(signal);
    }, 2000);

    return peer;
  }

  useEffect(() => {
    return () => {
      socket.emit(sessions.disconnectFromSession);
      window.location.replace(window.location.pathname);
    };
  }, []);

  return (
    <div className={s.container}>
      <div
        className={`${s.content} ${
          (toolsState.chat || toolsState.notations || toolsState.tasks) &&
          s.moveContent
        }`}
      >
        <div className={s.streamBlock}>
          {!peers || !peers.length ? (
            <div className={s.noUsersBlock}>
              <h1 style={{ textAlign: "center" }}>
                {strings.noUsersInSession}
              </h1>
            </div>
          ) : peersRef.current.length ? (
            peersRef.current.map((peer: any, index: number) => {
              return (
                <Video
                  peer={peer.peer}
                  outputAudioDevices={outputAudioDevices}
                  chatData={chatData}
                  key={index}
                />
              );
            })
          ) : null}

          <Draggable onDrag={handleDrag} bounds="parent" scale={0.7}>
            <div className={s.myselfVideoBlock}>
              <video
                autoPlay
                ref={myVideoRef}
                muted
                style={!camera ? { opacity: 0, position: "absolute" } : {}}
              />
              <span style={camera ? { opacity: 0, position: "absolute" } : {}}>
                <CircleAvatar
                  userId={userData?._id}
                  height="100px"
                  width="100px"
                  marginRight="0"
                />
              </span>
            </div>
          </Draggable>
        </div>
        <div className={s.bottomMenuBlock}>
          <BottomMenu
            myStream={myStream}
            peers={peers}
            inputAudioDevices={inputAudioDevices}
            inputVideoDevices={inputVideoDevices}
            outputAudioDevices={outputAudioDevices}
            setInputAudioDevices={setInputAudioDevices}
            setInputVideoDevices={setInputVideoDevices}
            setOutputAudioDevices={setOutputAudioDevices}
            setMyStream={setMyStream}
            sessionId={sessionId}
            camera={camera}
            setCamera={setCamera}
          />
        </div>
        <div className={s.rightMenuBlock}>
          <RightMenu toolsState={toolsState} setToolsState={setToolsState} />
        </div>
      </div>
      <div
        className={`${s.tollsBlock} ${toolsState.chat && s.chatActive} ${
          toolsState.tasks && s.tasksActive
        } ${toolsState.notations && s.notationsActive}`}
      >
        {!toolsState.notations && !toolsState.tasks && (
          <Chat
            sessionId={sessionId}
            setToolsState={setToolsState}
            chatHistory={chatData?.chat?.messages || []}
            avatars={
              chatData
                ? chatData.avatars.filter((item) => item.id !== userData?._id)
                : []
            }
          />
        )}
        {toolsState.tasks && sessionInfo && (
          <TodoVideoChat
            setToolsState={setToolsState}
            setTodosData={setTodosData}
            todosData={todosData}
            userSpecialistIds={sessionInfo.specialistUsers.map(
              (item) => item._id
            )}
            clientsIds={sessionInfo.clients.map((item) => item._id)}
          />
        )}
        {toolsState.notations && (
          <NotesVideoChat setToolsState={setToolsState} sessionId={sessionId} />
        )}
      </div>
    </div>
  );
};

export default SessionVideoChat;
