export enum ProjectsAvailableSessionTypes {
  SESSION = "SESSION",
  REAL_TIME_SESSION = "REAL_TIME_SESSION",
  PACKAGE = "PACKAGE",
}

export type TypeNewProject = {
  title: string;
  description: string;
  video?: File;
  isPrivate: boolean;
  avatar?: string;
  creationDate?: Date;
  endDate?: Date;
  clientLimit: number;
  specialistLimit: number;
  clients?: string[];
  specialists?: string[];
  sessionDiscount?: number;
  packageDiscount?: number;
  realTimeSessionDiscount?: number;
  availableSessionTypes?: {
    type: ProjectsAvailableSessionTypes;
    amount: number;
    unlimited: boolean;
  }[];
};
