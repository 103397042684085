import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SessionsApi from "../../api/sessionApi";
import { ReactComponent as CalendarIcon } from "../../assets/Header/calendar.svg";
import { ReactComponent as ArrowLeft } from "../../assets/Todo/arrow-left.svg";
import CommentComponent from "../../components/CommentComponent/CommentComponent";
import DateRabgePicker from "../../components/DateRangePicker/DateRangePicker";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../components/StandartButton/StandartButton";
import { translations } from "../../constans/languagesList";
import { ContextProvider } from "../../contextProvider";
import useClickOutside from "../../hooks/useClickOutside";
import strings from "../../localization";
import { setCommentModalVisible } from "../../redux-toolkit/slices/modalsSlice";
import { RootState } from "../../redux-toolkit/store";
import { Sessions } from "../../types/TypeSession";
import { TypeUserData } from "../../types/TypeUsers";
import { formatDateAndTime } from "../../utils/dateActions";
import s from "./HistoryOfSessions.module.css";
import SessionsTable from "./SessionsTable/SessionsTable";

type SessionsFilters = {
  price?: { from?: number; to?: number };
  isRefunded?: boolean;
  isPayedOut?: boolean;
  isCanceled?: boolean;
  isFromPackage?: boolean;
  date?: { from?: Date; to?: Date };
};
interface DateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}
interface SessionFilterValue {
  isRefunded?: boolean;
  isPayedOut?: boolean;
  isCanceled?: boolean;
}
type SessionStatusFilterValues = {
  [key: string]: SessionFilterValue | undefined;
};
export type HistorySession = Pick<
  Sessions,
  | "_id"
  | "title"
  | "description"
  | "sessionCost"
  | "project"
  | "isPayedOut"
  | "packageRef"
  | "isRefunded"
  | "tags"
  | "subject"
  | "isCanceled"
> & {
  specialists: TypeUserData[];
  clients: TypeUserData[];
  dateAndTime: {
    date: Date;
    duration: number;
  };
  visitDurations:
    | {
        _id: string;
        totalSessionTime: number;
      }[]
    | undefined;
};

const HistoryOfSessions = () => {
  const { userData } = useContext(ContextProvider);
  const token = localStorage.getItem("token");
  const datePickerMenuRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const [paganation, setPaganation] = useState({
    page: 0,
    rowPerPage: 5,
    total: 0,
  });
  const [filters, setFilters] = useState<SessionsFilters>({});
  const [sessionsData, setSessionsData] = useState<
    HistorySession[] | undefined
  >();
  const [selectedSession, setSelectedSession] = useState<
    HistorySession | undefined
  >();
  const [actionLoaders, setActionLoaders] = useState({ loadSessions: true });
  const [canLeaveComment, setCanLeaveComment] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [sessionStatusFilterValue, setSessionStatusFilterValue] =
    useState("All");
  const reduxSelectedDate = useSelector(
    (state: RootState) => state.modals.sessionCommentModal
  );

  useClickOutside(datePickerMenuRef, () => setShowDatePicker(false));

  const sessionStatusFilterValues: SessionStatusFilterValues = {
    ["Completed"]: { isRefunded: false, isPayedOut: true },
    ["Scheduled"]: { isRefunded: false, isPayedOut: false },
    ["Missed"]: { isRefunded: true, isPayedOut: false },
    ["Canceled"]: { isCanceled: true },
    ["All"]: undefined,
  };
  const sessionStatusData = [
    {
      value: "All",
      lable: strings.allSessions,
    },
    {
      value: "Completed",
      lable: strings.onlyCompleted,
    },
    {
      value: "Scheduled",
      lable: strings.onlyScheduled,
    },
    {
      value: "Missed",
      lable: strings.onlyMissed,
    },
    {
      value: "Canceled",
      lable: strings.onlyCanceled,
    },
  ];
  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      isPayedOut:
        sessionStatusFilterValues[sessionStatusFilterValue]?.isPayedOut,
      isRefunded:
        sessionStatusFilterValues[sessionStatusFilterValue]?.isRefunded,
      isCanceled:
        sessionStatusFilterValues[sessionStatusFilterValue]?.isCanceled,
    }));
  }, [sessionStatusFilterValue]);
  const getSessions = async () => {
    if (!token || !userData) return;
    setActionLoaders((prev) => ({ ...prev, loadSessions: true }));

    const response = await SessionsApi.getUsersSessionHistory(
      token,
      userData._id,
      filters,
      paganation.page + 1,
      paganation.rowPerPage
    );

    setActionLoaders((prev) => ({ ...prev, loadSessions: false }));
    if (response.sessions && response.status) {
      setSessionsData(response.sessions);
      setPaganation((prev) => ({ ...prev, total: response.totalAmount }));
    }
  };

  useEffect(() => {
    getSessions();
  }, [
    paganation.page,
    paganation.rowPerPage,
    filters.date,
    filters.isPayedOut,
    filters.isRefunded,
    filters.isCanceled,
  ]);

  const tagsSteps = [strings.direction, strings.topic, strings.result];

  const checkIfCanLeaveComment = async () => {
    if (token && selectedSession && !selectedSession.isCanceled) {
      const commentLeaveResponse = await SessionsApi.checkIfCanLeaveComment(
        token,
        selectedSession._id
      );
      setCanLeaveComment(commentLeaveResponse.status);
    }
  };

  useEffect(() => {
    checkIfCanLeaveComment();
  }, [selectedSession]);

  const formatMilliseconds = (milliseconds: number): string => {
    const { hours, minutes, seconds } =
      //@ts-expect-error
      translations[userData!.selectedLanguage!] || translations["en"];

    const secs = Math.floor((milliseconds / 1000) % 60);
    const mins = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hrs = Math.floor(milliseconds / (1000 * 60 * 60));

    return `${hrs}${hours} ${mins}${minutes} ${secs}${seconds}`;
  };

  const getMinimumTime = (arr: number[]): string => {
    const minMilliseconds = Math.min(...arr);
    return formatMilliseconds(minMilliseconds);
  };

  return (
    <div className={s.container}>
      <div className={s.filtersBlock}>
        <span className={s.filtersTitle}>
          {strings.sessionsHistoryFilterTitle}
        </span>
        <div className={s.filtersActionBlock}>
          <div className={s.dateRangeFilterBlock} ref={datePickerMenuRef}>
            <div
              className={s.selectDateRangeBlock}
              onClick={() => {
                setShowDatePicker((prev) => !prev);
              }}
              style={{
                backgroundColor: showDatePicker
                  ? "var(--secondary-color)"
                  : " var(--gray-main-color)",
              }}
            >
              <CalendarIcon width={25} height={25} />
              {filters.date
                ? formatDateAndTime(
                    filters.date.from!,
                    userData?.selectedLanguage!
                  ).formattedDate +
                  " - " +
                  formatDateAndTime(
                    filters.date.to!,
                    userData?.selectedLanguage!
                  ).formattedDate
                : strings.dateRange}
            </div>
            {showDatePicker && (
              <div className={s.datePickerBlock}>
                <DateRabgePicker
                  onDateChange={(value: DateRange) =>
                    setFilters((prev) => ({
                      ...prev,
                      date: { from: value.startDate, to: value.endDate },
                    }))
                  }
                />
              </div>
            )}
          </div>
          <div>
            <MultipleSelect
              data={sessionStatusData}
              multiplie={false}
              setValue={(value) => setSessionStatusFilterValue(value as string)}
              value={sessionStatusFilterValue}
              padding="10px"
              width="250px"
            />
          </div>
        </div>
      </div>
      <div className={s.mainContent}>
        <>
          <div
            className={
              selectedSession
                ? `${s.tableBlock} ${s.mobileTableBlockHidden}`
                : `${s.tableBlock} ${s.mobileTableBlockVisible}`
            }
          >
            <SessionsTable
              count={paganation.total}
              page={paganation.page}
              rows={sessionsData ?? []}
              rowsPerPage={paganation.rowPerPage}
              setPagination={setPaganation}
              loader={actionLoaders.loadSessions}
              setSelectedSession={setSelectedSession}
              selectedSession={selectedSession}
            />
          </div>
          <div
            className={
              selectedSession
                ? `${s.sessionInfoBlock} ${s.sessionInfoBlockActive} ${s.mobileSessionVisible}`
                : `${s.sessionInfoBlock} ${s.mobileSessionHidden}`
            }
          >
            {selectedSession && (
              <div>
                <div className={s.buttonsBlock}>
                  <div
                    className={s.backButton}
                    role="buttom"
                    onClick={() => setSelectedSession(undefined)}
                  >
                    <ArrowLeft />
                    <span className={s.backTitle}>
                      {strings.myProgramBackBtn}
                    </span>
                  </div>

                  {canLeaveComment &&
                    selectedSession.clients[0]._id === userData?._id && (
                      <StandartButton
                        action={() => dispatch(setCommentModalVisible(true))}
                        buttonTitle={strings.leaveFeedback}
                        height="35px"
                        fontSize="14px"
                        width="fit-content"
                      />
                    )}
                </div>
                <div className={s.infoRow}>
                  {tagsSteps.map(
                    (item, index) =>
                      selectedSession.tags && (
                        <div className={s.infoElement} key={index}>
                          <span className={s.infoLable}>{item}</span>
                          <span className={s.infoValue}>
                            {selectedSession.tags.find(
                              (el) => el.level === index + 1
                            )
                              ? selectedSession.tags
                                  .find((el) => el.level === index + 1)
                                  ?.labels.find(
                                    (el) =>
                                      el.language === userData?.selectedLanguage
                                  )?.text
                              : "-"}
                          </span>
                        </div>
                      )
                  )}
                  <div className={s.infoElement}>
                    <span className={s.infoLable}>
                      {strings.sessionInfoDuration}
                    </span>
                    <span className={s.infoValue}>
                      {selectedSession.visitDurations &&
                      selectedSession.visitDurations.length
                        ? getMinimumTime(
                            selectedSession.visitDurations.map(
                              (item) => item.totalSessionTime
                            )
                          )
                        : "-"}
                    </span>
                  </div>
                </div>
                {selectedSession.subject && selectedSession.subject.length ? (
                  <div className={s.subjectBlock}>
                    <span className={s.blockLable}>
                      {selectedSession.clients[0]._id === userData?._id
                        ? strings.myRequestTitle
                        : strings.clientRequestTitle}
                      :
                    </span>
                    <span>{selectedSession.subject}</span>
                  </div>
                ) : null}
                {selectedSession.packageRef && (
                  <div className={s.packageBlock}>
                    <span className={s.blockLable}>
                      {strings.packageSessionInfo}
                    </span>
                    <div className={s.rowInfoElement}>
                      <span className={s.infoLable}>
                        {strings.sessionInfoPackageTitle}:
                      </span>
                      <span className={s.infoRowValue}>
                        {selectedSession.packageRef.title.find(
                          (el) => el.language === userData?.selectedLanguage
                        )?.text ??
                          selectedSession.packageRef.title[0].text ??
                          "-"}
                      </span>
                    </div>
                    <div className={s.rowInfoElement}>
                      <span className={s.infoLable}>
                        {strings.sessionInfoSessionTitle}:
                      </span>
                      <span className={s.infoRowValue}>
                        {selectedSession.title
                          ? selectedSession.title?.find(
                              (el) => el.language === userData?.selectedLanguage
                            )?.text ?? selectedSession.title[0].text
                          : "-"}
                      </span>
                    </div>
                    <div className={s.rowInfoElement}>
                      <span className={s.infoLable}>
                        {strings.sessionInfoSessionDescription}:
                      </span>
                      <span className={s.infoRowValue}>
                        {selectedSession.description
                          ? selectedSession.description?.find(
                              (el) => el.language === userData?.selectedLanguage
                            )?.text ?? selectedSession.description[0].text
                          : "-"}
                      </span>
                    </div>
                  </div>
                )}
                {selectedSession.project && (
                  <div className={s.packageBlock}>
                    <span className={s.blockLable}>
                      {strings.sessionInfoProject}
                    </span>
                    <div className={s.rowInfoElement}>
                      <span className={s.infoLable}>
                        {strings.sessionInfoProjectTitle}:
                      </span>
                      <span className={s.infoRowValue}>
                        {selectedSession.project?.title ?? "-"}
                      </span>
                    </div>
                    <div /* className={s.rowInfoElement} */>
                      <span className={s.infoLable}>
                        {strings.sessionInfoProjectDescription}:{"  "}
                        <span className={s.infoRowValue}>
                          {selectedSession.project?.description ?? "-"}
                        </span>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      </div>
      {selectedSession && (
        <ModalWindow
          isOpen={reduxSelectedDate}
          alternativeCloseFunction={() =>
            dispatch(setCommentModalVisible(false))
          }
          width="fit-content"
        >
          <CommentComponent
            sessionId={selectedSession._id}
            closeModal={() => dispatch(setCommentModalVisible(false))}
          />
        </ModalWindow>
      )}
    </div>
  );
};

export default HistoryOfSessions;
